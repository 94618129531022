import React, { ChangeEvent, useState } from "react";
import { useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import Button from "../button/Button";
import Badge from "../common/Badge";
import ContentTitle from "../common/ContentTitle";
import ModalContainer from "../common/ModalContainer";
import Separator from "../common/Separator";
import InputBase from "../input/InputBase";
import AlertModal from "../modal/AlertModal";
import Span from "../span/Span";

const CreateNewTypeModal = ({ data, isBox }: { data: any; isBox: boolean }) => {
  // Hooks
  const { changeModal, closeModal } = useModal();
  // States
  const [boxInput, setBoxInput] = useState("");
  const [containerInput, setContainerInput] = useState("");

  // Recoil
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  // Functions
  const onChangeBoxInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setBoxInput(value);
  };

  const onChangeContainerInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setContainerInput(value);
  };

  const onClickCreate = async () => {
    const boxObj = boxInput
      ? { containerId: 1, quantity: Number(boxInput) }
      : null;
    const containerObj = containerInput
      ? { containerId: 2, quantity: Number(containerInput) }
      : null;
    const reqBody = [];
    if (boxObj) {
      reqBody.push(boxObj);
    }
    if (containerObj) {
      reqBody.push(containerObj);
    }
    if (!boxInput && !containerObj) return;

    const createStatus = await shopsApi.createFirstBoxContainer(reqBody);

    refetchFunctions &&
      refetchFunctions.refetchShopRequestAndRecall &&
      refetchFunctions.refetchShopRequestAndRecall();

    if (createStatus !== 400) {
      changeModal({
        content: <AlertModal message="성공" alertType="check" />,
      });
      return;
    } else {
      alert("오류 발생");
      return;
    }
  };

  return (
    <ModalContainer widthVw={26.6}>
      <ContentTitle isCenter>새로운 유형 요청</ContentTitle>
      <Span type="20pxBoldPureBlack">전체 내역</Span>
      <div className="mt-4 flex h-[43.33vh] w-full overflow-hidden">
        <div className="flex h-full w-full grow flex-col overflow-auto rounded-lg border border-solid border-custom-mediumGray p-4">
          {isBox ? (
            <section className="flex min-h-[50%] flex-col">
              <Span type="20pxBoldPureBlack">박스</Span>
              {data ? (
                data.find((item: any) =>
                  item.data.some((inner: any) => inner.containerId === 1),
                ) ? (
                  <div className="flex h-full w-full items-center justify-center">
                    모든 박스가 선택 되었습니다.
                  </div>
                ) : (
                  <div className="flex w-full items-center justify-between">
                    <Badge
                      badgeColor="custom-lightYellow"
                      textColor="custom-mainYellow"
                    >
                      요청
                    </Badge>
                    <Span type="14pxBoldBlack">플라스틱</Span>
                    <Span type="14pxBoldGray">TYPE A</Span>
                    <InputBase
                      width={150}
                      heightVh={4}
                      onChange={onChangeBoxInput}
                    />
                  </div>
                )
              ) : null}
            </section>
          ) : null}

          {/* <div className="my-3">
            <Separator />
          </div> */}

          {!isBox ? (
            <section className="flex min-h-[50%] flex-col ">
              <Span type="20pxBoldPureBlack">용기</Span>
              {data ? (
                data.find((item: any) =>
                  item.data.some((inner: any) => inner.containerId === 2),
                ) ? (
                  <div className="flex h-full w-full items-center justify-center">
                    모든 용기가 선택 되었습니다.
                  </div>
                ) : (
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center gap-x-4">
                      <Badge
                        badgeColor="custom-lightYellow"
                        textColor="custom-mainYellow"
                      >
                        요청
                      </Badge>
                      <Span type="14pxBoldBlack">유리</Span>
                    </div>
                    <div className="flex items-center gap-x-4">
                      <Span type="14pxBoldGray">플라스틱</Span>
                      <InputBase
                        style={{ width: 100, height: "4vh" }}
                        onChange={onChangeContainerInput}
                      />
                    </div>
                  </div>
                )
              ) : null}
            </section>
          ) : null}
        </div>
      </div>
      <div className="my-8"></div>
      <div className="flex w-full justify-center gap-x-5">
        <Button
          buttoncolor="custom-gray"
          heightVh={4}
          width={71}
          onClick={closeModal}
        >
          취소
        </Button>
        <Button heightVh={4} width={152} onClick={onClickCreate}>
          상기 수량으로 요청
        </Button>
      </div>
    </ModalContainer>
  );
};

export default CreateNewTypeModal;
