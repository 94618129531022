import { useEffect } from "react";
import AuthContentContainer from "../components/AuthPage/AuthContentContainer";

interface SignInOrResetPasswordProps {
  contentType: "signIn" | "verification" | "resetPassword";
}

const SignInOrResetPassword = ({ contentType }: SignInOrResetPasswordProps) => {
  useEffect(() => {
    console.log("signin 접속");
  }, []);
  return (
    <div className="flex h-[100vh] items-center justify-center">
      <AuthContentContainer contentType={contentType} />
    </div>
  );
};

export default SignInOrResetPassword;
