import React, { ReactNode } from "react";
interface MainContentContainerProps {
  children: ReactNode;
}

const InAndOutPageContentContainer = ({
  children,
}: MainContentContainerProps) => {
  return (
    <div className="relative box-border flex h-full w-full flex-col rounded-[8px] bg-white px-[40px] py-[3.7vh]">
      {children}
    </div>
  );
};

export default InAndOutPageContentContainer;
