import dayjs from "dayjs";
import { MouseEvent, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../lib/hooks/UseModal";
import { formatMobileString } from "../../lib/utils/FormatMobileString";
import { HqShop } from "../../types/HqTypes";
import Button from "../button/Button";
import Badge from "../common/Badge";
import RegisterShopModal from "./RegisterShopModal";

interface PageUserRowProps {
  data: HqShop;
  refetch?: () => void;
  index: number;
}

const Title = ({
  children,
  isMobile,
}: {
  children: ReactNode;
  isMobile?: boolean;
}) => {
  return (
    <strong
      className={`font-bold  text-custom-black    ${
        isMobile
          ? "text-[0.875rem] leading-[1.25rem]"
          : "text-[1.25rem] leading-[1.75rem]"
      } `}
    >
      {children}
    </strong>
  );
};

const HQShopsPageComponentRow = ({
  refetch,
  data,
  index,
}: PageUserRowProps) => {
  // Hooks
  const { openModal } = useModal();
  const navigate = useNavigate();

  // Functions
  const onClickComponent = (e: MouseEvent<HTMLLIElement>) => {
    openModal({
      content: <RegisterShopModal type="modify" shopData={data} />,
    });
  };

  const onClickTransaction = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/hq/io/123");
  };

  return (
    <li className="cursor-pointer list-none" onClick={onClickComponent}>
      <div className="grid w-full grid-cols-custom-hq-shops items-center">
        <Title>{index}</Title>
        <Title>{data.owner}</Title>
        <span className="text-[1rem] leading-[1.5rem] text-custom-gray ">
          {data.name}
        </span>
        <Title isMobile>{formatMobileString(data.mobile)}</Title>
        <div className="pl-[1.5rem]">
          <span className="text-[1rem] leading-[1.5rem] text-custom-gray">
            {`${data.address} ${data.address2}`}
          </span>
        </div>
        <div>
          <Button
            title="거래 내역"
            buttoncolor="custom-mainBlue"
            style={{ height: "4vh", width: "7.5rem" }}
            onClick={(e) => onClickTransaction(e)}
          />
        </div>

        <span className="text-[0.875rem]  font-medium  leading-[1.25rem] text-custom-gray">
          {`등록일 : ${dayjs(data.createdAt).format("YYYY-MM-DD")} `}
        </span>

        <div>
          {data.is_active ? (
            <Badge textColor="custom-mainBlue" badgeColor="custom-lightBlue">
              거래중
            </Badge>
          ) : null}
        </div>
      </div>
    </li>
  );
};

export default HQShopsPageComponentRow;
