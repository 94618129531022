import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { RegisterShopFormData } from "../../pages/auth/RegisterShopPage";

interface PostCodeProps {
  onClose: () => void;
  handleOnComplete?: (address: string) => void;
  setAddress?: Dispatch<SetStateAction<RegisterShopFormData>>;
}

const PostCode = ({ onClose, setAddress, handleOnComplete }: PostCodeProps) => {
  // Refs
  const contentRef = useRef<HTMLDivElement>(null);

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    if (handleOnComplete) {
      handleOnComplete(fullAddress);
      onClose();
      return;
    }

    console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    if (setAddress) {
      setAddress((prev) => ({
        ...prev,
        address: fullAddress,
      }));
      onClose();
    }
  };

  useEffect(() => {
    // Add a click event listener to the document
    const handleClickOutside = (e: any) => {
      // If the user clicked inside the div, do nothing
      if (contentRef.current && contentRef.current.contains(e.target)) {
        return;
      }

      // If the user clicked outside the div, close the modal
      onClose();
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed left-0 top-0 z-10 flex h-full w-full items-center justify-center">
      <div className="absolute left-0 top-0 h-full w-full bg-black opacity-50"></div>
      <div className="relative z-20 rounded bg-white p-5" ref={contentRef}>
        <DaumPostcodeEmbed onComplete={handleComplete} />
      </div>
    </div>
  );
};

export default PostCode;
