import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import FilterButton from "../components/button/FilterButton";
import ContentTitle from "../components/common/ContentTitle";
import BoxContainerCard from "../components/InAndOutPage/BoxContainerCard";
import DatePickerComponent from "../components/InAndOutPage/DatePickerComponent";
import InAndOutPageContentContainer from "../components/InAndOutPage/InAndOutPageContentContainer";
import SearchInput from "../components/input/SearchInput";
import { ReactComponent as ChevronDoubleLeftIcon } from "../assets/svgs/DoubleChevronLeft.svg";
import { ReactComponent as ChevronDoubleRightIcon } from "../assets/svgs/DoubleChevronRight.svg";
import { ReactComponent as ChevronLeftIcon } from "../assets/svgs/ChevronLeft.svg";
import { ReactComponent as ChevronRightIcon } from "../assets/svgs/ChevronRight.svg";
import Button from "../components/button/Button";
import { useModal } from "../lib/hooks/UseModal";
import CallDriverModal from "../components/InAndOutPage/CallDriverModal";
import { useQuery } from "@tanstack/react-query";
import shopsApi from "../api/Shops";
import Span from "../components/span/Span";
import dayjs from "dayjs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { refetchFunctionsAtom } from "../lib/recoils/RefetchFunctionsAtom";
import { getTimePassedForSameDay } from "../lib/utils/GetIsTimePassed";
import AlertModal from "../components/modal/AlertModal";
import useContainerAndBoxQuery from "../lib/queries/UseContainerAndBoxQuery";
import { debounce } from "lodash";
import { KOREAN_DELIVERY_STATUS } from "../lib/Consts";
import { useNavigate } from "react-router-dom";
import { shopInfoAtom } from "../lib/recoils/atoms/shopInfoAtom";
import useShopOrderControl from "../lib/hooks/UseShopOrderControl";
import Pagination from "../components/pagination/Pagination";

export type ShopInAndOutFilterType =
  | ""
  | "ready"
  | "preparing"
  | "delivering"
  | "delivered"
  | "collecting"
  | "collected";

export interface InAndOutFilter {
  startDate: Date | null;
  endDate: Date | null;
  filter: ShopInAndOutFilterType;
  searchTerm: string;
}

const InAndOutStatusPage = () => {
  // Hooks
  const { openModal } = useModal();
  const { callDriversForAdditionalOrderBool } = useShopOrderControl();

  // Recoil
  const setRefetchFunctions = useSetRecoilState(refetchFunctionsAtom);
  const shopInfo = useRecoilValue(shopInfoAtom);

  // States
  const [inAndOutFilter, setInAndOutFilter] = useState<InAndOutFilter>({
    startDate: new Date(),
    endDate: new Date(),
    filter: "",
    searchTerm: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const { startDate, endDate, filter, searchTerm } = inAndOutFilter;

  // Navigate
  const navigate = useNavigate();

  // Query
  const {
    data: finishDriverCallDateTime,
    refetch: refetchFinishDriverCallDateTime,
  } = useQuery({
    queryKey: ["finishDriverCallDateTime"],
    queryFn: shopsApi.getFinishedCallingDriversDatetime,
  });
  const { containerData } = useContainerAndBoxQuery();
  const { data: deliveryStatusData, isLoading: deliveryStatusLoading } =
    useQuery({
      queryKey: [
        "inAndOutDeliveryStatus",
        startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
        endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
        currentPage,
        10,
        searchTerm,
        filter,
      ],
      queryFn: async () =>
        shopsApi.getDeliveryStatus(
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
          endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
          currentPage,
          10,
          searchTerm,
          filter,
        ),
    });
  console.log(deliveryStatusData);

  // State
  const [totalPage, setTotalPage] = useState(
    deliveryStatusData && deliveryStatusData.totalCount
      ? Math.ceil(deliveryStatusData.totalCount / 10)
      : 1,
  );

  // Functions
  const onClickFilter = (filter: ShopInAndOutFilterType) => {
    console.log(filter);
    if (inAndOutFilter.filter === filter) {
      setInAndOutFilter((prev) => ({
        ...prev,
        filter: "",
      }));
    } else {
      setInAndOutFilter((prev) => ({
        ...prev,
        filter,
      }));
    }
  };

  const debouncedOnChange = debounce((value: string) => {
    setInAndOutFilter((prev) => ({ ...prev, searchTerm: value }));
  }, 800);

  const onChangeSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    debouncedOnChange(value);
  };

  const onClickCallDriver = async (e: MouseEvent<HTMLButtonElement>) => {
    // 주말인 경우 마감 안 됨
    if (dayjs().day() === 0 || dayjs().day() === 6) {
      openModal({
        content: (
          <AlertModal
            alertType="denied"
            message="주말에는 마감할 수 없습니다."
          />
        ),
      });

      return;
    }
    // const disabled = getTimePassedForSameDay(
    //   finishDriverCallDateTime
    //     ? finishDriverCallDateTime[0].finished_calling_drivers_datetime
    //     : null,
    // );
    // if (disabled) {
    //   openModal({
    //     content: (
    //       <AlertModal
    //         alertType="denied"
    //         message="금일 기사 배정이 이미 마감되었습니다."
    //       />
    //     ),
    //   });
    //   return;
    // }

    // 주문 마감 후 추가 기사 호출여부
    const isAdditional = await callDriversForAdditionalOrderBool();
    e.preventDefault();
    openModal({
      content: <CallDriverModal isAdditional={isAdditional} />,
    });
  };

  const onClickPage = (page: number) => {
    if (page <= 0) return null;
    else if (page > totalPage) return null;
    setCurrentPage(page);
  };

  // Effects
  useEffect(() => {
    setRefetchFunctions((prev) => ({
      ...prev,
      refetchFinishDriverCallDateTime,
    }));
  }, [refetchFinishDriverCallDateTime, setRefetchFunctions]);

  useEffect(() => {
    if (deliveryStatusData && deliveryStatusData.totalCount) {
      if (deliveryStatusData.totalCount <= 10) setTotalPage(1);
      else setTotalPage(Math.ceil(deliveryStatusData.totalCount / 10));
    }
  }, [deliveryStatusData]);

  useEffect(() => {
    if (!shopInfo) return;
    if (shopInfo[0].id === 1) {
      navigate("/hq/io");
      return;
    }
  }, [shopInfo]);

  // Classes
  const spanClass = `text-[0.875rem] leading-[1.25rem] text-custom-black`;

  return (
    <div className="flex h-full w-full flex-col">
      <ContentTitle hasMb>입출고 현황</ContentTitle>
      <div className="relative mb-[4.62vh] flex w-full justify-between gap-x-[50px]">
        {/** Absolute 영역 */}
        <div className="absolute -top-[2.3vh] right-0 flex -translate-y-full items-center gap-x-[2.1vw]">
          {finishDriverCallDateTime &&
          finishDriverCallDateTime[0].finished_calling_drivers_datetime ? (
            <Span type="16pxBoldPureBlack">
              {`마지막 기사 호출 시간 : ${dayjs(
                finishDriverCallDateTime[0].finished_calling_drivers_datetime,
              ).format("YYYY-MM-DD HH:mm")}`}
            </Span>
          ) : null}

          <Button
            title="테스트!22"
            buttoncolor="custom-mainBlue"
            style={{ width: "7.5rem", height: "3.75rem" }}
            onClick={callDriversForAdditionalOrderBool}
          />
          <Button
            title="기사 호출"
            buttoncolor="custom-mainBlue"
            style={{ width: "7.5rem", height: "3.75rem" }}
            onClick={onClickCallDriver}
            disabled={
              dayjs().day() === 0 ||
              dayjs().day() === 6 ||
              getTimePassedForSameDay(
                finishDriverCallDateTime
                  ? finishDriverCallDateTime[0]
                      .finished_calling_drivers_datetime
                  : null,
              )
            }
          />
          <Button
            title="현황 업데이트"
            buttoncolor="custom-mainYellow"
            style={{ width: "7.5rem", height: "3.75rem" }}
          />
        </div>

        {/** 추후 다시 사용 가능할지도 */}
        {/* <BoxContainerCard type="box" data={boxData} /> */}
        <BoxContainerCard type="container" data={containerData} />
      </div>

      <InAndOutPageContentContainer>
        <section className="flex items-center justify-between">
          <section>
            <ContentTitle>운송내역</ContentTitle>
            <span className={spanClass}>
              모든 운송 내역 입니다. 검색을 통해서 확인이 가능 합니다.
            </span>
          </section>
          <section className="flex items-center">
            <DatePickerComponent
              placeholder="시작일"
              isStart
              inAndOutFilter={inAndOutFilter}
              setInAndOutFilter={setInAndOutFilter}
            />
            <span className="mx-[0.62vw] text-[1rem] leading-[1.25rem] text-custom-gray">
              ~
            </span>
            <DatePickerComponent
              placeholder="종료일"
              inAndOutFilter={inAndOutFilter}
              setInAndOutFilter={setInAndOutFilter}
            />
          </section>
          <section className="flex">
            <div className="mr-[0.5vw] flex items-center gap-x-[0.41vw]">
              <FilterButton
                title="배송준비"
                whereTo="inAndOut"
                isSelected={inAndOutFilter.filter.includes("preparing")}
                onClick={() => onClickFilter("preparing")}
              />
              <FilterButton
                title="배송중"
                whereTo="inAndOut"
                isSelected={inAndOutFilter.filter.includes("delivering")}
                onClick={() => onClickFilter("delivering")}
              />
              <FilterButton
                title="배송완료"
                whereTo="inAndOut"
                isSelected={inAndOutFilter.filter.includes("delivered")}
                onClick={() => onClickFilter("delivered")}
              />
              <FilterButton
                title="수거중"
                whereTo="inAndOut"
                isSelected={inAndOutFilter.filter.includes("collecting")}
                onClick={() => onClickFilter("collecting")}
              />
              <FilterButton
                title="보관완료"
                whereTo="inAndOut"
                isSelected={inAndOutFilter.filter.includes("collected")}
                onClick={() => onClickFilter("collected")}
              />
            </div>
            <SearchInput
              placeholder="고객,배송기사"
              whereTo="inAndOut"
              height={"4vh"}
              name="searchTerm"
              onChange={onChangeSearchInput}
            />
          </section>
        </section>

        <section className="relative box-border h-full rounded-[8px] border border-solid border-custom-mediumGray px-[1vw] py-[0.5vh] ">
          {/* Grid */}
          <div className="relative flex h-full flex-col items-start gap-y-[0.85vh] whitespace-nowrap">
            {/* Header */}
            <div className="sticky top-0 col-start-1 col-end-8 grid h-fit w-full grid-cols-custom-inAndOut">
              <div className={`${spanClass}`}>#</div>
              <div className={`text-center ${spanClass}`}>유형</div>
              <div className={`text-center ${spanClass}`}>일시</div>
              <div className={`text-center ${spanClass}`}>
                고객 (전화번호 뒷번호 4자리)
              </div>
              <div className={`text-center ${spanClass}`}>
                배송기사명 (차량번호)
              </div>
              {/* <div className={`text-center ${spanClass}`}>시작 박스 수량</div>
            <div className={`text-center ${spanClass}`}>종료 박스 수량</div> */}
              <div className={`text-center ${spanClass}`}>시작 병 수량</div>
              <div className={`text-center ${spanClass}`}>종료 병 수량</div>
            </div>

            {/* Data */}
            {!deliveryStatusData && deliveryStatusLoading ? (
              <div className="flex w-full grow items-center justify-center">
                로딩중...
              </div>
            ) : null}
            {deliveryStatusData &&
            !deliveryStatusLoading &&
            deliveryStatusData.data.length > 0 ? (
              deliveryStatusData.data.map((item, index) => {
                if (item.deliveryStatus === "ready") return null;
                return (
                  <div
                    className="grid w-full grid-cols-custom-inAndOut"
                    key={index + item.userName}
                  >
                    <div key={index + 1} className={`${spanClass}`}>
                      {index + 1}
                    </div>
                    <div key={index + 2} className={`${spanClass} text-center`}>
                      {KOREAN_DELIVERY_STATUS[item.deliveryStatus]}
                    </div>
                    <div key={index + 3} className={`${spanClass} text-center`}>
                      {dayjs(item.updatedAt).format("YYYY/MM/DD HH:MM")}
                    </div>
                    <div key={index + 4} className={`${spanClass} text-center`}>
                      {item.userName} ({item.userMobile.substring(7, 11)})
                    </div>
                    <div key={index + 5} className={`${spanClass} text-center`}>
                      {item.driverName} ({item.carNumber})
                    </div>

                    <div key={index + 8} className={`${spanClass} text-center`}>
                      {item.quantity ?? "수량 입력 전 입니다."}
                    </div>
                    <div key={index + 9} className={`${spanClass} text-center`}>
                      {item.after_quantity ?? "수량 입력 전 입니다."}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex w-full grow items-center justify-center">
                운송 내역이 없습니다.
              </div>
            )}
          </div>
        </section>
        {/* PageButton */}
        {totalPage > 0 && (
          <Pagination
            totalPage={totalPage}
            currentPage={currentPage}
            onClickPage={onClickPage}
          />
        )}
        {/* <nav className="mt-[1.85vh] flex w-full items-center justify-center gap-x-5">
          <ChevronDoubleLeftIcon
            className="cursor-pointer"
            onClick={() => {
              setCurrentPage(1);
            }}
          />
          <ChevronLeftIcon
            className="cursor-pointer"
            onClick={() => {
              const prevPage = currentPage - 1;
              if (prevPage === 0) return;
              setCurrentPage(prevPage);
            }}
          />

          <ul className="flex gap-x-5">
            {Array(totalPage)
              .fill("페이지")
              .map((_, index) => {
                return (
                  <li
                    key={index + Math.random()}
                    onClick={() => onClickPage(index + 1)}
                    className={`box-border flex shrink-0 cursor-pointer items-center justify-center text-[1rem] font-medium leading-[1.25rem] text-custom-mainYellow ${
                      currentPage === index + 1
                        ? "bg-custom-lightYellow"
                        : "bg-white"
                    } `}
                  >
                    {index + 1}
                  </li>
                );
              })}
          </ul>

          <ChevronRightIcon
            className="cursor-pointer"
            onClick={() => {
              const nextPage = currentPage + 1;
              if (nextPage <= totalPage) {
                setCurrentPage((prev) => prev + 1);
              } else {
                return;
              }
            }}
          />
          <ChevronDoubleRightIcon
            className="cursor-pointer"
            onClick={() => {
              setCurrentPage(totalPage);
            }}
          />
        </nav> */}
      </InAndOutPageContentContainer>
    </div>
  );
};

export default InAndOutStatusPage;
