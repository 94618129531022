import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateClickComponent.css";
import { ko } from "date-fns/esm/locale";
import { ReactComponent as CalendarIcon } from "../../assets/svgs/Calendar.svg";
import { useEffect } from "react";
import useShopOrderControl from "../../lib/hooks/UseShopOrderControl";
import { useRecoilState } from "recoil";
import { shopOrderAtom } from "../../lib/recoils/atoms/ShopOrderPage/ShopOrderAtom";

const DateClickComponent = ({
  checkIsToday,
  setIsToday,
}: {
  setSelectedDate: any;
  isToday: boolean;
  setIsToday: any;
  checkIsToday: any;
}) => {
  // Hooks
  const { setSelectedDate } = useShopOrderControl();

  // Recoil
  const [{ selectedDate }, setShopOrderState] = useRecoilState(shopOrderAtom);
  // Functions
  const onClickPrevDay = () => {
    if (selectedDate) {
      let newDate = new Date(selectedDate);
      newDate.setDate(newDate.getDate() - 1);
      setShopOrderState((prev) => ({ ...prev, selectedDate: newDate }));
    } else {
      return;
    }
  };

  const onClickNextDay = () => {
    if (selectedDate) {
      let newDate = new Date(selectedDate);
      newDate.setDate(newDate.getDate() + 1);
      setShopOrderState((prev) => ({ ...prev, selectedDate: newDate }));
    } else {
      return;
    }
  };

  useEffect(() => {
    checkIsToday(selectedDate);
    console.log(selectedDate);
  }, [selectedDate]);

  return (
    <div className="flex items-center">
      <button className="font-bold" onClick={onClickPrevDay}>
        &lt;
      </button>
      <DatePicker
        id="dateClickComponent"
        selected={selectedDate}
        locale={ko}
        className="order-date-input"
        dateFormat={"yyyy.MM.dd(eee)"}
        onChange={(date) => setSelectedDate(date)}
      />
      <button className="mr-[1.5vw] font-bold" onClick={onClickNextDay}>
        &gt;
      </button>
      <label htmlFor="dateClickComponent">
        <CalendarIcon style={{ height: "2.9vh" }} />
      </label>
    </div>
  );
};

export default DateClickComponent;
