import { WhereTo } from "../../types/ComponentSizeTypes";

interface SeparatorProps {
  direction?: "vertical" | "horizontal";
  mt?: number;
  mb?: number;
  whereTo?: WhereTo;
}

const Separator = ({ direction, mt, mb }: SeparatorProps) => {
  return <div className="h-[1px] w-full bg-custom-mediumGray" />;
};

export default Separator;
