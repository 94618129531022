export const formatKoreanChildType = (childType: string): string => {
  switch (childType) {
    case "infant":
      return "유아식";
    case "superEarly":
      return "초기";
    case "early":
      return "초중기";
    case "middle":
      return "중기";
    case "lateMiddle":
      return "중후기";
    case "late":
      return "후기";
    case "completion":
      return "완료기";
    case "etc":
      return "기타";
    default:
      return childType;
  }
};
