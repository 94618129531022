import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
} from "react";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import { shopMemuFormAtom } from "../../lib/recoils/atoms/ShopMenuPage/ShopMenuFormAtom";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import Button from "../button/Button";
import InputBase from "../input/InputBase";
import AlertModal from "../modal/AlertModal";

const MenuDetail = ({
  setShowAddMenu,
}: {
  setShowAddMenu: Dispatch<SetStateAction<boolean>>;
}) => {
  // Hooks
  const { openModal } = useModal();

  // State
  const [newMenuForm, setNewMenuForm] = useState({
    name: "",
    foodAmount: 0,
    price: 0,
  });

  // Recoil
  const [menuDetailFormData, setMenuDetailFormData] =
    useRecoilState(shopMemuFormAtom);
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  // Functions
  const onClickCreateMenu = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { childType, week } = menuDetailFormData;
    const { name, foodAmount, price } = newMenuForm;
    const status = await shopsApi.createMenu(
      name,
      childType,
      week,
      Number(foodAmount),
      Number(price),
    );
    if (status === 201) {
      refetchFunctions &&
        refetchFunctions.refetchShopMenu &&
        refetchFunctions.refetchShopMenu();

      setMenuDetailFormData((prev) => ({
        ...prev,
        name: "",
        foodAmount: 0,
        price: 0,
      }));
      setNewMenuForm({
        name: "",
        foodAmount: 0,
        price: 0,
      });
      openModal({
        content: <AlertModal alertType="check" message="메뉴 생성 성공" />,
      });
    } else {
      openModal({
        content: <AlertModal alertType="denied" message="오류 발생" />,
      });
    }
  };

  const onChangeMenuInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name);
    if (name === "price" || name === "foodAmount") {
      if (Number.isNaN(Number(value))) {
        return;
      }
    }
    setNewMenuForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <span
        className={` whitespace-pre-wrap text-[0.875rem] font-normal leading-[1.25rem] text-custom-black`}
      >
        메뉴 세부 정보
      </span>
      <div className="flex w-full flex-col justify-between">
        <form className="flex w-full flex-col rounded-[8px] border border-solid border-custom-gray px-[24px] py-[2.2vh] ">
          <div className="flex flex-col gap-y-[2.4vh]">
            <InputBase
              placeholder="판매상품명"
              height={"4vh"}
              name="name"
              inputType="string"
              onChange={onChangeMenuInput}
              value={newMenuForm.name}
            />
            <div className="flex items-end gap-x-[14px]">
              <InputBase
                placeholder="용량"
                height={"4vh"}
                name="foodAmount"
                inputType="string"
                value={newMenuForm.foodAmount}
                onChange={onChangeMenuInput}
              />
              <span className="text-[1rem] leading-[1.25rem] text-custom-gray">
                g
              </span>
            </div>
            <div className="flex items-end gap-x-[14px]">
              <InputBase
                placeholder="판매정가"
                height={"4vh"}
                name="price"
                inputType="string"
                value={newMenuForm.price}
                onChange={onChangeMenuInput}
              />
              <span className="text-[1rem] leading-[1.25rem] text-custom-gray">
                원
              </span>
            </div>
          </div>
        </form>
        <div className="mt-4 flex w-full flex-col items-center">
          <span className="mb-[1.1vh] text-[0.75rem] leading-[1rem] text-custom-black">
            각 메뉴 변경 후 아래의 저장을 눌러야 최종적으로 저장이 됩니다.
          </span>
          <Button
            title="저장하기"
            width={120}
            height={60}
            onClick={onClickCreateMenu}
          />
        </div>
      </div>
    </>
  );
};

export default MenuDetail;
