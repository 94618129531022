import { MouseEvent, useEffect } from "react";
import { useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import { formatMobileString } from "../../lib/utils/FormatMobileString";
import { FlatOrderList, TransformedOrder } from "../../types/OrderTypes";
import Button from "../button/Button";
import ContentTitle from "../common/ContentTitle";
import ModalContainer from "../common/ModalContainer";
import Separator from "../common/Separator";
import InputBase from "../input/InputBase";
import AlertModal from "../modal/AlertModal";

const CancelOrderModal = ({
  shopOrderInfo,
  isNormal,
}: {
  shopOrderInfo: FlatOrderList;
  isNormal?: boolean;
}) => {
  // Consts
  const inputStyle = {
    width: 326,
    height: "4vh",
  };

  // Hooks
  const { changeModal, closeModal } = useModal();

  // Recoils
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  // Functions
  const onClickCancelManualOrder = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { data, status } = await shopsApi.cancelManualOrder(
      shopOrderInfo.orderId,
    );
    if (status === 200) {
      refetchFunctions &&
        refetchFunctions.refetchShop &&
        refetchFunctions.refetchShop();
      changeModal({
        content: (
          <AlertModal alertType="check" message="주문이 취소되었습니다." />
        ),
      });
    } else {
      changeModal({
        content: (
          <AlertModal
            alertType="denied"
            message="기사 호출 후에는 삭제가 불가능합니다."
          />
        ),
      });
    }
    console.log(data, status);
  };

  const onClickCancelOrder = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { data, status } = await shopsApi.cancelOrder(shopOrderInfo.orderId);
    if (status === 200) {
      refetchFunctions &&
        refetchFunctions.refetchShop &&
        refetchFunctions.refetchShop();
      changeModal({
        content: (
          <AlertModal alertType="check" message="주문이 취소되었습니다." />
        ),
      });
    } else {
      changeModal({
        content: (
          <AlertModal
            alertType="denied"
            message="기사 호출 후에는 삭제가 불가능합니다."
          />
        ),
      });
    }
    console.log(data, status);
  };

  useEffect(() => {
    console.log("주문 취소의", shopOrderInfo);
  }, []);

  return (
    <ModalContainer width={550}>
      <ContentTitle isCenter>주문 취소</ContentTitle>
      <form className="flex w-full flex-col">
        <section>
          <InputBase
            style={{ ...inputStyle, marginBottom: "3.89vh" }}
            title="이름"
            disabled
            placeholder={shopOrderInfo.userName}
          />
          <InputBase
            style={{ ...inputStyle, marginBottom: "3.89vh" }}
            title="휴대폰 번호"
            disabled
            placeholder={formatMobileString(shopOrderInfo.mobile)}
          />

          {shopOrderInfo.driverId && (
            <>
              <div className="flex items-end justify-between">
                <InputBase
                  placeholder="홍길동"
                  disabled
                  style={{ ...inputStyle }}
                  title="배정된 기사"
                />
                <div>
                  <img
                    src={require("../../assets/images/TempDriver.png")}
                    alt="배정된 기사"
                  />
                </div>
              </div>
            </>
          )}
        </section>

        <section className="mt-[7.69vh] flex w-full flex-col justify-center">
          <div className="flex flex-col items-center">
            {/* <Span type="16pxBoldBlack">금번 결제 금액</Span>
            <Span type="24pxBoldBlack">50,000원</Span> */}
          </div>
          <div className="my-[4.63vh]">
            <Separator />
          </div>
        </section>

        <section className="flex w-full justify-center">
          <Button
            title="닫기"
            buttoncolor="custom-gray"
            style={{ height: "4vh", width: 103, marginRight: 13 }}
            onClick={() => closeModal()}
          />
          <Button
            title="배송 취소"
            style={{ height: "4vh", width: 220 }}
            onClick={
              isNormal === true ? onClickCancelOrder : onClickCancelManualOrder
            }
          />
        </section>
      </form>
    </ModalContainer>
  );
};

export default CancelOrderModal;
