import React from "react";
import InputBase from "./InputBase";
import { AddressInput } from "./InputTypes";
import InputWithButton from "./InputWithButton";

const InputWithAddress = ({
  id,
  showFrontDoorPassword,
  name,
  onChange,
  title,
  width,
  address,
  addressDetail,
  addressDetailInputName,
  addressInputName,
  description,
  descriptionInputName,
  onButtonClick,
  heightVh,
  ...props
}: AddressInput) => {
  return (
    <div>
      {title && (
        <label htmlFor={id} className="text-[1rem] font-bold leading-5">
          {title}
        </label>
      )}
      <div className="mt-[1.25rem] box-border flex w-full flex-col items-stretch justify-center gap-x-[2.76vw] gap-y-[0.875rem]">
        <InputWithButton
          name={name}
          buttonTitle="주소검색"
          placeholder="주소를 검색해 주세요."
          onButtonClick={onButtonClick}
          heightVh={heightVh}
          value={address}
        />
        <InputBase
          fullWidth
          placeholder="건물이름 동 호수"
          value={addressDetail}
          name={addressDetailInputName}
          onChange={onChange}
          heightVh={heightVh}
        />
        {showFrontDoorPassword && (
          <InputBase
            fullWidth
            placeholder="공동현관문 비밀번호"
            name={descriptionInputName}
            value={description}
            onChange={onChange}
          />
        )}
        {showFrontDoorPassword && (
          <p className="self-end text-[0.875rem] leading-[1.25rem] text-custom-mainYellow">
            * 공동현관문이 있는 경우에만 입력해 주세요.
          </p>
        )}
      </div>
    </div>
  );
};

export default InputWithAddress;
