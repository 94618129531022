import { ReactComponent as ChevronDoubleLeftIcon } from "../../assets/svgs/DoubleChevronLeft.svg";
import { ReactComponent as ChevronDoubleRightIcon } from "../../assets/svgs/DoubleChevronRight.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/svgs/ChevronLeft.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/svgs/ChevronRight.svg";
import PageUserRow from "./CustomerPageComponentRow";
import { UserProps } from "../../types/UserTypes";
import { Dispatch, SetStateAction } from "react";
import useShopCustomerQuery from "../../lib/queries/UseShopCustomerQuery";
import Pagination from "../pagination/Pagination";

interface PageComponentProps {
  title: string;
  data: {
    data: UserProps[];
    message: string;
    totalCount: number;
    remarks: string;
  };
  sort: "desc" | "asc";
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  refetch?: () => void;
}

const CustomerPageComponent = ({
  title,
  data,
  sort,
  currentPage,
  setCurrentPage,
  refetch,
}: PageComponentProps) => {
  console.log("totalCount :", data.totalCount);
  console.log("data :", data);
  const totalPage = Math.ceil(data.totalCount / 10);

  // Query
  useShopCustomerQuery(sort, currentPage);

  const onClickPage = (page: number) => {
    if (page <= 0) return null;
    else if (page > totalPage) return null;
    setCurrentPage(page);
  };

  return (
    <div className="box-border flex h-full w-full flex-col">
      <h3 className="mb-[0.4vh] text-[0.875rem] leading-[1.25rem] text-custom-black">
        {title}
      </h3>
      <section className="mb-[2.7vh] box-border h-[57vh] w-full overflow-auto rounded-[8px] border border-solid border-custom-mediumGray px-[1.5vw] py-[3.1vh]">
        <ul className="flex h-full flex-col gap-y-[2.7vh]">
          {data.data.length === 0 && (
            <div className="flex h-full w-full grow items-center justify-center">
              등록된 고객 데이터가 없습니다.
            </div>
          )}
          {data &&
            data.data.length > 0 &&
            data.data.map((item, index) => (
              <PageUserRow refetch={refetch} data={item} index={index} />
            ))}
        </ul>
      </section>
      {totalPage > 0 && (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          onClickPage={onClickPage}
        />
      )}
      {/* {totalPage > 0 && (
        <nav className="flex grow items-center gap-x-10 self-center">
          <ChevronDoubleLeftIcon
            className="cursor-pointer"
            onClick={() => {
              setCurrentPage(1);
            }}
          />
          <ChevronLeftIcon
            className="cursor-pointer"
            onClick={() => {
              const prevPage = currentPage - 1;
              if (prevPage === 0) return;
              setCurrentPage(prevPage);
            }}
          />

          <ul className="flex gap-x-10">
            {Array(totalPage)
              .fill("")
              .map((item, index) => {
                return (
                  <li
                    key={index + Math.random()}
                    onClick={() => onClickPage(index + 1)}
                    className={`box-border flex shrink-0 cursor-pointer items-center justify-center text-[1rem] font-medium leading-[1.25rem] text-custom-mainYellow ${
                      currentPage === index + 1
                        ? "bg-custom-lightYellow"
                        : "bg-white"
                    } `}
                  >
                    {index + 1}
                  </li>
                );
              })}
          </ul>

          <ChevronRightIcon
            className="cursor-pointer"
            onClick={() => {
              const nextPage = currentPage + 1;
              if (nextPage <= totalPage) {
                setCurrentPage((prev) => prev + 1);
              } else {
                return;
              }
            }}
          />
          <ChevronDoubleRightIcon
            className="cursor-pointer"
            onClick={() => {
              setCurrentPage(totalPage);
            }}
          />
        </nav>
      )} */}
    </div>
  );
};

export default CustomerPageComponent;
