import { TransformedOrder } from "../../types/OrderTypes";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { formatKoreanChildType } from "./FormatKoreanChildType";
import { CHILDTYPE_ORDER } from "../Consts";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

export const generateCsv = (
  allModifiedOrderData: TransformedOrder[],
  date: Date | null,
) => {
  console.log("엑셀데이터", allModifiedOrderData);
  // Step 1: Create unique header Set based on childType and menuName
  const headerMap: Map<string, { childType: string; amounts: number[] }> =
    new Map();
  allModifiedOrderData.forEach((order) => {
    order.menus.forEach((menu) => {
      const headerKey = `${menu.childType}-${menu.menuName}`;
      if (!headerMap.has(headerKey)) {
        headerMap.set(headerKey, { childType: menu.childType, amounts: [] });
      }
      if (!headerMap.get(headerKey)!.amounts.includes(menu.amount)) {
        headerMap.get(headerKey)!.amounts.push(menu.amount);
      }
    });
  });

  // Sort the headerMap by childType according to CHILDTYPE_ORDER, then by menuName
  const sortedHeaders = Array.from(headerMap.entries()).sort((a, b) => {
    const orderA = CHILDTYPE_ORDER[a[1].childType] || 9;
    const orderB = CHILDTYPE_ORDER[b[1].childType] || 9;
    if (orderA !== orderB) {
      return orderA - orderB;
    }
    return a[0].localeCompare(b[0]);
  });

  // Step 2: Create the header rows
  const childTypeRow: string[] = [];
  const menuNameRow: string[] = [];
  const amountRow: string[] = [];

  sortedHeaders.forEach(([key, value]) => {
    const childTypeKorean = formatKoreanChildType(value.childType);
    const amountsLength = value.amounts.length;
    childTypeRow.push(childTypeKorean);
    // Fill the rest with empty strings if there are multiple amounts
    for (let i = 1; i < amountsLength; i++) {
      childTypeRow.push("");
    }
    value.amounts.forEach((amount) => {
      menuNameRow.push(key.split("-")[1]);
      amountRow.push(amount.toString());
    });
  });

  // Step 3: Create the data rows
  const dataRows = allModifiedOrderData.map((order) => {
    const row = [
      "", // 체크칸 (Placeholder for now)
      dayjs(date).tz().format("YYYY-MM-DD"), // 날짜
      order.userName, // 고객명
      `${order.address} ${order.address2}`, // 주소
      order.description ?? "", // 현관번호
      order.mobile, // 전화번호
      "", // 결제일자 (Placeholder for now)
      Math.abs(Number(order.baseAmount)).toString(), // 결제금액
      order.containerType === "disposable"
        ? "일회용기"
        : order.containerType === "glass"
        ? "유리용기"
        : "",
      order.remarks ?? "없음", // 비고, 특이사항
    ];

    // Initialize a map to hold the quantities for each header
    const quantityMap: Map<string, number> = new Map();

    // Populate the quantityMap with quantities for each header
    order.menus.forEach((menu) => {
      const headerKey = `${menu.childType}-${menu.menuName}-${menu.amount}`;
      quantityMap.set(headerKey, menu.quantity);
    });

    // Add the quantities to the row based on the header order
    sortedHeaders.forEach(([key, value]) => {
      value.amounts.forEach((amount) => {
        const quantityKey = `${key}-${amount}`;
        const quantity = quantityMap.get(quantityKey);
        row.push(quantity !== undefined ? String(quantity) : "");
      });
    });

    return row;
  });

  // Step 4: Combine rows to create CSV format
  const fixedHeaderRow = [
    "체크칸",
    "날짜",
    "고객명",
    "주소",
    "현관번호",
    "전화번호",
    "결제일자",
    "결제금액",
    "용기 정보",
    "비고, 특이사항",
  ];

  // Step 5. childTypeRow 에서 중복 제거
  let lastChildType = "";
  const cleanedChildTypeRow = childTypeRow.map((childType) => {
    if (childType === "") {
      // Keep the empty strings as placeholders for amounts
      return childType;
    } else if (childType === lastChildType) {
      // Replace duplicate child type with empty string
      return "";
    } else {
      // Update the lastChildType and keep the current child type
      lastChildType = childType;
      return childType;
    }
  });

  // Step 6. menuName 에서 중복 제거
  let lastMenuName = "";
  const cleanedMenuName = menuNameRow.map((menuName) => {
    if (menuName === "") {
      return menuName;
    } else if (menuName === lastMenuName) {
      return "";
    } else {
      lastMenuName = menuName;
      return menuName;
    }
  });

  const csvData = [
    [...fixedHeaderRow, ...cleanedChildTypeRow],
    ["", "", "", "", "", "", "", "", "", "", ...cleanedMenuName],
    ["", "", "", "", "", "", "", "", "", "", ...amountRow],
    ...dataRows,
  ];

  return csvData;
};
