import React from "react";
import FormContainer from "../components/SignUpPage/FormContainer";

const SignupPage = () => {
  return (
    <div>
      <FormContainer />
    </div>
  );
};

export default SignupPage;
