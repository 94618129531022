import { ReactComponent as SearchIcon } from "../../assets/svgs/Search.svg";
import { FileInputProps } from "./InputTypes";

const FileInput = ({
  name,
  id,
  placeholder,
  value,
  onChange,
  onClickInput,
  disabled,
  width,
  height,
  fullWidth,
  title,
  ...props
}: FileInputProps) => {
  const widthClass = `${
    fullWidth ? "w-full" : width ? `w-[${width}px] max-w-[326px]` : "w-auto"
  }`;
  const heightClass = height
    ? typeof height === "string"
      ? `h-[4vh]`
      : `h-[${height}px]`
    : `h-[52px]`;
  return (
    <div
      className={`relative box-border flex items-center rounded-lg border border-solid p-4 text-[1rem] leading-[1.25rem] text-custom-gray ${widthClass} ${heightClass}`}
    >
      <label className="w-full cursor-pointer" htmlFor={id}>
        {placeholder}
      </label>
      <input
        accept="image/*"
        className="hidden"
        id={id}
        name={name}
        placeholder={placeholder ? placeholder : ""}
        value={value}
        onChange={onChange}
        onClick={onClickInput}
        disabled={disabled}
        multiple
        type={"file"}
        {...props}
      />
      <div className="absolute right-[0.63vw] top-1/2 -translate-y-1/2">
        <SearchIcon />
      </div>
    </div>
  );
};

export default FileInput;
