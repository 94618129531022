import React, { ChangeEvent } from "react";

interface ContainerSelectProps {
  id?: string;
  title?: string;
  width?: number;
  fullWidth?: boolean;
  name?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const selectData = [
  { value: "glass", id: "1" },
  { value: "disposable", id: "2" },
];

const ContainerSelect = ({
  title,
  id,
  width,
  fullWidth,
  name,
  value,
  onChange,
}: ContainerSelectProps) => {
  return (
    <div className="flex w-full flex-col">
      {title && (
        <label htmlFor={id} className="text-[1rem] font-bold leading-5">
          {title}
        </label>
      )}
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={`mt-[1.25rem] h-[52px] rounded-lg border border-solid p-4 ${
          fullWidth ? "w-full" : width ? `w-[${width}px]` : "w-auto"
        }`}
      >
        {selectData.map((item) => (
          <option key={item.value} value={item.value}>
            {item.value === "disposable" ? "일반" : "유리"}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ContainerSelect;
