import React, { ChangeEvent, HTMLAttributes } from "react";

interface SelectDriverProps extends HTMLAttributes<HTMLSelectElement> {
  id?: string;
  name?: string;
  title?: string;
  placeholder?: string;
  value?: string | number;
  width?: number;
  height?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  additionalText?: string;
  options?: {
    value: string;
  }[];
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const SelectDriver = ({
  id,
  name,
  fullWidth,
  width,
  height,
  additionalText,
  placeholder,
  options,
  onChange,
  value,
  ...props
}: SelectDriverProps) => {
  // Classes
  const widthClass = fullWidth ? "w-full" : width ? `w-[${width}px]` : "w-auto";

  return (
    <div className="flex flex-col">
      <select
        required
        name={name}
        value={value}
        placeholder={placeholder}
        className={`box-border h-[52px] rounded-lg  border border-solid p-4 text-custom-black ${widthClass}`}
        onChange={onChange}
        {...props}
      >
        <option disabled value="" selected>
          회사차량/개인차량
        </option>
        {options &&
          options.map((option, index) => (
            <option key={option.value + index} value={option.value}>
              {option.value === "company" ? "회사차량" : "개인차량"}
            </option>
          ))}
      </select>
      {additionalText && (
        <span className="mt-[0.75vh] self-end text-[0.875rem] leading-[1.25rem] text-custom-mainYellow">
          {additionalText}
        </span>
      )}
    </div>
  );
};

export default SelectDriver;
