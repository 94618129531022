import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/svgs/MainLogo.svg";
import AuthInput from "./AuthInput";
import Button from "../button/Button";
import authApis from "../../api/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loginInfoAtom } from "../../lib/recoils/atoms/LoginInfoAtom";
import webStorage from "../../lib/utils/WebStorage";
import axios from "axios";
import { shopInfoAtom } from "../../lib/recoils/atoms/shopInfoAtom";
import { ShopInfo } from "../../types/UserTypes";

interface AuthContentContainerProps {
  contentType: "signIn" | "verification" | "resetPassword";
}

export interface SignInFormData {
  mobile: string;
  password: string;
}

interface VerificationFormData {
  mobile: string;
  authenticationNumber: string;
}

interface ResetFormData {
  password: string;
  confirm: string;
}

const AuthContentContainer = ({ contentType }: AuthContentContainerProps) => {
  // Hooks
  const { state, search } = useLocation();
  const navigate = useNavigate();

  // States
  const [signInFormData, setSignInFormData] = useState<SignInFormData>({
    mobile: "",
    password: "",
  });
  const [count, setCount] = useState(180);
  const [isCertificationComplete, setIsCertificationComplete] = useState(false);
  const [verificationFormData, setVerificationFormData] =
    useState<VerificationFormData>({
      mobile: "",
      authenticationNumber: "",
    });

  const [resetFormData, setResetFormData] = useState<ResetFormData>({
    password: "",
    confirm: "",
  });

  // Recoil
  const [loginInfo, setLoginInfo] = useRecoilState(loginInfoAtom);
  const [shopInfo, setShopInfo] = useRecoilState(shopInfoAtom);

  // Classes
  const layoutClassName = `w-[856px] flex flex-col box-border justify-between items-center  rounded-[1.25rem] bg-white shadow-xl   pl-[115px] pr-[115px] h-[76.48vh] pt-[52px] pb-[80px]`;
  const wrapperClass = `flex flex-col justify-center items-center gap-y-[60px]`;

  // Functions
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log("asdasd", name);
    if (contentType === "signIn") {
      setSignInFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (contentType === "verification") {
      const isNumber = !isNaN(Number(value));
      if (!isNumber) return;
      setVerificationFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setResetFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (contentType === "signIn") {
      const response = await authApis.signInShop(signInFormData);
      if (response) {
        const { status, data } = response;
        if (status === 200) {
          webStorage.setStorage("local", "accessToken", data.accessToken);
          webStorage.setStorage("local", "refreshToken", data.refreshToken);
          axios.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;

          const { data: shopData } = await axios<{
            message: string;
            data: ShopInfo[];
          }>("/shops/shop-info");
          setShopInfo(shopData.data);
          setLoginInfo(true);

          if (shopData.data[0].id === 1) {
            console.log("admin 입니다.");
            navigate("/hq/io");
            return;
          } else {
            console.log("일반 유저입니다.");
            navigate("/io");
            return;
          }
        }
      }
    }
  };

  const getVerificationNumber = async () => {
    if (!verificationFormData.mobile) {
      alert("핸드폰 번호를 입력해주세요.");
      return;
    }
    if (isCertificationComplete) return;
    if (count === 0) setCount(179);
    else if (count > 0 && count < 180) return;
    else setCount((prev) => prev - 1);

    const getAuthNumberResult = await authApis.getMobileCertification(
      verificationFormData.mobile,
    );

    if (getAuthNumberResult) {
      alert("인증번호 발급 성공");
      return;
    } else {
      alert("인증번호 발급 실패");
      return;
    }
  };

  const checkVerificationCode = async () => {
    if (
      !verificationFormData.authenticationNumber ||
      !verificationFormData.mobile
    ) {
      alert("번호, 전화번호를 입력해주세요");
      return;
    }
    if (verificationFormData.authenticationNumber.length !== 6) {
      alert("인증번호를 확인해주세요.");
      return;
    }

    const verificatoinResult = await authApis.checkVerificationCode(
      verificationFormData.authenticationNumber,
      verificationFormData.mobile,
    );
    if (verificatoinResult.result) {
      if (verificatoinResult.data) {
        navigate("/reset-password", {
          state: { shopId: verificatoinResult.data.id },
        });
      } else {
        alert("해당 전화번호로 등록된 매장이 없습니다.");
        return;
      }
    } else {
      alert("인증에 실패했습니다.");
      return;
    }
  };

  const onClickResetPassword = async () => {
    if (resetFormData.confirm !== resetFormData.password) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    const updateShopPasswordRes = await authApis.updateShopPassword(
      state.shopId,
      resetFormData.password,
    );
    if (updateShopPasswordRes !== 500) {
      alert("비밀번호 변경에 성공했습니다.");
      navigate("/");
    } else {
      alert("비밀번호 변경에 실패했습니다.");
    }
  };

  const onClickButton = () => {
    if (contentType === "verification") {
      checkVerificationCode();
    } else if (contentType === "resetPassword") {
      onClickResetPassword();
    }
  };

  // Effects
  useEffect(() => {
    if (loginInfo) {
      setVerificationFormData({
        authenticationNumber: "",
        mobile: "",
      });
      setCount(180);
      navigate("/io");
    }
  }, [loginInfo, navigate]);

  useEffect(() => {
    if (isCertificationComplete) return;
    if (count === 180 || count <= 0) return;

    const timer = setTimeout(() => setCount((p) => p - 1), 1000);

    return () => clearTimeout(timer);
  }, [count]);

  useEffect(() => {
    console.log("스테이트!", state);
    console.log("서치", search);
  }, [state, search]);

  return (
    <div className={layoutClassName}>
      <div className={wrapperClass}>
        <Logo />
        <strong className="text-[1.25rem] font-semibold text-[#9b9c92]">
          {contentType === "signIn"
            ? "담음 관리자 페이지에 오신 것을 환영 합니다."
            : contentType === "verification"
            ? "비밀번호를 초기화 합니다."
            : "새 비밀번호를 입력해주세요."}
        </strong>
      </div>

      <form className="flex flex-col gap-y-[65px]" onSubmit={onSubmit}>
        {contentType === "signIn" ? (
          <>
            <AuthInput
              type="mobile"
              onChange={handleInputChange}
              value={signInFormData.mobile}
              required
            />
            <AuthInput
              type="password"
              onChange={handleInputChange}
              value={signInFormData.password}
              required
            />
          </>
        ) : contentType === "verification" ? (
          <>
            <AuthInput
              type="phoneNumber"
              onChange={handleInputChange}
              value={verificationFormData.mobile}
              maxLength={11}
              onButtonClick={getVerificationNumber}
            />
            <AuthInput
              type="authenticationNumber"
              onChange={handleInputChange}
              value={verificationFormData.authenticationNumber}
              maxLength={6}
              timer={count}
            />
          </>
        ) : (
          <>
            <AuthInput
              type="password"
              onChange={handleInputChange}
              value={resetFormData.password}
              placeholder="새 비밀번호"
              name="password"
            />
            <AuthInput
              type="password"
              onChange={handleInputChange}
              value={resetFormData.confirm}
              placeholder="비밀번호 확인"
              name="confirm"
            />
          </>
        )}

        <Button
          title={contentType === "signIn" ? "로그인" : "비밀번호 초기화"}
          width={626}
          height={54}
          buttoncolor="custom-mainYellow"
          onClick={onClickButton}
        />
      </form>
      <div
        onClick={() => {
          setSignInFormData({
            mobile: "",
            password: "",
          });
          setVerificationFormData({
            authenticationNumber: "",
            mobile: "",
          });
          navigate(contentType === "signIn" ? "/reset" : "/");
        }}
        className="cursor-pointer self-end text-center text-[1.25rem] font-semibold text-[#9b9c92]"
      >
        {contentType === "signIn" ? "비밀번호 초기화" : "로그인 하기"}
      </div>
    </div>
  );
};

export default AuthContentContainer;
