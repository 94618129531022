import React, { ReactNode } from "react";

interface ModalContainerProps {
  heightVh?: number;
  height?: number;
  widthVw?: number | string;
  width?: number;
  children: ReactNode;
}

const ModalContainer = ({
  height,
  heightVh,
  width,
  widthVw,
  children,
}: ModalContainerProps) => {
  // Styles
  const widthStyle = widthVw ? `${widthVw}vw` : width ? `${width}px` : "auto";
  const heightStyle = heightVh
    ? `${heightVh}vw`
    : height
    ? `${height}px`
    : "w-auto";

  return (
    <div
      className="z-20 box-border flex shrink-0 flex-col justify-between rounded-[8px] bg-white px-custom-modal-x py-custom-modal-x"
      style={{
        width: widthStyle,
        height: heightStyle,
      }}
    >
      {children}
    </div>
  );
};

export default ModalContainer;
