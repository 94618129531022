import { MouseEvent, ReactNode } from "react";
import { useModal } from "../../lib/hooks/UseModal";
import { convertDateToNormal } from "../../lib/utils/FormatDateToNormal";
import { formatMobileString } from "../../lib/utils/FormatMobileString";
import { UserProps } from "../../types/UserTypes";
import InputBase from "../input/InputBase";
import DepositModal from "./DepositModal";
import ManageCustomerModal from "./ManageCustomerModal";

interface PageUserRowProps {
  data: UserProps;
  refetch?: () => void;
  index: number;
}

const Title = ({
  children,
  isMobile,
}: {
  children: ReactNode;
  isMobile?: boolean;
}) => {
  return (
    <strong
      className={`font-bold  text-custom-black ${
        isMobile
          ? "text-[0.875rem] leading-[1.25rem]"
          : "text-[1.25rem] leading-[1.75rem]"
      } `}
    >
      {children}
    </strong>
  );
};

const StatusBadge = ({ isAppRegisterd }: { isAppRegisterd?: "YES" | "NO" }) => {
  return (
    <div
      className={` box-border flex h-[24px] items-center justify-center px-[0.5rem] py-[0.25rem] text-[0.75rem] leading-[1rem] opacity-0 ${
        isAppRegisterd === "YES" &&
        "text-custom rounded-[8px] bg-[#E8F2FE] text-custom-mainBlue opacity-100"
      }`}
    >
      {isAppRegisterd === "YES" && "앱 가입 완료"}
    </div>
  );
};

const CustomerPageComponentRow = ({
  data,
  refetch,
  index,
}: PageUserRowProps) => {
  // Hooks
  const { openModal } = useModal();

  // Functions
  const onClickManageCustomer = (e: MouseEvent<HTMLLIElement>) => {
    openModal({
      content: (
        <ManageCustomerModal refetch={refetch} type="modify" user={data} />
      ),
    });
  };

  const onClickDepositModal = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    console.log("deposit mOdal");
    openModal({
      content: <DepositModal refetch={refetch} user={data} />,
    });
  };

  return (
    <li className="cursor-pointer list-none" onClick={onClickManageCustomer}>
      <div className="grid w-full grid-cols-custom-customerRow items-center overflow-hidden">
        <Title>{index + 1}</Title>
        <Title>{data.userName}</Title>
        <Title isMobile>{formatMobileString(data.mobile)}</Title>

        <span className="text-[1rem] leading-[1.5rem] text-custom-gray">
          {`${data.address} ${data.addressDetail}`}
        </span>

        <span className="text-[1rem] leading-[1.5rem] text-custom-gray">
          {data.description}
        </span>

        <span className="text-center text-[0.875rem] font-medium leading-[1.25rem] text-custom-gray">
          {`등록일 : ${convertDateToNormal(data.createdAt)}`}
        </span>

        <span className="text-center text-[0.875rem] font-medium leading-[1.25rem] text-custom-gray">
          {`총 발송 횟수 : ${data.totalOrder}`}
        </span>
        <div className={`min-w-[78px]`}>
          <StatusBadge isAppRegisterd={data.inUseApp} />
        </div>
        <div
          className="relative flex cursor-pointer pl-[2vw]"
          onClick={onClickDepositModal}
        >
          <div className="box-border flex h-[4vh] w-full items-center rounded-lg border border-solid border-gray-200 bg-gray-50 px-3 text-gray-400">
            {data.totalDeposit === null ? "0" : String(data.totalDeposit)}
          </div>
        </div>
      </div>
    </li>
  );
};

export default CustomerPageComponentRow;
