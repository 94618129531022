import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import shopsApi from "../api/Shops";
import ContentTitle from "../components/common/ContentTitle";
import MainContentContainer from "../components/common/MainContentContainer";
import ImageSelection from "../components/ShopSettingPage/ImageSelection";
import ShopInfoForm from "../components/ShopSettingPage/ShopInfoForm";
import Span from "../components/span/Span";
import useShopSettingControl from "../lib/hooks/UseShopSettingControl";
import { refetchFunctionsAtom } from "../lib/recoils/RefetchFunctionsAtom";

const ShopSettingPage = () => {
  // Hooks
  const { cleanShopSettingState, setShopSettingState, shopSettingState } =
    useShopSettingControl();

  // Query
  const { data: shopInfo, refetch } = useQuery({
    queryKey: ["shopInfo"],
    queryFn: shopsApi.getShopInfo,
  });

  // Recoil
  const setRefetchFunctions = useSetRecoilState(refetchFunctionsAtom);

  // Effects
  useEffect(() => {
    if (shopInfo && shopInfo[0]) {
      setShopSettingState((prev) => ({
        ...prev,
        shopId: shopInfo[0].id,
        address: shopInfo[0].address,
        addressDetail: shopInfo[0].address2,
        name: shopInfo[0].name,
        mobile: shopInfo[0].mobile,
        owner: shopInfo[0].owner,
        logoUrl: shopInfo[0].logo ?? null,
        imgUrl: shopInfo[0].img_url ?? null,
        imgUrl2: shopInfo[0].img_url2 ?? null,
        imgUrl3: shopInfo[0].img_url3 ?? null,
        imgUrl4: shopInfo[0].img_url4 ?? null,
        imgUrl5: shopInfo[0].img_url5 ?? null,
        imgUrl6: shopInfo[0].img_url6 ?? null,
        imgUrl7: shopInfo[0].img_url7 ?? null,
        imgUrl8: shopInfo[0].img_url8 ?? null,
        imgUrl9: shopInfo[0].img_url9 ?? null,
        imgUrl10: shopInfo[0].img_url10 ?? null,
      }));
    }

    return () => {
      cleanShopSettingState();
    };
  }, [shopInfo]);

  useEffect(() => {
    if (setRefetchFunctions) {
      setRefetchFunctions((prev) => ({ ...prev, refetchShopSetting: refetch }));
    }
  }, [refetch, setRefetchFunctions]);

  useEffect(() => {
    console.log("sss");
    console.log(shopSettingState);
  }, [shopSettingState]);

  if (!shopInfo) return null;

  return (
    <div className="flex h-full w-full flex-col">
      <ContentTitle hasMb>설정</ContentTitle>
      <MainContentContainer>
        <Span type="14pxNormalBlack">
          매장 정보 및 운영에 필요한 설정을 할 수 있습니다.
        </Span>
        {shopInfo ? (
          <div className="flex h-full w-full gap-x-[4.69vw]">
            <ShopInfoForm shopInfo={shopSettingState} />
            <ImageSelection />
          </div>
        ) : null}
      </MainContentContainer>
    </div>
  );
};

export default ShopSettingPage;
