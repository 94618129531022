import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { ChangeEvent, useEffect, useRef } from "react";
import useShopOrderControl from "../../lib/hooks/UseShopOrderControl";
import { OrderDriverProps } from "../../lib/queries/UseShopOrderDriverQuery";
import { pxToVh } from "../../lib/utils/TransformPxToViewPort";
import Button from "../button/Button";
import Badge from "../common/Badge";
import Separator from "../common/Separator";
import SearchInput from "../input/SearchInput";
import Span from "../span/Span";
import { TransformedOrder } from "../../types/OrderTypes";

const DriverList = ({
  orders,
  drivers,
  fetchNextPage,
  isFechingNextPage,
  hasNextPage,
  disabled,
}: {
  orders: TransformedOrder[];
  drivers: OrderDriverProps[];
  isFechingNextPage: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  disabled: boolean;
}) => {
  // Hooks
  const {
    shopOrderStates: { selectedDriverId, selectedDate, selectedOrder },
    setSelectedDriver,
    assignDriver,
    setShopOrderStates,
  } = useShopOrderControl();

  // Refs
  const scrollableDriverDivRef = useRef<HTMLDivElement>(null);

  // Functions
  const debounceSetDriverSearchTerm = debounce((value) => {
    setShopOrderStates((prev) => ({ ...prev, driverSearchTerm: value }));
  }, 500);

  const onChangeDriverNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    debounceSetDriverSearchTerm(value);
  };

  // Effects
  // 무한 스크롤
  useEffect(() => {
    const scrollableDriverDiv = scrollableDriverDivRef.current;

    const checkDriverScrollPosition = debounce(() => {
      if (!scrollableDriverDiv) return;

      const isNearBottom =
        scrollableDriverDiv.scrollHeight - scrollableDriverDiv.scrollTop <=
        scrollableDriverDiv.clientHeight + 100;

      if (isNearBottom) {
        console.log(hasNextPage);
        if (isFechingNextPage || !hasNextPage) return;
        fetchNextPage();
      }
    }, 250);

    if (scrollableDriverDiv) {
      scrollableDriverDiv.addEventListener("scroll", checkDriverScrollPosition);
    }

    return () => {
      if (scrollableDriverDiv) {
        scrollableDriverDiv.removeEventListener(
          "scroll",
          checkDriverScrollPosition,
        );
      }
    };
  }, [hasNextPage, fetchNextPage, isFechingNextPage]);

  // 기사 디바운스

  return (
    <article className="flex grow flex-col">
      <div className="mb-[1vh]">
        <SearchInput
          height={"4vh"}
          placeholder="기사명"
          name="driverSearchTerm"
          onChange={onChangeDriverNameInput}
        />
      </div>
      <Span type="14pxNormalBlack">배송기사 선택</Span>
      <div className="custom-border-container flex h-[48.14vh] grow flex-col overflow-hidden">
        <div
          className="relative flex grow flex-col overflow-auto"
          ref={scrollableDriverDivRef}
        >
          {drivers.map((driver, index) => {
            if (!driver) {
              return (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 items-center justify-center ">
                  <Span type="16pxBoldBlack">해당 조건의 기사가 없습니다.</Span>
                </div>
              );
            }
            return (
              <article
                key={index + Math.random() + driver.driverId}
                className={`relative box-border flex min-h-[9.26vh] w-full cursor-pointer items-center ${
                  selectedDriverId === driver.driverId
                    ? "bg-custom-lightBlue"
                    : ""
                }`}
                onClick={() => {
                  if (disabled) {
                    // 현재 체크된 수동 주문이 있으면 체크 가능
                    // selectedOrder 배열에 있는 id의 주문 이 "모두" orderType이 manualOrder라면 기사 선택 가능
                    const isAllManualOrder = selectedOrder.every((eachId) => {
                      const order = orders.find(
                        (eachOrder) => eachOrder.orderId === eachId,
                      );
                      return order && order.orderType === "manualOrder";
                    });

                    if (isAllManualOrder) {
                      setSelectedDriver(driver.driverId);
                    } else {
                      setSelectedDriver(0);
                      return;
                    }
                  }
                  setSelectedDriver(driver.driverId);
                }}
              >
                <div className="box-border flex w-full justify-between ">
                  <div className="flex w-[30%] justify-center">
                    <img
                      src={require("../../assets/images/TempDriver.png")}
                      alt="대기중 배송기사"
                    />
                  </div>
                  <div className="flex grow flex-col justify-between">
                    <div className="flex gap-x-[0.42vw]">
                      <div className="w-[50%]">
                        <Span type="16pxBoldBlack">{driver.name}</Span>
                      </div>
                      <div className="flex grow">
                        {driver.deliveryWaiting === "YES" ? (
                          <Badge
                            textColor="custom-mainYellow"
                            badgeColor="custom-lightYellow"
                          >
                            배송가능
                          </Badge>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <Span type="12pxNormalGray">{driver.vehicleNum}</Span>
                    </div>
                  </div>
                  {/** Absolute */}
                  <div className="absolute bottom-0 w-full">
                    <Separator />
                  </div>
                </div>
              </article>
            );
          })}
        </div>
      </div>
      <div className="mt-[1.1vh] flex w-full grow flex-col items-center justify-between">
        <span className="text-center text-[12px] leading-[16px] text-custom-mediumGray">
          기사를 추가/변경하려면 기사항목에서 진행해 주세요.
        </span>

        <Button
          style={{
            width: 120,
            height: pxToVh(60, "string"),
          }}
          title="저장하기"
          onClick={() => assignDriver()}
          disabled={
            selectedDriverId
              ? false
              : dayjs(selectedDate).format("YYYY-MM-DD") !==
                  dayjs().format("YYYY-MM-DD") || disabled
          }
        />
      </div>
    </article>
  );
};

export default DriverList;
