import axios, { isAxiosError } from "axios";
import { SignInFormData } from "../components/AuthPage/AuthContentContainer";
import webStorage from "../lib/utils/WebStorage";

type SignUpShopResponse = { status: number; data: { message: string } };

const authApis = {
  // 매장 로그인 API
  signInShop: async (formData: SignInFormData) => {
    try {
      const response = await axios.post<{
        message: string;
        accessToken: string;
        refreshToken: string;
      }>("/shops/sign-in", formData);
      const { data, status } = response;
      if (status === 200) {
        const { accessToken, refreshToken } = data;
        webStorage.setStorage("local", "accessToken", accessToken);
        webStorage.setStorage("local", "refreshToken", refreshToken);
      }
      return { data, status };
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        const code = error.response?.status;
        if (code === 401) {
          console.log(error.response?.data);
        }
      }
      alert("아이디/비밀번호를 확인해주세요.");
      return null;
    }
  },

  // 핸드폰 인증 번호 받기
  getMobileCertification: async (mobile: string) => {
    try {
      const getAuthNumberResult = await axios.post<{ result: boolean }>(
        "/shops/mobile-auth",
        {
          mobile,
        },
      );
      return getAuthNumberResult.data.result;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  // 핸드폰 인증 번호 확인
  checkVerificationCode: async (code: string, mobile: string) => {
    try {
      const { data } = await axios.post<{
        result: boolean;
        message: string;
        data?: { id: number };
      }>("/shops/mobile-verification", { code, mobile });
      return data;
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        const message = error.response?.data.message;
        if (message === "INVALID CODE") {
          alert("인증 코드를 확인해주세요.");
          return {
            result: false,
            message: error.response?.data.message,
            data: null,
          };
        }
      }
      return { result: false, message: "error", data: null };
    }
  },

  // 매장-매장 비밀번호 바꾸기
  updateShopPassword: async (shopId: number, password: string) => {
    try {
      const { status } = await axios.patch<{ message: string }>(
        "/shops/password-change",
        {
          shopId,
          password,
        },
      );
      return status;
    } catch (error) {
      console.log(error);
      return 500;
    }
  },

  // 본사-매장 등록 API
  signUpShop: async (formData: {
    name: string;
    owner: string;
    address: string;
    addressDetail: string;
    mobile: string;
  }): Promise<SignUpShopResponse> => {
    try {
      const response = await axios.post("/shops/sign-up", formData);
      const { data, status } = response;
      return { data, status };
    } catch (error) {
      if (isAxiosError(error)) {
        console.log(error.response?.status);
        const code = error.response?.status;
        if (code === 400) {
          console.log("axios error", error);
          return { status: code, data: { message: error.response?.data } };
        }
      }

      return { status: 400, data: { message: "exist shop" } };
    }
  },
};

export default authApis;
