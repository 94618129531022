import { useQuery } from "@tanstack/react-query";
import React, { MouseEvent, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import hqApis from "../api/HqApis";
import Button from "../components/button/Button";
import FilterButton from "../components/button/FilterButton";
import ContentTitle from "../components/common/ContentTitle";
import MainContentContainer from "../components/common/MainContentContainer";
import PageComponent from "../components/common/PageComponent";
import HQContainersPageComponentRow from "../components/HQContainersPage/HQContainersPageComponentRow";
import HqContainerStatusModal from "../components/HQContainersPage/HqContainerStatusModal";
import HQManageContainerModal from "../components/HQContainersPage/HQManageContainerModal";
import Span from "../components/span/Span";
import { useModal } from "../lib/hooks/UseModal";
import { refetchFunctionsAtom } from "../lib/recoils/RefetchFunctionsAtom";

const HQContainersPage = () => {
  // Hooks
  const { openModal } = useModal();

  // States
  const [sortOrder, setSortOrder] = useState<"bottle" | "glass" | "">("");

  // Query
  const { data: containerData, refetch } = useQuery({
    queryKey: ["hqContainers", sortOrder],
    queryFn: async () => await hqApis.getContainers(sortOrder),
  });

  // Recoil
  const setRefetchFunctions = useSetRecoilState(refetchFunctionsAtom);

  // Functions
  const onClickManageContainer = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openModal({
      content: <HQManageContainerModal />,
    });
  };

  const onClickFilterButton = (filter: "bottle" | "glass") => {
    if (filter === sortOrder) setSortOrder("");
    else setSortOrder(filter);
  };

  // Effects
  useEffect(() => {
    if (setRefetchFunctions) {
      setRefetchFunctions((prev) => ({
        ...prev,
        refetchHqContainers: refetch,
      }));
    }
  }, [refetch, setRefetchFunctions]);

  return (
    <div className="flex w-full flex-col">
      <ContentTitle hasMb>박스 & 용기</ContentTitle>
      <MainContentContainer>
        <div className="absolute -top-[3.7vh] right-0 -translate-y-full">
          <Button
            title="전체 현황"
            buttoncolor="custom-mainBlue"
            style={{ width: "6.25vw", height: "4vh", marginRight: "2.08vw" }}
            onClick={() => openModal({ content: <HqContainerStatusModal /> })}
          />
        </div>

        <div className="flex w-full items-center justify-between">
          <Span type="14pxNormalBlack">
            박스 및 용기 등록 관리 내역 입니다.
          </Span>
          <div className="flex items-center">
            <section className="mr-[3.85vw] flex gap-x-[1.35vw]">
              <FilterButton
                title="박스"
                whereTo="hqContainer"
                onClick={() => onClickFilterButton("bottle")}
                isSelected={sortOrder === "bottle"}
              />
              <FilterButton
                title="용기"
                whereTo="hqContainer"
                onClick={() => onClickFilterButton("glass")}
                isSelected={sortOrder === "glass"}
              />
            </section>
            <section>
              <Button
                title="박스 용기 관리"
                style={{ height: "4vh", width: "6.25vw" }}
                onClick={onClickManageContainer}
              />
            </section>
          </div>
        </div>

        <PageComponent title="등록 현황">
          {containerData && containerData.length > 0 ? (
            containerData.map((container, index) => (
              <HQContainersPageComponentRow
                key={container.id + index + Math.random()}
                container={container}
                index={index + 1}
              />
            ))
          ) : (
            <div className="flex h-full w-full items-center justify-center">
              <h1>등록된 용기가 없습니다.</h1>
            </div>
          )}
        </PageComponent>
      </MainContentContainer>
    </div>
  );
};

export default HQContainersPage;
