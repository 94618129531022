import React, { useEffect, useState } from "react";
import { useModal } from "../../../lib/hooks/UseModal";
import ContentTitle from "../ContentTitle";
import Separator from "../Separator";
import Button from "../../button/Button";
import { useQuery } from "@tanstack/react-query";
import {
  HqToShopRequestData,
  HqTransferListRes,
} from "../../../types/HqInAndOutPageTypes";
import hqApis from "../../../api/HqApis";
import Span from "../../span/Span";
import Badge from "../Badge";
import { ReactComponent as AddCircle } from "../../../assets/svgs/AddCircle.svg";
import AlertModal from "../../modal/AlertModal";
import { ShopInfo } from "../../../types/UserTypes";
import axios from "axios";

const HqCallDriverModal = ({
  transferList,
}: {
  transferList: HqTransferListRes | null | undefined;
}) => {
  // Hooks
  const { closeModal, changeModal } = useModal();

  // Query
  const { data, isError, refetch } = useQuery({
    queryKey: ["hqToShopRequestTotalList"],
    queryFn: async () => hqApis.getHqToShopRequestTotalList(),
  });

  const {
    data: hqDrivers,
    isError: hqDriversError,
    refetch: refetchDriver,
  } = useQuery({
    queryKey: ["hqDriverList", "asc"],
    queryFn: async () => hqApis.getHqDriverList(1, "asc", "", 300),
  });

  // States
  const [showDriverModal, setShowDriverModal] = useState(false);
  const [targetData, setTargetData] = useState<HqToShopRequestData[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<number | null>(null);
  const [groupedByShopName, setGroupedByShopName] = useState<
    { name: string; data: { data: HqToShopRequestData[]; shopId: number } }[]
  >([]);

  // Functions
  const onClickAddCircle = (
    shopId: number,
    willTargetData: HqToShopRequestData[],
  ) => {
    setShowDriverModal(true);
    setTargetData(willTargetData);
  };

  const onClickAssignDriver = async () => {
    if (!selectedDriver) return;
    console.log("타켓 데터", targetData);
    const containerHistoryIds = targetData.map(
      (target) => target.containerHistoriesId,
    );
    const assignResponse = await hqApis.assignDriverRequestAndRecall(
      containerHistoryIds,
      selectedDriver,
    );
    if (assignResponse === 200) {
      changeModal({
        content: <AlertModal alertType="check" message="기사배정 성공" />,
      });
      refetch();
      refetchDriver();
      return;
    } else {
      alert("오류 발생");
      refetch();
      refetchDriver();
      return;
    }
  };

  const onClickProceed = async () => {
    const { data: shopInfo } = await axios<{
      message: string;
      data: ShopInfo[];
    }>("/shops/shop-info");

    console.log(shopInfo.data[0]);
    if (shopInfo.data[0].id !== 1) return;

    const proceedResponse = await hqApis.proceedHqRequestAndRecall();

    if (proceedResponse !== 400) {
      refetch();
      changeModal({ content: <AlertModal alertType="check" message="성공" /> });
      return;
    } else {
      alert("오류 발생");
      refetch();
      return;
    }
  };

  // Effects
  useEffect(() => {
    if (!data) return;
    const willGroupedByShopName = data.reduce((acc, cur) => {
      if (!acc[cur.name]) {
        acc[cur.name] = {
          shopId: cur.shopId,
          data: [],
        };
      }

      acc[cur.name].data.push(cur);
      return acc;
    }, {} as any);

    console.log("리듀스", willGroupedByShopName);

    setGroupedByShopName(
      Object.keys(willGroupedByShopName).map((key) => ({
        shopId: willGroupedByShopName[key].shopId,
        name: key,
        data: willGroupedByShopName[key],
      })),
    );
    console.log(
      "모달 데이터",
      Object.keys(willGroupedByShopName).map((key) => ({
        name: key,
        data: willGroupedByShopName[key],
      })),
    );
  }, [data]);

  return (
    <div className="relative z-20 box-border flex w-[38.5vw] flex-col justify-between rounded-[8px] bg-white px-custom-modal-x py-custom-modal-x">
      <ContentTitle isCenter>기사 호출</ContentTitle>

      <ContentTitle>전체 내역</ContentTitle>

      <div className="flex h-[43.33vh] w-full flex-col overflow-auto rounded-[8px] border border-solid border-custom-mediumGray px-[1.6vw] py-[1.85vh]">
        {isError ? (
          <div className="flex grow items-center justify-center">오류 발생</div>
        ) : null}
        {data && data.length > 0 && groupedByShopName.length > 0 ? (
          groupedByShopName.map((shop, index) => {
            const hasDriver = shop.data.data.some(
              (eachShop) => eachShop.driverId,
            );
            return (
              <div
                key={index + Math.random() + shop.name}
                className="flex w-full flex-col"
              >
                <div className="relative mb-5 flex w-full justify-between">
                  <ContentTitle>{shop.name}</ContentTitle>
                  <div className="absolute right-0 flex flex-col items-center">
                    {hasDriver ? (
                      <Span type="20pxBoldPureBlack">기사 정보</Span>
                    ) : (
                      <AddCircle
                        onClick={() =>
                          onClickAddCircle(shop.data.shopId, shop.data.data)
                        }
                      />
                    )}

                    <Span type="16pxBoldBlack">
                      {hasDriver ? shop.data.data[0].driverName : "기사 선택"}
                    </Span>
                    <Span type="16pxBoldBlack">
                      {hasDriver ? shop.data.data[0].driverMobile : "기사 선택"}
                    </Span>
                  </div>
                </div>
                {shop.data && shop.data.data.length > 0 ? (
                  <div className="flex w-full flex-col justify-center gap-y-6">
                    {shop.data.data.map((each, index) => {
                      const isRequest = each.status === "transferDriverMatched";
                      return (
                        <div
                          key={index + Math.random() + each.shopId}
                          className="flex w-3/4 items-center justify-between"
                        >
                          <Badge
                            badgeColor={
                              isRequest
                                ? "custom-lightYellow"
                                : "custom-lightBlue"
                            }
                            textColor={
                              isRequest
                                ? "custom-mainYellow"
                                : "custom-mainBlue"
                            }
                          >
                            {isRequest ? "요청" : "반납"}
                          </Badge>
                          <Span type="14pxBoldBlack">{each.containerName}</Span>
                          <Span type="14pxBoldGray">
                            {each.containerDetail}
                          </Span>
                          <Span type="14pxBoldBlack">{each.quantity}개</Span>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })
        ) : (
          <div className="flex grow items-center justify-center">
            내역이 없습니다.
          </div>
        )}
      </div>
      <div className="mt-[5.6vh] flex w-full justify-center gap-x-[0.68vw]">
        <Button
          title="이송 취소"
          buttoncolor="custom-gray"
          width={103}
          height={52}
          onClick={closeModal}
        />
        <Button
          title="이송 진행"
          buttoncolor="custom-mainYellow"
          width={219}
          height={52}
          onClick={onClickProceed}
        />
      </div>

      {showDriverModal && !hqDriversError && hqDrivers ? (
        <section className="absolute -right-1/2 top-0 box-border flex h-full w-[18.65vw] flex-col rounded-lg bg-white px-custom-modal-x py-custom-modal-x ">
          <div className="mb-11">
            <ContentTitle>이송 가능 기사 선택</ContentTitle>
          </div>
          <div className="h-full overflow-hidden rounded-lg border border-solid border-custom-mediumGray">
            <div className="flex h-full w-full flex-col overflow-auto">
              {hqDrivers.data.map((driver, index) => {
                return (
                  <article
                    key={index + Math.random() + driver.driverId}
                    className={`relative box-border flex min-h-[9.26vh] w-full cursor-pointer items-center ${
                      selectedDriver === driver.driverId
                        ? "bg-custom-lightBlue"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedDriver(driver.driverId);
                    }}
                  >
                    <div className="box-border flex w-full justify-between ">
                      <div className="flex w-[30%] justify-center">
                        <img
                          src={require("../../../assets/images/TempDriver.png")}
                          alt="대기중 배송기사"
                        />
                      </div>
                      <div className="flex grow flex-col justify-between">
                        <div className="flex gap-x-[0.42vw]">
                          <div className="w-[50%]">
                            <Span type="16pxBoldBlack">{driver.name}</Span>
                          </div>
                          <div className="flex grow">
                            {driver.delivery_waiting === "YES" ? (
                              <Badge
                                textColor="custom-mainYellow"
                                badgeColor="custom-lightYellow"
                              >
                                배송가능
                              </Badge>
                            ) : (
                              <Badge
                                textColor="custom-mainRed"
                                badgeColor="custom-lightRed"
                              >
                                배송불가
                              </Badge>
                            )}
                          </div>
                        </div>
                        <div className="flex justify-center">
                          <Span type="12pxNormalGray">{driver.vehicleNum}</Span>
                        </div>
                      </div>
                      {/** Absolute */}
                      <div className="absolute bottom-0 w-full">
                        <Separator />
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
          <div className="mt-[5.6vh] flex w-full">
            <Button
              title="선택"
              height={52}
              fullwidth
              onClick={onClickAssignDriver}
            />
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default HqCallDriverModal;
