import dayjs from "dayjs";
import { ChangeEvent } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import AlertModal from "../../components/modal/AlertModal";
import CancelOrderModal from "../../components/ShopOrderPage/CancelOrderModal";
import SpecialNoteAndCheckOrderAddressModal from "../../components/ShopOrderPage/SpecialNoteAndCheckOrderAddressModal";
import {
  FlatOrderList,
  SortType,
  TransformedOrder,
} from "../../types/OrderTypes";
import { shopOrderAtom } from "../recoils/atoms/ShopOrderPage/ShopOrderAtom";
import { refetchFunctionsAtom } from "../recoils/RefetchFunctionsAtom";
import { useModal } from "./UseModal";
import { useQuery } from "@tanstack/react-query";
import CallDriverModal from "../../components/InAndOutPage/CallDriverModal";

const useShopOrderControl = () => {
  // Hooks
  const { openModal } = useModal();
  const [shopOrderStates, setShopOrderStates] = useRecoilState(shopOrderAtom);

  // Recoils
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  /* --------------------------------------------- Query --------------------------------------------- */
  const {
    data: finishDriverCallDateTime,
    refetch: refetchFinishDriverCallDateTime,
  } = useQuery({
    queryKey: ["finishDriverCallDateTime"],
    queryFn: shopsApi.getFinishedCallingDriversDatetime,
  });

  /* --------------------------------------------- Functions --------------------------------------------- */
  const onClickCheckBox = (orderIds: number[]) => {
    let willNewSelectedOrders = [...shopOrderStates.selectedOrder];
    const isExists = orderIds.every((orderId) =>
      willNewSelectedOrders.includes(orderId),
    );
    if (!isExists) {
      orderIds.forEach((orderId) => willNewSelectedOrders.push(orderId));
    } else {
      orderIds.forEach((orderId) => {
        const existingIndex = willNewSelectedOrders.findIndex(
          (item) => item === orderId,
        );
        if (existingIndex !== -1) {
          willNewSelectedOrders.splice(existingIndex, 1);
        }
      });
    }
    setShopOrderStates((prev) => ({
      ...prev,
      selectedOrder: [...willNewSelectedOrders],
    }));
  };

  const handleSelectedIds = (ids: number[]) => {
    console.log("예아", ids);
    if (!ids || ids.length === 0) {
      console.log("ids 입력 확인", ids);
      return;
    }
    let willNewSelectedOrder = [...shopOrderStates.selectedOrder];
    console.log(willNewSelectedOrder);
    ids.forEach((id) => {
      console.log("id", id);
      const index = willNewSelectedOrder.indexOf(id);
      if (index > -1) {
        willNewSelectedOrder.splice(index, 1);
      } else {
        willNewSelectedOrder.push(id);
      }
    });

    setShopOrderStates((prev) => ({
      ...prev,
      selectedOrder: [...willNewSelectedOrder],
    }));
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "customerAndProductSearchTerm") {
      setShopOrderStates((prev) => ({
        ...prev,
        customerAndProductSearchTerm: value,
      }));
    } else if (name === "driverName") {
      setShopOrderStates((prev) => ({ ...prev, driverSearchTerm: value }));
    }
  };

  const checkIsToday = (date: Date | null) => {
    const today = new Date();
    if (date) {
      const result =
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
      setShopOrderStates((prev) => ({ ...prev, isToday: result }));
    }
  };

  const setSelectedOrder = (orderId: number) => {
    const currentSelectedOrder = [...shopOrderStates.selectedOrder];
    const exists = currentSelectedOrder.includes(orderId);
    if (exists) {
      const existItemIndex = currentSelectedOrder.indexOf(orderId);
      currentSelectedOrder.splice(existItemIndex, 1);
    } else {
      currentSelectedOrder.push(orderId);
    }
    console.log(currentSelectedOrder);
    setShopOrderStates((prev) => ({
      ...prev,
      selectedOrder: currentSelectedOrder,
    }));
  };

  const setSelectedDriver = (driverId: number) => {
    setShopOrderStates((prev) => ({ ...prev, selectedDriverId: driverId }));
  };

  const assignDriver = async () => {
    const disabled =
      dayjs(shopOrderStates.selectedDate).format("YYYY-MM-DD") !==
      dayjs().format("YYYY-MM-DD");
    if (disabled) {
      openModal({
        content: (
          <AlertModal
            alertType="denied"
            message="당일 주문만 배정할 수 있습니다."
          />
        ),
      });
      return;
    }

    // 선택된 주문, 기사가 없을때 경고
    if (
      !shopOrderStates.selectedOrder ||
      shopOrderStates.selectedOrder.length < 1 ||
      !shopOrderStates.selectedDriverId
    ) {
      openModal({
        content: (
          <AlertModal alertType="denied" message="기사를 배정해주세요." />
        ),
      });
      return;
    }

    const assignDriverResStatus = await shopsApi.assignDriver(
      shopOrderStates.selectedDriverId,
      shopOrderStates.selectedOrder,
    );
    // Recoil에서 인덱스 뺴서 체크 해제
    setShopOrderStates((prev) => ({ ...prev, selectedOrder: [] }));
    // 리패칭
    refetchFunctions &&
      refetchFunctions.refetchShop &&
      refetchFunctions.refetchShop();
    if (assignDriverResStatus === 200) {
      openModal({
        content: (
          <AlertModal alertType="check" message="기사 배정에 성공했습니다." />
        ),
      });
    } else {
      openModal({
        content: (
          <AlertModal alertType="denied" message="기사 배정에 실패했습니다." />
        ),
      });
    }
  };

  const onClickSpecialNote = (orderData: FlatOrderList) => {
    openModal({
      content: (
        <SpecialNoteAndCheckOrderAddressModal data={orderData} isSpecialNote />
      ),
    });
    refetchFunctions &&
      refetchFunctions.refetchShop &&
      refetchFunctions.refetchShop();
  };

  const onClickCancelManualOrder = (orderInfo: FlatOrderList) => {
    openModal({
      content: <CancelOrderModal shopOrderInfo={orderInfo} />,
    });
  };

  const onClickCancelOrder = (orderInfo: FlatOrderList) => {
    openModal({
      content: <CancelOrderModal shopOrderInfo={orderInfo} isNormal={true} />,
    });
  };

  /**
   * 금일 주문 마감 후 지정된 수동 주문에 배정된 기사 호출
   * 0. 기사 호출을 누르면 기사 호출이 마감 되었는가?
   * 1. 마감되지 않았다면 기존 로직 진행
   * 2. 마감 되었다면 (오늘 날짜에 / 수동 배송에 할당된 기사가 있다면) 추가 기사 호출 진행하기 위해 사요할 bool 리턴
   */
  const callDriversForAdditionalOrderBool = async () => {
    const MAX_RETRY = 5;
    let retryCount = 0;
    let finishDriverCallDateTimeObject: dayjs.Dayjs;
    let isDriverCalledToday: string;

    try {
      while (retryCount < MAX_RETRY) {
        if (!finishDriverCallDateTime) {
          refetchFinishDriverCallDateTime();
          await new Promise((resolve) => setTimeout(resolve, 300));
          throw new Error("query data is undefined");
        }

        finishDriverCallDateTimeObject = dayjs(
          finishDriverCallDateTime[0].finished_calling_drivers_datetime,
        );
        isDriverCalledToday =
          finishDriverCallDateTimeObject.format("YYYY-MM-DD HH:mm");

        console.log("지금 마지막 기사 호출 시간", isDriverCalledToday);

        // 오늘 기사 호출이 되었었는지 확인
        // 마감 날짜가 같은 날짜이고 && 현재 시간 > 마지막 기사 호출 시간이면 마감되었던 것
        const isDriverCalledAlready =
          finishDriverCallDateTimeObject.day() === dayjs().date() &&
          dayjs().isAfter(finishDriverCallDateTimeObject);
        console.log("오늘 마감이 되었나?", isDriverCalledAlready);

        // 오늘 기사
        return isDriverCalledAlready;
      }
    } catch (error) {
      console.log("금일 주문 마감 후 지정된 수정 에러", error);
      console.log("재시도함", retryCount);
      if (retryCount > MAX_RETRY) {
        return false;
      } else {
        retryCount++;
        await new Promise((resolve) => setTimeout(resolve, 300));
      }
    }
  };

  /**
   *
   */

  return {
    shopOrderStates,
    setShopOrderStates,
    onClickCheckBox,
    onChangeInput,
    checkIsToday,
    setSelectedOrder,
    setSelectedDriver,
    assignDriver,
    onClickSpecialNote,
    onClickCancelManualOrder,
    onClickCancelOrder,
    handleSelectedIds,
    setDriverAndProductSearchTerm: (newTerm: string) =>
      setShopOrderStates((prev) => ({
        ...prev,
        driverAndProductSearchTerm: newTerm,
      })),
    setDriverSearchTerm: (newTerm: string) =>
      setShopOrderStates((prev) => ({ ...prev, driverSearchTerm: newTerm })),
    setSortType: (newSortType: SortType) =>
      setShopOrderStates((prev) => ({ ...prev, sortType: newSortType })),
    setSelectedDate: (newDate: Date | null) =>
      setShopOrderStates((prev) => ({ ...prev, selectedDate: newDate })),
    setIsToday: (newIsToday: boolean) =>
      setShopOrderStates((prev) => ({ ...prev, isToday: newIsToday })),
    callDriversForAdditionalOrderBool,
  };
};

export default useShopOrderControl;
