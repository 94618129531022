import React from "react";
import InputBase from "./InputBase";
import { ReactComponent as SeacrhIcon } from "../../assets/svgs/Search.svg";
import { SearchInputProps } from "./InputTypes";
import { WhereTo } from "../../types/ComponentSizeTypes";

// Title과 같이 사용할때 아이콘 위치가 원하는 곳에 나오지 않아 InputBase에 searchIcon Props를 넘겨서 사용하는 것으로 대신함
// Title과 같이 사용할때 아이콘 위치가 원하는 곳에 나오지 않아 InputBase에 searchIcon Props를 넘겨서 사용하는 것으로 대신함
// Title과 같이 사용할때 아이콘 위치가 원하는 곳에 나오지 않아 InputBase에 searchIcon Props를 넘겨서 사용하는 것으로 대신함

export const widthClassList: { [key in WhereTo]: string } = {
  customerAndDriver: "w-[15.6vw]",
  inAndOut: "w-[15.6vw]",
  order: "w-[15.6vw]",
  hqContainer: "w-auto",
};

const SearchInput = ({
  onButtonClick,
  whereTo,
  ...props
}: SearchInputProps) => {
  const widthClass = whereTo
    ? widthClassList[whereTo]
      ? widthClassList[whereTo]
      : ""
    : "";
  return (
    <div className={`relative ${widthClass} `}>
      <InputBase fullWidth {...props} />
      <div className="absolute right-[0.63vw] top-1/2 -translate-y-1/2 cursor-pointer">
        <SeacrhIcon onClick={onButtonClick} />
      </div>
    </div>
  );
};

export default SearchInput;
