const webStorage = {
  setStorage: (storageType: "local" | "session", key: string, data: any) => {
    if (!data) return;
    const stringifyedData = JSON.stringify(data);

    storageType === "local"
      ? localStorage.setItem(key, stringifyedData)
      : sessionStorage.setItem(key, stringifyedData);
  },

  getStorage: (storageType: "local" | "session", key: string) => {
    const stringedData =
      storageType === "local"
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key);
    if (stringedData) {
      const data = JSON.parse(stringedData);
      return data;
    }
  },
};

export default webStorage;
