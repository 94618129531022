const AssignedBadge = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      onClick={onClick}
      className="flex shrink-0 cursor-pointer flex-col items-center justify-center rounded-[8px] bg-custom-mainBlue p-1 text-[0.75rem] leading-[1rem] text-white"
    >
      <span>배정</span>
      <span>완료</span>
    </div>
  );
};

export default AssignedBadge;
