import LeftNav from "./LeftNav";

interface CommonContainerProps {
  children: JSX.Element;
  topRightComponent?: JSX.Element;
}

const CommonContainer = ({ children }: CommonContainerProps) => {
  return (
    <div className="box-border flex h-screen min-w-custom-minWidth">
      <LeftNav />
      <main className="box-border h-full flex-grow px-[3.125rem] pb-[3.125rem] pt-[calc(60px+5.5vh)]">
        {children}
      </main>
    </div>
  );
};

export default CommonContainer;
