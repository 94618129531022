import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import useShopSettingControl from "../../lib/hooks/UseShopSettingControl";
import { shopInfoAtom } from "../../lib/recoils/atoms/shopInfoAtom";
import Button from "../button/Button";
import PostCode from "../modal/PostCode";

const ShopSettingAddressInput = () => {
  // Hooks
  const { onChangeShopSetting, handlePostCode, shopSettingState } =
    useShopSettingControl();

  // Query
  const { data: shopInfo } = useQuery({
    queryKey: ["shopInfo"],
    queryFn: shopsApi.getShopInfo,
  });

  // Recoil
  // const shopInfo = useRecoilValue(shopInfoAtom);

  // States
  const [showPostCode, setShowPostCode] = useState(false);

  return (
    <div className="flex w-full flex-col">
      <label
        htmlFor="shopSettingAddress"
        className="mb-[1.85vh] text-[1rem] font-bold leading-5"
      >
        주소
      </label>
      <div className="flex w-full items-center justify-between">
        <input
          disabled
          id="shopSettingAddress"
          className="custom-border-container h-[4vh] w-[14.79vw] p-2"
          type="text"
          placeholder="주소 입력"
          value={
            shopSettingState.address === ""
              ? shopInfo![0].address
              : shopSettingState.address
          }
        />
        <Button
          title="주소찾기"
          style={{ height: "4vh", width: "6.25vw" }}
          onClick={() => setShowPostCode(true)}
        />
      </div>

      <label
        htmlFor="shopSettingAddressDetail"
        className="mb-[1.85vh] mt-[2.22vh] text-[1rem] font-bold leading-5"
      >
        세부주소
      </label>
      <input
        id="shopSettingAddressDetail"
        className="custom-border-container h-[4vh] w-full p-2"
        type="text"
        placeholder="세부주소 입력"
        onChange={onChangeShopSetting}
        name="addressDetail"
        value={
          shopSettingState.addressDetail === ""
            ? shopInfo![0].address2
            : shopSettingState.addressDetail
        }
      />

      {showPostCode && (
        <PostCode
          handleOnComplete={handlePostCode}
          onClose={() => setShowPostCode(false)}
        />
      )}
    </div>
  );
};

export default ShopSettingAddressInput;
