import { ReactComponent as ChevronDoubleLeftIcon } from "../../assets/svgs/DoubleChevronLeft.svg";
import { ReactComponent as ChevronDoubleRightIcon } from "../../assets/svgs/DoubleChevronRight.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/svgs/ChevronLeft.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/svgs/ChevronRight.svg";
import { Dispatch, SetStateAction, useEffect } from "react";
import HQShopsPageComponentRow from "./HQShopsPageComponentRow";
import { HqShop } from "../../types/HqTypes";
import Pagination from "../pagination/Pagination";

interface PageComponentProps {
  title: string;
  sort: "desc" | "asc";
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  data:
    | {
        data: HqShop[];
        message: string;
        totalCount: number;
      }
    | undefined;
}

const HQShopsPageComponent = ({
  title,
  currentPage,
  setCurrentPage,

  data,
}: PageComponentProps) => {
  const totalPage = data ? Math.ceil(data.totalCount / 10) : 1;

  const onClickPage = (page: number) => {
    if (page <= 0) return null;
    else if (page > totalPage) return null;
    setCurrentPage(page);
  };

  useEffect(() => {
    console.log(data?.data);
  }, [data]);

  return (
    <div className="box-border flex h-full w-full flex-col">
      <h3 className="mb-[0.4vh] text-[0.875rem] leading-[1.25rem] text-custom-black">
        {title}
      </h3>
      <section className="mb-[2.7vh] box-border h-[57vh] w-full overflow-auto rounded-[8px] border border-solid border-custom-mediumGray px-[1.5vw] py-[3.1vh]">
        {data && data.data && data.data.length > 0 ? (
          <ul className="flex flex-col gap-y-[2.7vh]">
            {data.data.map((shop, index) => {
              return (
                <HQShopsPageComponentRow
                  key={index + shop.shopId + Math.random()}
                  data={shop}
                  index={index + 1}
                />
              );
            })}
          </ul>
        ) : (
          <div className="flex h-full w-full grow items-center justify-center">
            등록된 고객 데이터가 없습니다.
          </div>
        )}
      </section>
      {totalPage > 0 && (
        <Pagination
          totalPage={totalPage}
          currentPage={currentPage}
          onClickPage={onClickPage}
        />
      )}
      {/* <nav className="flex grow items-center gap-x-10 self-center">
        <ChevronDoubleLeftIcon
          className="cursor-pointer"
          onClick={() => {
            setCurrentPage(1);
          }}
        />
        <ChevronLeftIcon
          className="cursor-pointer"
          onClick={() => {
            const prevPage = currentPage - 1;
            if (prevPage === 0) return;
            setCurrentPage(prevPage);
          }}
        />

        <ul className="flex gap-x-10">
          {Array(totalPage)
            .fill("")
            .map((item, index) => {
              return (
                <li
                  key={index + Math.random()}
                  onClick={() => onClickPage(index + 1)}
                  className={`border-soild box-border flex h-[24px] w-[24px] shrink-0 cursor-pointer items-center justify-center rounded-[8px] border border-custom-mainYellow text-[1rem] font-medium leading-[1.25rem] text-custom-mainYellow ${
                    currentPage === index + 1
                      ? "bg-custom-lightYellow"
                      : "bg-custom-white"
                  } `}
                >
                  {index + 1}
                </li>
              );
            })}
        </ul>

        <ChevronRightIcon
          className="cursor-pointer"
          onClick={() => {
            const nextPage = currentPage + 1;
            if (nextPage <= totalPage) {
              setCurrentPage((prev) => prev + 1);
            } else {
              return;
            }
          }}
        />
        <ChevronDoubleRightIcon
          className="cursor-pointer"
          onClick={() => {
            setCurrentPage(totalPage);
          }}
        />
      </nav> */}
    </div>
  );
};

export default HQShopsPageComponent;
