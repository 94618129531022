import { ChangeEvent, MouseEvent, useState } from "react";
import Button from "../components/button/Button";
import FilterButton from "../components/button/FilterButton";
import ContentTitle from "../components/common/ContentTitle";
import MainContentContainer from "../components/common/MainContentContainer";
import SearchInput from "../components/input/SearchInput";
import ManageDriverModal from "../components/ShopCustomerAndDriverPage/ManageDriverModal";
import DriverPageComponent from "../components/ShopCustomerAndDriverPage/DriverPageComponent";
import { useModal } from "../lib/hooks/UseModal";
import useShopDriverQuery from "../lib/queries/UseShopDriverQuery";
import { debounce } from "lodash";

const ShopDriverPage = () => {
  // Hooks
  const { openModal } = useModal();

  // States
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [checkDeliveryWaiting, setCheckDeliveryWaiting] = useState("");
  // 전체 기사 페이지는 "" 로 해야 delivery_waiting 여부 상관없이 불러올 수 있다.
  // 배송 가능 기사 확인 버튼 만들 때 필요할 수 있다.

  // Query
  const { data, refetch } = useShopDriverQuery(
    sortOrder,
    currentPage,
    searchText,
    checkDeliveryWaiting,
  );

  // Functions
  const onClickSortOrder = () => {
    sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");
  };

  const onClickAddButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openModal({
      content: <ManageDriverModal refetch={refetch} type="register" />,
    });
  };

  const debounceOnChange = debounce((value: string) => {
    setSearchText(value);
  }, 800);

  const onChangeSearhInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    debounceOnChange(value);
  };

  return (
    <div className="relative flex w-full flex-col">
      <ContentTitle hasMb>기사</ContentTitle>

      <MainContentContainer>
        <div className="bg-akber-300 absolute -top-[1.6vh] right-0 flex -translate-y-full flex-col items-end gap-y-[6px] ">
          <Button
            title="기사추가⨁"
            buttoncolor="custom-mainYellow"
            style={{ width: "7.5rem", height: "3.75rem" }}
            onClick={onClickAddButton}
          />
          <span className="text-[0.75rem] font-normal leading-[rem] text-custom-gray">
            신규 기사를 추가할 때 사용 합니다.
          </span>
        </div>
        <div className="flex w-full items-center justify-between">
          <h3 className="text-[0.875rem] leading-[1.25rem] text-custom-black">
            기사를 등록 및 관리할 수 있습니다. 클릭 시 변경할 수 있습니다.
          </h3>
          <div className="flex items-center gap-x-[52px]">
            <div className="flex items-center gap-x-[42px]">
              <FilterButton
                whereTo="customerAndDriver"
                title="가입일 오름차순"
                isSelected={sortOrder === "asc"}
                onClick={onClickSortOrder}
              />
              <FilterButton
                whereTo="customerAndDriver"
                title="가입일 내림차순"
                isSelected={sortOrder === "desc"}
                onClick={onClickSortOrder}
              />
            </div>
            <div>
              <SearchInput
                height={"4vh"}
                placeholder={"기사명, 전화번호"}
                onChange={onChangeSearhInput}
              />
            </div>
          </div>
        </div>

        {data && (
          <DriverPageComponent
            title="배송 주소 확인"
            sort={sortOrder}
            data={data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            refetch={refetch}
          />
        )}
      </MainContentContainer>
    </div>
  );
};

export default ShopDriverPage;
