import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import SignInOrResetPassword from "./pages/SignInOrResetPasswordPage";
import SignupPage from "./pages/SignupPage";
import Header from "./components/common/Header";
import CommonContainer from "./components/common/CommonContainer";
import InAndOutStatusPage from "./pages/InAndOutStatusPage";
import MenuPage from "./pages/MenuPage";
import ShopCustomerPage from "./pages/ShopCustomerPage";
import ShopDriverPage from "./pages/ShopDriverPage";
import RequestsAndRecallsPage from "./pages/RequestsAndRecallsPage";
import ShopOrderPage from "./pages/ShopOrderPage";
import HQInAndOutStatusPage from "./pages/HQInAndOutStatusPage";
import HQShopTransactionPage from "./pages/HQShopTransactoinPage";
import HQShopsPage from "./pages/HQShopsPage";
import HQContainersPage from "./pages/HQContainersPage";
import HQDriversPage from "./pages/HQDriversPage";
import ShopSettingPage from "./pages/ShopSettingPage";
import useAxiosInterceptor from "./lib/hooks/UseAxiosInterceptor";
import { useRecoilValue } from "recoil";
import { loginInfoAtom } from "./lib/recoils/atoms/LoginInfoAtom";
import ProtectedRoute from "./components/common/ProtectedRoute";

const App = () => {
  const isSetupComplete = useAxiosInterceptor();

  const location = useLocation();
  const hideHeaderRoutes = [
    "/signin",
    "/reset",
    "/reset-password",
    "/terms/policy",
    "/terms/privacy",
  ];
  const loginInfo = useRecoilValue(loginInfoAtom);

  if (!isSetupComplete) {
    return <div>Setting...</div>;
  }

  return (
    <>
      {!hideHeaderRoutes.includes(location.pathname) && <Header />}
      <Routes>
        <Route
          path="/terms/policy"
          element={
            <iframe
              src="/terms/policy_1.html"
              title="약관"
              style={{ width: "100%", height: "100vh" }}
            />
          }
        />
        <Route
          path="/terms/privacy"
          element={
            <iframe
              src="/terms/privacy_1.html"
              title="약관"
              style={{ width: "100%", height: "100vh" }}
            />
          }
        />

        <Route
          path="/"
          element={
            loginInfo ? <Navigate to={"/or"} /> : <Navigate to={"/signin"} />
          }
        />
        <Route
          path="/signin"
          element={<SignInOrResetPassword contentType="signIn" />}
        />

        <Route
          path="/io"
          element={
            <ProtectedRoute>
              <CommonContainer children={<InAndOutStatusPage />} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/hq/io"
          element={
            <ProtectedRoute>
              <CommonContainer children={<HQInAndOutStatusPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hq/io/:shopId"
          element={
            <ProtectedRoute>
              <CommonContainer children={<HQShopTransactionPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hq/shops"
          element={
            <ProtectedRoute>
              <CommonContainer children={<HQShopsPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hq/drivers"
          element={
            <ProtectedRoute>
              <CommonContainer children={<HQDriversPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hq/containers"
          element={
            <ProtectedRoute>
              <CommonContainer children={<HQContainersPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/or"
          element={
            <ProtectedRoute>
              <CommonContainer children={<ShopOrderPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rq"
          element={
            <ProtectedRoute>
              <CommonContainer children={<RequestsAndRecallsPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sc"
          element={
            <ProtectedRoute>
              <CommonContainer children={<ShopCustomerPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sd"
          element={
            <ProtectedRoute>
              <CommonContainer children={<ShopDriverPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sm"
          element={
            <ProtectedRoute>
              <CommonContainer children={<MenuPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shop/setting"
          element={
            <ProtectedRoute>
              <CommonContainer children={<ShopSettingPage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reset"
          element={<SignInOrResetPassword contentType="verification" />}
        />
        <Route
          path="/reset-password"
          element={<SignInOrResetPassword contentType="resetPassword" />}
        />
        <Route path="/signup" element={<SignupPage />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default App;
