import { atom } from "recoil";

interface ModalStateAtomProps {
  isOpen: boolean;
  content: JSX.Element;
}

export const modalStateAtom = atom<ModalStateAtomProps>({
  key: "modalStateAtom",
  default: {
    isOpen: false,
    content: <></>,
  },
});
