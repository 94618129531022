import React, { ReactNode } from "react";

export type ColorType =
  | "custom-mainYellow"
  | "custom-lightYellow"
  | "custom-mainBlue"
  | "custom-lightBlue"
  | "custom-mainRed"
  | "custom-lightRed"
  | "custom-lightRed"
  | "custom-gray"
  | "custom-black"
  | "custom-mediumGray"
  | "custom-mainGreen"
  | "custom-lightGreen";

const Badge = ({
  children,
  badgeColor,
  textColor,
}: {
  children: ReactNode;
  badgeColor: ColorType;
  textColor: ColorType;
}) => {
  const colorClasses = {
    "custom-mainYellow": "bg-custom-mainYellow",
    "custom-lightYellow": "bg-custom-lightYellow",
    "custom-mainBlue": "bg-custom-mainBlue",
    "custom-lightBlue": "bg-custom-lightBlue",
    "custom-mainRed": "bg-custom-mainRed",
    "custom-lightRed": "bg-custom-lightRed",
    "custom-gray": "bg-custom-gray",
    "custom-black": "bg-custom-black",
    "custom-mediumGray": "bg-custom-mediumGray",
    "custom-mainGreen": "bg-custom-mainGreen",
    "custom-lightGreen": "bg-custom-lightGreen",
  };
  const textColorClasses = {
    "custom-mainYellow": "text-custom-mainYellow",
    "custom-lightYellow": "text-custom-lightYellow",
    "custom-mainBlue": "text-custom-mainBlue",
    "custom-lightBlue": "text-custom-lightBlue",
    "custom-mainRed": "text-custom-mainRed",
    "custom-lightRed": "bg-custom-lightRed",
    "custom-gray": "text-custom-gray",
    "custom-black": "text-custom-black",
    "custom-mediumGray": "text-custom-mediumGray",
    "custom-mainGreen": "text-custom-mainGreen",
    "custom-lightGreen": "text-custom-lightGreen",
  };

  return (
    <div
      className={`box-border flex items-center justify-center rounded-[8px] px-[0.41vw] py-[0.37vh] text-[0.75rem]
      leading-[16px] ${colorClasses[badgeColor]}`}
    >
      <span className={textColorClasses[textColor]}>{children}</span>
    </div>
  );
};

export default Badge;
