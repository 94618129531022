import Separator from "../common/Separator";
import { ReactComponent as CircleChecked } from "../../assets/svgs/CircleChecked.svg";
import { ReactComponent as Circle } from "../../assets/svgs/Circle.svg";
import { ReactComponent as Cancel } from "../../assets/svgs/Cancel.svg";
import Span from "../span/Span";
import Badge from "../common/Badge";
import useShopOrderControl from "../../lib/hooks/UseShopOrderControl";
import AssignedBadge from "./AssignedBadge";
import { useModal } from "../../lib/hooks/UseModal";
import SpecialNoteAndCheckOrderAddressModal from "./SpecialNoteAndCheckOrderAddressModal";
import shopsApi from "../../api/Shops";
import AlertModal from "../modal/AlertModal";
import { useRecoilValue } from "recoil";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import { KOREAN_CHILDTYPE, KOREAN_CONTAINER } from "../../lib/Consts";
import { ChildType } from "../../lib/recoils/atoms/ShopMenuPage/ShopMenuFormAtom";
import ContainerType from "../../types/ContainerTypes";
import { FlatOrderList, TransformedOrder } from "../../types/OrderTypes";
import formatKoreanWon from "../../lib/utils/FormatKoreanWon";
import dayjs from "dayjs";
import { shopOrderAtom } from "../../lib/recoils/atoms/ShopOrderPage/ShopOrderAtom";
import { useState } from "react";

const CheckBox = ({ checked }: { checked: boolean }) => {
  if (checked) {
    return <CircleChecked style={{ width: "1.3vw", height: "1.3vw" }} />;
  } else {
    return <Circle style={{ width: "1.3vw", height: "1.3vw" }} />;
  }
};

const OrderRow = ({
  data,
  disabled,
  closedTime,
}: {
  // data: TransformedOrder;
  data: FlatOrderList;
  disabled: boolean;
  closedTime: string | null;
}) => {
  // Hooks
  const {
    shopOrderStates,
    onClickSpecialNote,
    onClickCancelManualOrder,
    onClickCancelOrder,
    onClickCheckBox,
    handleSelectedIds,
  } = useShopOrderControl();
  const { openModal } = useModal();

  // Recoil
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);
  const { selectedDate } = useRecoilValue(shopOrderAtom);

  // States
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  // Functions
  const onClickOpenAddressAndDescription = () => {
    openModal({
      content: (
        <SpecialNoteAndCheckOrderAddressModal
          data={data}
          isSpecialNote={false}
        />
      ),
    });
  };

  const onClickCancelAssignedDriver = async (
    orderId: number,
    driverId: number,
  ) => {
    const status = await shopsApi.cancelAssignedDriver(orderId, driverId);
    if (status !== 400) {
      refetchFunctions &&
        refetchFunctions.refetchShop &&
        refetchFunctions.refetchShop();
      openModal({
        content: (
          <AlertModal alertType="check" message="기사 배정을 취소했습니다." />
        ),
      });
    } else {
      alert("배정을 취소할 수 없습니다.");
    }
  };

  return (
    <div className="flex w-full flex-col">
      <section className="flex h-fit w-full">
        <div className="flex h-fit w-full flex-col">
          {!data && <div>컨텐츠 없음</div>}
          {data && (
            <section className="grid w-full grid-cols-custom-orderContainer items-start py-4">
              <div className="flex h-full w-full items-center justify-between">
                <div className="w-[1.67vw]">
                  {data.driverId ? (
                    <AssignedBadge
                      onClick={() => {
                        if (disabled) return;
                        if (dayjs(selectedDate).day() !== dayjs().day()) return;
                        onClickCancelAssignedDriver(
                          data.orderId,
                          data.driverId!,
                        );
                      }}
                    />
                  ) : (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (disabled && data.order_type !== "manualOrder")
                          return;
                        if (dayjs(selectedDate).day() !== dayjs().day()) return;
                        handleSelectedIds(data.orderIds);
                      }}
                    >
                      <CheckBox
                        // checked={data.orderIds.every((orderId) =>
                        //   shopOrderStates.selectedOrder.includes(orderId),
                        // )}
                        checked={shopOrderStates.selectedOrder.includes(
                          data.orderId,
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className="w-[2.1vw]">
                  <Span type="14pxBoldBlack">{data.userName}</Span>
                </div>
                <div
                  className="w-[50%] cursor-pointer"
                  onClick={onClickOpenAddressAndDescription}
                >
                  <Span type="12pxNormalBlack">{data.address}</Span>
                </div>

                {/* menus가 존재하면 용기 버튼, 메뉴 없으면 수거대상 */}
                {data.order_type === "manualOrder" ? (
                  <Badge
                    badgeColor={`${
                      data.containerType === "glass"
                        ? "custom-lightYellow"
                        : data.containerType === "disposable"
                        ? "custom-lightGreen"
                        : "custom-lightBlue"
                    }`}
                    textColor={`${
                      data.containerType === "glass"
                        ? "custom-mainYellow"
                        : data.containerType === "disposable"
                        ? "custom-mainGreen"
                        : "custom-mainBlue"
                    }`}
                  >
                    {KOREAN_CONTAINER[data.containerType as ContainerType]}
                  </Badge>
                ) : data.menus.length > 0 ? (
                  <div>
                    <Badge
                      badgeColor={`${
                        data.containerType === "glass"
                          ? "custom-lightYellow"
                          : data.containerType === "disposable"
                          ? "custom-lightGreen"
                          : "custom-lightBlue"
                      }`}
                      textColor={`${
                        data.containerType === "glass"
                          ? "custom-mainYellow"
                          : data.containerType === "disposable"
                          ? "custom-mainGreen"
                          : "custom-mainBlue"
                      }`}
                    >
                      {KOREAN_CONTAINER[data.containerType as ContainerType]}
                    </Badge>
                  </div>
                ) : (
                  <div>
                    <Badge
                      badgeColor={`custom-lightBlue`}
                      textColor={`custom-mainBlue`}
                    >
                      수거대상
                    </Badge>
                  </div>
                )}
              </div>
              <div className="flex h-full  items-center justify-center">
                <Span type="12pxBoldBlack">
                  {formatKoreanWon(Math.abs(Number(data.baseAmount))) ?? 0}원
                </Span>
              </div>
              <div className="flex h-full  items-center justify-center">
                {selectedDate ? (
                  dayjs(selectedDate).format("YYYY-MM-DD") ===
                  dayjs().format("YYYY-MM-DD") ? (
                    <Span type="12pxBoldBlack">
                      {formatKoreanWon(data.totalDeposit) ?? 0}원
                    </Span>
                  ) : null
                ) : null}
              </div>
              <div
                className="flex h-full  items-center justify-center"
                onClick={() => onClickSpecialNote(data)}
              >
                <div className="flex h-full w-full cursor-pointer items-center justify-center rounded-lg border text-xs ">
                  {data.remarks ? data.remarks : ""}
                </div>
              </div>

              <div className="flex h-full w-full items-center justify-center">
                <div className="flex h-full w-full items-center justify-center overflow-hidden">
                  {data.driverId && (
                    <img
                      src={require("../../assets/images/TempDriver.png")}
                      alt="배정된 기사"
                      style={{ width: "4vh", height: "4vh" }}
                    />
                  )}
                </div>
              </div>

              {/* 상품 주문 내역 Grid */}
              {data.order_type === "manualOrder" ? (
                <div className="col-start-6 col-end-8 flex h-full w-full items-center justify-between pl-[0.94vw]">
                  <Span type="12pxBoldBlue">
                    배송이 수동으로 추가 되었습니다.
                  </Span>

                  {disabled ? null : (
                    <Cancel
                      className="cursor-pointer"
                      onClick={() => {
                        onClickCancelManualOrder(data);
                      }}
                    />
                  )}
                </div>
              ) : data.menus.length > 0 ? (
                <div className="flex w-full flex-col pl-[0.94vw]">
                  <div className="grid w-full grid-cols-4 gap-y-[2.78vh]">
                    {data.menus.map((eachMenu, index: number) => {
                      return (
                        <div
                          key={index + Math.random()}
                          className="flex shrink-0 flex-col items-center"
                        >
                          {/* 현재 isRegular를 받아올 수 있는 방법이 없어서 추후 사용 가능하게 주석처리 */}
                          {/* {eachMenu.isRegular ? (
                            <div className="w-3/4">
                              <Badge
                                badgeColor="custom-lightYellow"
                                textColor="custom-mainYellow"
                              >
                                정기
                              </Badge>
                            </div>
                          ) : (
                            <div className="w-3/4">
                              <Badge
                                badgeColor="custom-lightBlue"
                                textColor="custom-mainBlue"
                              >
                                일반
                              </Badge>
                            </div>
                          )} */}
                          <Span type="12pxBoldBlack" alignSelf="center">
                            {`${
                              //@ts-ignore
                              KOREAN_CHILDTYPE[eachMenu.child_type as ChildType]
                            }
                          `}
                          </Span>
                          <Span type="12pxBoldBlack" alignSelf="center">
                            {`${eachMenu.menuName} [${eachMenu.amount}]g`}
                          </Span>
                          <Span type="12pxBoldBlack" alignSelf="center">
                            {eachMenu.quantity}개
                          </Span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col pl-[0.94vw]">
                  <div className="grid w-full grid-cols-4 gap-y-[2.78vh]">
                    {/* {data.collections.map((item, index) => (
                      <div
                        key={index + Math.random()}
                        className="flex flex-col"
                      >
                        <Span
                          textColor="custom-mainRed"
                          type="12pxBoldBlack"
                          alignSelf="center"
                        >
                          {KOREAN_CONTAINER[item.type as ContainerType]}
                        </Span>
                        <Span
                          textColor="custom-mainRed"
                          type="12pxBoldBlack"
                          alignSelf="center"
                        >
                          {item.quantity ?? 0}개
                        </Span>
                      </div>
                    ))} */}
                  </div>
                </div>
              )}

              {data.order_type === "order" ? (
                <div className="flex h-full items-center justify-end">
                  <Cancel
                    className="cursor-pointer"
                    onClick={() => {
                      onClickCancelOrder(data);
                    }}
                  />
                </div>
              ) : null}
            </section>
          )}

          {/* menus가 존재하면 Separator 출력으로 collections과 분리 */}
          {/* {data.menus.length > 0 &&
            data.collections &&
            data.collections.length > 0 && (
              <div className="my-[0.93vh] w-full">
                <Separator />
              </div>
            )} */}

          {/* 수거 대상 Grid menus가 있을때 두번째 ROW로 */}
          {/*data.menus.length > 0 &&
            data.collections &&
            data.collections.length > 0 && (
              <section className="mb-[0.93vh] grid w-full grid-cols-custom-orderContainer items-start">
                <div className="flex h-full w-full items-center justify-end">
                  <Badge
                    badgeColor="custom-lightBlue"
                    textColor="custom-mainBlue"
                  >
                    수거대상
                  </Badge>
                </div>

                
                <div className="col-start-6 col-end-7 flex flex-col pl-[0.94vw]">
                  <div className="grid w-full grid-cols-4 gap-y-[2.78vh]">
                    {data.collections.map((item, index) => (
                      <div
                        key={index + Math.random()}
                        className="flex flex-col"
                      >
                        <Span
                          textColor="custom-mainRed"
                          type="12pxBoldBlack"
                          alignSelf="center"
                        >
                          {KOREAN_CONTAINER[item.type as ContainerType]}
                        </Span>
                        <Span
                          textColor="custom-mainRed"
                          type="12pxBoldBlack"
                          alignSelf="center"
                        >
                          {item.quantity ?? 0}개
                        </Span>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
                    )*/}
        </div>
      </section>
      <section>
        <Separator />
      </section>
    </div>
  );
};

export default OrderRow;
