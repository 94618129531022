import { useQuery } from "@tanstack/react-query";
import shopsApi from "../../api/Shops";

const useShopCustomerQuery = (
  sort: "desc" | "asc",
  pageNum?: number,
  searchText?: string,
) => {
  const pageNumber = pageNum ? pageNum : 1;
  const { data, isLoading, isFetched, isFetching, refetch } = useQuery({
    queryKey: ["shopCustomerList", sort, pageNum, searchText],
    queryFn: async () => shopsApi.getCustomerList(pageNumber, sort, searchText),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  return {
    data,
    isFetched,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useShopCustomerQuery;
