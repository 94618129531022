import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import { shopOrderAtom } from "../recoils/atoms/ShopOrderPage/ShopOrderAtom";
import { refetchFunctionsAtom } from "../recoils/RefetchFunctionsAtom";
import dayjs from "dayjs";
import transformData from "../utils/ProcessAllOrderData";
import { FlatOrderList, TransformedOrder } from "../../types/OrderTypes";

export interface ShopOrderProps {
  userId: number;
  orderId: number;
  driverId: null | number;
  driverName: string;
  userName: string;
  address: string;
  mobile: string;
  containerType: string;
  description: string;
  totalDeposit: string;
  orderType: string;
  quantity: string | null;
  collectQuantity: string | null;
  remarks: string;
}

const useShopOrderQuery = (perPage: number, pageNum: number) => {
  // Recoil
  const { selectedDate, sortType, customerAndProductSearchTerm } =
    useRecoilValue(shopOrderAtom);
  const [refetchFunctions, setRefetchFunctions] =
    useRecoilState(refetchFunctionsAtom);

  // Vars
  const queryDate = selectedDate
    ? dayjs(selectedDate).format("YYYY-MM-DD")
    : dayjs(new Date()).format("YYYY-MM-DD");

  // States
  const [allModifiedOrderData, setAllModifiedOrderData] = useState<
    TransformedOrder[]
  >([]);
  const [flatData, setFlatData] = useState<FlatOrderList[]>([]);

  // Query
  const {
    data,
    hasNextPage,
    isFetching,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [
      "shopOrderList",
      queryDate,
      perPage,
      pageNum,
      sortType,
      customerAndProductSearchTerm,
    ],
    queryFn: async ({ pageParam }) =>
      await shopsApi.getOrderList(
        queryDate,
        perPage,
        pageParam ?? 1,
        sortType,
        customerAndProductSearchTerm,
      ),
    getNextPageParam: (lastPage, allPage) => {
      const totalCount: number = lastPage.totalCount;
      const totalPage = Math.ceil(totalCount / perPage);
      const nextPage = allPage.length + 1;
      return nextPage <= totalPage ? nextPage : undefined;
    },
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (!data) return;
    const flatedData = data.pages.flatMap((page) => page.data) || [];

    const newData = transformData(flatedData);

    // 기사 배정된 것과 안된 것 정렬
    // 각 그룹끼리는 이름별로 정렬
    const sortedData = newData.sort((a, b) => {
      if (a.driverId === null && b.driverId !== null) return -1;
      if (a.driverId !== null && b.driverId === null) return 1;

      return a.userName.localeCompare(b.userName);
    });

    const sortedFlatData = [...flatedData].sort((a, b) => {
      if (a.driver === null && b.driverId !== null) return -1;
      if (a.driverId !== null && b.driverId === null) return 1;

      return a.userName.localeCompare(b.userName);
    });

    console.log("전체 묶인 데이터", sortedData);
    setAllModifiedOrderData(sortedData);
    console.log("실제 사용할 데이터", sortedFlatData);
    setFlatData(sortedFlatData);
  }, [data]);

  useEffect(() => {
    setRefetchFunctions((prev) => ({ ...prev, refetchShop: refetch }));
  }, [refetch]);

  return {
    allModifiedOrderData,
    data,
    flatData,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
    refetchFunctions,
  };
};

export default useShopOrderQuery;
