import { Dispatch, SetStateAction } from "react";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerStyle.css";
import { HQInAndOutFilter } from "../../pages/HQInAndOutStatusPage";

interface DatePickerProps {
  isStart?: boolean;
  placeholder?: string;
  inAndOutFilter: HQInAndOutFilter;
  setInAndOutFilter: Dispatch<SetStateAction<HQInAndOutFilter>>;
}

const HQDatePickerComponent = ({
  placeholder,
  inAndOutFilter,
  setInAndOutFilter,
  isStart,
}: DatePickerProps) => {
  const onChangeDate = (date: Date) => {
    if (!date) {
      return;
    }
    if (isStart) {
      setInAndOutFilter((prev) => ({ ...prev, startDate: date }));
    } else {
      setInAndOutFilter((prev) => ({ ...prev, endDate: date }));
    }
  };

  return (
    <DatePicker
      selected={isStart ? inAndOutFilter.startDate : inAndOutFilter.endDate}
      dateFormat={"yyyy-MM-dd"}
      className="input-datepicker"
      locale={ko}
      closeOnScroll
      placeholderText={placeholder}
      onChange={onChangeDate}
    />
  );
};

export default HQDatePickerComponent;
