import { MouseEvent } from "react";
import { ReactComponent as CheckIcon } from "../../assets/svgs/Check.svg";
import { ReactComponent as DeniedIcon } from "../../assets/svgs/Denied.svg";
import { ReactComponent as WarningIcon } from "../../assets/svgs/Warning.svg";
import { useModal } from "../../lib/hooks/UseModal";
import Button from "../button/Button";

interface AlertModalProps {
  alertType: "check" | "warning" | "denied";
  message: string;
  onClickWarning?: () => void;
  onClickCheck?: () => void;
  hasCancelOnDenied?: boolean;
}

const AlertModal = ({
  alertType,
  message,
  onClickWarning,
  onClickCheck,
  hasCancelOnDenied,
}: AlertModalProps) => {
  // Hooks
  const { closeModal } = useModal();
  // States
  // const [localAlertType, setLocalAlertType] = useState(alertType);

  // Functions
  const onClickOk = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    closeModal();
    onClickCheck && onClickCheck();
  };

  const Icon =
    alertType === "check" ? (
      <CheckIcon />
    ) : alertType === "warning" ? (
      <WarningIcon />
    ) : alertType === "denied" ? (
      <DeniedIcon />
    ) : (
      <CheckIcon />
    );

  return (
    <div className="z-20 flex h-[300px] w-[300px] flex-col items-center justify-between rounded-[8px] bg-white px-[32px] py-[32px]">
      <div>{Icon}</div>
      <span className="text-[1.25rem] font-medium leading-[1.5rem]">
        {message}
      </span>
      <div className="flex w-full items-center justify-between">
        {alertType !== "warning" && (
          <div className="flex w-full items-center justify-between gap-x-3">
            {hasCancelOnDenied ? (
              <Button
                title="취소"
                buttoncolor="custom-gray"
                width={103}
                height={52}
                onClick={closeModal}
              />
            ) : null}
            <Button
              title="확인"
              fullwidth
              height={52}
              buttoncolor={
                alertType === "check" ? "custom-mainYellow" : "custom-mainRed"
              }
              onClick={onClickOk}
            />
          </div>
        )}
        {alertType === "warning" && (
          <>
            <Button
              title="취소"
              buttoncolor="custom-gray"
              width={103}
              height={52}
              onClick={closeModal}
            />
            <Button
              title="확인"
              buttoncolor="custom-mainRed"
              width={103}
              height={52}
              onClick={onClickWarning}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AlertModal;
