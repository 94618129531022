import { useRef } from "react";
import useShopSettingControl from "../../lib/hooks/UseShopSettingControl";
import Span from "../span/Span";
import "./ShopSettingFileInput.css";

const ShopSettingFileInput = () => {
  // Hooks
  const { onChangeLogoUpload, shopInfo, shopSettingState } =
    useShopSettingControl();

  const logoUrl = shopSettingState.logoUrl ?? "";

  const inputRef = useRef<HTMLInputElement>(null);

  const getLocalLogoSrc = () => {
    const logo = shopSettingState.logo;
    if (logo instanceof File) {
      return URL.createObjectURL(logo);
    } else {
      return "";
    }
  };

  const localLogoUrl = getLocalLogoSrc();

  console.log(localLogoUrl);

  return (
    <div className="flex w-full flex-col">
      <label
        className="mb-[1.85vh] text-[1rem] font-bold leading-5"
        htmlFor="shopSettingInput"
      >
        로고
      </label>

      {logoUrl === "" && localLogoUrl === "" ? null : (
        <div className="flex h-36  w-full items-center gap-4">
          <img
            className="h-full w-full object-contain"
            src={localLogoUrl ? localLogoUrl : logoUrl ?? ""}
            alt="logo"
          />
          <div
            className="cursor flex cursor-pointer  whitespace-nowrap rounded-xl border p-2"
            onClick={() => {
              if (inputRef && inputRef.current) {
                inputRef.current.click();
              }
            }}
          >
            <Span children="로고 변경" type="16pxBoldBlack" />
          </div>
        </div>
      )}

      <div className={logoUrl !== "" ? "hidden" : ""}>
        <div className="flex items-center justify-between">
          <input
            name="logo"
            id="shopSettingInput"
            placeholder="파일을 선택해 주세요."
            className="custom-border-container pl-3 pt-2"
            style={{ height: "4vh", width: "14.8vw" }}
            type="file"
            accept=".png"
            onChange={onChangeLogoUpload}
            ref={inputRef}
          />
          <label
            htmlFor="shopSettingInput"
            className="flex h-[4vh] w-[6.25vw] items-center justify-center rounded-[8px] bg-custom-mainYellow text-base font-bold text-white"
          >
            파일 찾기
          </label>
        </div>
        <div className="mt-[1rem] flex w-full flex-col items-end">
          <Span type="14pxNormalMainYellow">
            128X128 PNG 파일을 넣어 주세요. 이는 옵션 사항 입니다.
          </Span>
          <Span type="14pxNormalMainYellow">
            이용자 앱에서 보여집니다. 없을 경우, ‘담음’ 로고가 보여집니다.
          </Span>
        </div>
      </div>
    </div>
  );
};

export default ShopSettingFileInput;
