import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Button from "../button/Button";
import Separator from "../common/Separator";
import { ReactComponent as CancelIcon } from "../../assets/svgs/Cancel.svg";
import { ShopMenu } from "../../types/MenuType";
import { useRecoilValue } from "recoil";
import {
  ChildType,
  shopMemuFormAtom,
} from "../../lib/recoils/atoms/ShopMenuPage/ShopMenuFormAtom";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import AlertModal from "../modal/AlertModal";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import { KOREAN_CHILDTYPE } from "../../lib/Consts";

const MenuList = ({
  setShowAddMenu,
  filteredMenu,
}: {
  setShowAddMenu: Dispatch<SetStateAction<boolean>>;
  filteredMenu: ShopMenu[];
}) => {
  // Consts
  const KOREAN_WEEKDAY = {
    mon: "월요일",
    tue: "화요일",
    wed: "수요일",
    thu: "목요일",
    fri: "금요일",
  };

  // Hooks
  const { openModal, changeModal } = useModal();

  // Recoil
  const menuDetailFormData = useRecoilValue(shopMemuFormAtom);
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  // States
  const [displayMenu, setDisplayMenu] = useState<ShopMenu[]>(
    filteredMenu.filter(
      (menu) =>
        menu.day === menuDetailFormData.week &&
        menu.childType === menuDetailFormData.childType,
    ),
  );

  // Functions
  const onClickDeleteMenu = async (menuId: number) => {
    openModal({
      content: (
        <AlertModal
          message="정말 삭제하시겠습니까?"
          alertType="warning"
          onClickWarning={async () => {
            const status = await shopsApi.deleteMenu(menuId);
            refetchFunctions &&
              refetchFunctions.refetchShopMenu &&
              refetchFunctions.refetchShopMenu();

            if (status !== 400) {
              refetchFunctions &&
                refetchFunctions.refetchShopMenu &&
                refetchFunctions.refetchShopMenu();
              changeModal({
                content: (
                  <AlertModal
                    alertType="check"
                    message="메뉴가 삭제되었습니다."
                  />
                ),
              });
            } else {
              refetchFunctions &&
                refetchFunctions.refetchShopMenu &&
                refetchFunctions.refetchShopMenu();
              changeModal({
                content: <AlertModal alertType="denied" message="에러 발생." />,
              });
            }
          }}
        />
      ),
    });
  };

  // Effects
  // filteredMenu가 바뀔때 마다 업데이트
  useEffect(() => {
    const newMenuList = filteredMenu
      .filter(
        (menu) =>
          menu.day === menuDetailFormData.week &&
          menu.childType === menuDetailFormData.childType,
      )
      .sort((a, b) => {
        if (a.menuName < b.menuName) return -1;
        if (a.menuName > b.menuName) return 1;

        return a.amount - b.amount;
      });
    setDisplayMenu(newMenuList);
  }, [menuDetailFormData.childType, menuDetailFormData.week, filteredMenu]);

  return (
    <>
      <div className="flex items-center justify-between">
        <span className="whitespace-pre-wrap text-[0.875rem] font-normal leading-[1.25rem] text-custom-black">
          메뉴
        </span>
        <span className="whitespace-pre-wrap text-[0.875rem] font-normal leading-[1.25rem] text-custom-black">
          * 메뉴 추가를 누르시면 오른쪽 화면이 초기화가 됩니다.
        </span>
      </div>
      <div className="relative flex h-[64vh] w-full flex-col overflow-auto rounded-[8px] border border-solid border-custom-gray">
        <div className="sticky right-0 top-0 z-10 flex w-full justify-end bg-white px-[30px] py-[2vh]">
          <Button
            title="메뉴 추가 ⨁"
            height={30}
            width={120}
            onClick={() => setShowAddMenu(true)}
          />
        </div>
        {displayMenu && displayMenu.length > 0 ? (
          <>
            <ul className="z-0 h-full w-full">
              {displayMenu.map((item, index, arr) => (
                <li
                  key={item.day + index}
                  className="w-full  cursor-pointer flex-col justify-center px-[30px]"
                >
                  <div className="flex w-full  items-center">
                    <strong className="w-[15%] text-[1.25rem] font-bold leading-[1.75rem]">
                      {index + 1}
                    </strong>
                    <div className="relative flex w-full flex-col rounded-[8px] bg-white px-[1.25rem] py-[1.48vh] shadow-[0_0_20px_0_rgba(0,0,0,0.05)]">
                      <span
                        className={`text-[0.875rem] font-medium leading-[1.25rem] text-custom-gray`}
                      >
                        {`${KOREAN_WEEKDAY[item.day]}, ${
                          KOREAN_CHILDTYPE[item.childType as ChildType]
                        }`}
                      </span>
                      <strong className="text-[1rem] font-bold leading-[1.25rem]">
                        {item.menuName}
                      </strong>
                      <div className="flex w-full items-center justify-between">
                        <span className="text-[0.75rem] leading-[1rem]">
                          {item.amount}g
                        </span>
                        <strong>{item.price.toLocaleString("ko-KR")}원</strong>
                      </div>

                      <CancelIcon
                        className="absolute right-[4px] top-[0.56vh] cursor-pointer"
                        onClick={() => onClickDeleteMenu(item.menuId)}
                      />
                    </div>
                  </div>
                  {!(arr.length === index + 1) && (
                    <div className="py-[1.48vh]">
                      <Separator />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </>
  );
};

export default MenuList;
