import React, { HTMLAttributes, ReactNode } from "react";
import { ColorType } from "../common/Badge";

interface SpanProps extends HTMLAttributes<HTMLSpanElement> {
  type:
    | "12pxNormalGray"
    | "12pxNormalBlue"
    | "12pxNormalBlack"
    | "12pxNormalPureBlack"
    | "12pxBoldGray"
    | "12pxBoldBlue"
    | "12pxBoldBlack"
    | "12pxBoldPureBlack"
    | "12pxBoldMainYellow"
    | "14pxNormalGray"
    | "14pxNormalBlack"
    | "14pxNormalPureBlack"
    | "14pxNormalMainYellow"
    | "14pxNormalLightGreen"
    | "14pxNormalExcelGreen"
    | "14pxBoldGray"
    | "14pxBoldBlack"
    | "14pxBoldPureBlack"
    | "14pxBoldMainYellow"
    | "14pxBoldMainRed"
    | "14pxBoldMainGreen"
    | "16pxNormalGray"
    | "16pxNormalBlack"
    | "16pxNormalPureBlack"
    | "16pxBoldGray"
    | "16pxBoldBlack"
    | "16pxBoldPureBlack"
    | "16pxBoldMainYellow"
    | "20pxNormalGray"
    | "20pxNormalBlack"
    | "20pxNormalPureBlack"
    | "20pxBoldGray"
    | "20pxBoldBlack"
    | "20pxBoldPureBlack"
    | "20pxBoldMainYellow"
    | "24pxNormalGray"
    | "24pxNormalBlack"
    | "24pxNormalPureBlack"
    | "24pxBoldGray"
    | "24pxBoldBlack"
    | "24pxBoldPureBlack"
    | "24pxBoldMainYellow";
  children: ReactNode;
  alignSelf?: "start" | "center" | "end";
  textColor?: ColorType;
}

const Span = ({
  type,
  alignSelf,
  children,
  textColor,
  ...props
}: SpanProps) => {
  const classList = {
    "12pxNormalGray": "text-[0.75rem] text-custom-gray",
    "12pxNormalBlue": "text-[0.75rem] text-custom-mainBlue",
    "12pxNormalBlack": "text-[0.75rem] text-custom-black",
    "12pxNormalPureBlack": "text-[0.75rem] text-black",
    "12pxBoldGray": "text-[0.75rem] font-bold text-custom-gray",
    "12pxBoldBlue": "text-[0.75rem] font-bold text-custom-mainBlue",
    "12pxBoldBlack": "text-[0.75rem] font-bold text-custom-black",
    "12pxBoldPureBlack": "text-[0.75rem] font-bold text-black",
    "12pxBoldMainYellow": "text-[0.75rem] font-bold text-custom-mainYellow",

    "14pxNormalGray": "text-[0.875rem] text-custom-gray",
    "14pxNormalBlack": "text-[0.875rem] text-custom-black",
    "14pxNormalPureBlack": "text-[0.875rem] text-black",
    "14pxNormalMainYellow": "text-[0.875rem] text-custom-mainYellow",
    "14pxNormalLightGreen": "text-[0.875rem] text-custom-lightGreen",
    "14pxNormalExcelGreen": "text-[0.875rem] text-custom-excelGreen",
    "14pxBoldGray": "text-[0.875rem] font-bold text-custom-gray",
    "14pxBoldBlack": "text-[0.875rem] font-bold text-custom-black",
    "14pxBoldPureBlack": "text-[0.875rem] font-bold text-black",
    "14pxBoldMainYellow": "text-[0.875rem] font-bold text-custom-mainYellow",
    "14pxBoldMainRed": "text-[0.875rem] font-bold text-custom-mainRed",
    "14pxBoldMainGreen": "text-[0.875rem] font-bold text-custom-mainGreen",

    "16pxNormalGray": "text-[1rem] text-custom-gray",
    "16pxNormalBlack": "text-[1rem] text-custom-black",
    "16pxNormalPureBlack": "text-[1rem] text-black",
    "16pxBoldGray": "text-[1rem] font-bold text-custom-gray",
    "16pxBoldBlack": "text-[1rem] font-bold text-custom-black",
    "16pxBoldPureBlack": "text-[1rem] font-bold text-black",
    "16pxBoldMainYellow": "text-[1rem] font-bold text-custom-mainYellow",

    "20pxNormalGray": "text-[1.25rem] text-custom-gray",
    "20pxNormalBlack": "text-[1.25rem] text-custom-black",
    "20pxNormalMainYellow": "text-[1.25rem] text-custom-mainYellow",
    "20pxNormalPureBlack": "text-[1.25rem] text-black",
    "20pxBoldGray": "text-[1.25rem] font-bold text-custom-gray",
    "20pxBoldBlack": "text-[1.25rem] font-bold text-custom-black",
    "20pxBoldPureBlack": "text-[1.25rem] font-bold text-black",
    "20pxBoldMainYellow": "text-[1.25rem] font-bold text-custom-mainYellow",

    "24pxNormalGray": "text-[1.5rem] text-custom-gray",
    "24pxNormalBlack": "text-[1.5rem] text-custom-black",
    "24pxNormalPureBlack": "text-[1.5rem] text-black",
    "24pxNormalMainYellow": "text-[1.5rem] text-custom-mainYellow",
    "24pxBoldGray": "text-[1.5rem] font-bold text-custom-gray",
    "24pxBoldBlack": "text-[1.5rem] font-bold text-custom-black",
    "24pxBoldPureBlack": "text-[1.5rem] font-bold text-black",
    "24pxBoldMainYellow": "text-[1.5rem] font-bold text-custom-mainYellow",
  };

  const textColorClasses = {
    "custom-mainYellow": "text-custom-mainYellow",
    "custom-lightYellow": "text-custom-lightYellow",
    "custom-mainBlue": "text-custom-mainBlue",
    "custom-lightBlue": "text-custom-lightBlue",
    "custom-mainRed": "text-custom-mainRed",
    "custom-lightRed": "text-custom-lightRed",
    "custom-gray": "text-custom-gray",
    "custom-black": "text-custom-black",
    "custom-mediumGray": "text-custom-mediumGray",
    "custom-mainGreen": "text-custom-mainGreen",
    "custom-lightGreen": "text-custom-lightGreen",
  };

  const alignClass = alignSelf
    ? alignSelf === "start"
      ? "text-start"
      : alignSelf === "center"
      ? "text-center"
      : alignSelf === "end"
      ? "text-end"
      : ""
    : "";

  return (
    <span
      {...props}
      className={`${classList[type]} ${alignClass} ${
        textColor && textColorClasses[textColor]
      }`}
    >
      {children}
    </span>
  );
};

export default Span;
