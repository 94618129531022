import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import useShopSettingControl from "../../lib/hooks/UseShopSettingControl";
import { shopInfoAtom } from "../../lib/recoils/atoms/shopInfoAtom";
import { ShopSettingStateProps } from "../../lib/recoils/atoms/ShopSettingPage/ShopSettingAtom";
import { ShopInfo } from "../../types/UserTypes";
import InputBase from "../input/InputBase";
import Span from "../span/Span";
import ShopSettingAddressInput from "./ShopSettingAddressInput";
import ShopSettingFileInput from "./ShopSettingFileInput";

const ShopInfoForm = ({ shopInfo }: { shopInfo: ShopSettingStateProps }) => {
  // Hooks
  const { onChangeShopSetting, shopSettingState } = useShopSettingControl();

  // Query
  // const { data: shopInfo } = useQuery({
  //   queryKey: ["shopInfo"],
  //   queryFn: shopsApi.getShopInfo,
  // });

  // Recoil
  // const shopInfo = useRecoilValue(shopInfoAtom);

  return (
    <div className="flex flex-col">
      <Span type="14pxNormalBlack">매장 정보</Span>

      {shopInfo ? (
        <div className="custom-border-container flex w-[24.48vw] grow flex-col gap-y-[2.22vh] p-6">
          <InputBase
            fullWidth
            title="매장명"
            heightVh={4}
            name="name"
            value={shopInfo.name}
            onChange={onChangeShopSetting}
          />
          <ShopSettingAddressInput />
          <InputBase
            fullWidth
            title="전화번호"
            heightVh={4}
            name="mobile"
            value={
              shopSettingState.mobile === ""
                ? shopInfo.mobile
                : shopSettingState.mobile
            }
            onChange={onChangeShopSetting}
          />
          <InputBase
            title="담당자 명"
            heightVh={4}
            name="owner"
            value={
              shopSettingState.owner === ""
                ? shopInfo.owner
                : shopSettingState.owner
            }
            onChange={onChangeShopSetting}
          />
          <ShopSettingFileInput />
        </div>
      ) : null}
    </div>
  );
};

export default ShopInfoForm;
