import { useEffect, useState } from "react";
import MainContentContainer from "../components/common/MainContentContainer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContentTitle from "../components/common/ContentTitle";
import ChildTypeList from "../components/MenuPage/ChildTypeList";
import { useRecoilState, useSetRecoilState } from "recoil";
import { shopMemuFormAtom } from "../lib/recoils/atoms/ShopMenuPage/ShopMenuFormAtom";
import MenuDetail from "../components/MenuPage/MenuDetail";
import SelectedDaySection from "../components/MenuPage/SelectedDay";
import MenuList from "../components/MenuPage/MenuList";
import { useQuery } from "@tanstack/react-query";
import shopsApi from "../api/Shops";
import { ShopMenu } from "../types/MenuType";
import { refetchFunctionsAtom } from "../lib/recoils/RefetchFunctionsAtom";

export type CurrentStageIndex = 0 | 1 | 2 | 3 | 4;

const MenuPage = () => {
  // Query
  const {
    data: wholeMenuData,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["shopMenuList"],
    queryFn: shopsApi.getTotalMenu,
  });

  // Recoil
  const [menuDetailFormData, setMenuDetailFormData] =
    useRecoilState(shopMemuFormAtom);
  const setRefetchFunctions = useSetRecoilState(refetchFunctionsAtom);
  // States
  const [currentStageIndex, setCurrentStgeIndex] =
    useState<CurrentStageIndex>(0);
  const [showAddMenu, setShowAddMenu] = useState(false);
  const [filteredMenuData, setFilteredMenuData] = useState<ShopMenu[]>([]);

  // Effects
  // 언마운트시 Recoil 초기화
  useEffect(() => {
    return () => {
      setMenuDetailFormData({
        childType: "",
        foodAmount: 0,
        name: "",
        price: 0,
        week: "",
      });
    };
  }, [setMenuDetailFormData]);

  useEffect(() => {
    if (
      isError ||
      isLoading ||
      !wholeMenuData ||
      (wholeMenuData as ShopMenu[]).length === 0
    )
      return;
    const filterWeekDay = menuDetailFormData.week;
    const filterWeekDayMenus = (wholeMenuData as ShopMenu[]).filter(
      (menu) => menu.day === filterWeekDay,
    );
    setFilteredMenuData(filterWeekDayMenus);
  }, [wholeMenuData, menuDetailFormData.week, isError, isLoading]);

  // refetch 함수 등록
  useEffect(() => {
    setRefetchFunctions((prev) => ({ ...prev, refetchShopMenu: refetch }));

    return () => {
      setRefetchFunctions((prev) => ({ ...prev, refetchShopMenu: undefined }));
    };
  }, [refetch, setRefetchFunctions]);

  // 디버깅
  useEffect(() => {
    console.log(filteredMenuData);
  }, [filteredMenuData]);

  // useEffect(() => {
  //   console.log(wholeMenuData);
  // }, [wholeMenuData]);

  return (
    <div className="flex h-full w-full flex-col">
      <ContentTitle hasMb>메뉴</ContentTitle>
      <MainContentContainer>
        <div className="mb-[3.5vh]">
          <span className="whitespace-pre-wrap text-[0.875rem] font-normal leading-[1.25rem] text-custom-black">
            매장 정보 및 운영에 필요한 설정을 할 수 있습니다.
          </span>
        </div>
        <div className="flex h-full w-full justify-between">
          <SelectedDaySection
            currentStageIndex={currentStageIndex}
            setCurrentStgeIndex={setCurrentStgeIndex}
          />

          <section className="flex h-full w-[22.18vw] flex-col gap-y-[7px]">
            {currentStageIndex >= 1 && (
              <ChildTypeList
                filteredMenu={filteredMenuData}
                currentStageIndex={currentStageIndex}
                setCurrentStgeIndex={setCurrentStgeIndex}
              />
            )}
          </section>
          <section className="flex h-full w-[22.81vw] flex-col gap-y-[7px]">
            {currentStageIndex >= 2 && (
              <MenuList
                setShowAddMenu={setShowAddMenu}
                filteredMenu={filteredMenuData}
              />
            )}
          </section>
          <section className="flex h-full w-[15.6vw] flex-col gap-y-[7px] overflow-auto">
            {showAddMenu && <MenuDetail setShowAddMenu={setShowAddMenu} />}
          </section>
        </div>
      </MainContentContainer>
    </div>
  );
};

export default MenuPage;
