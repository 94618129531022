import React, { useState } from "react";
import Button from "../button/Button";
import SignUpInput from "./SignUpInput";

const FormContainer = () => {
  const [signUpFormData, setSignUpFormData] = useState({
    name: "",
    owner: "",
    address: "",
    addressDetail: "",
    mobile: "",
    password: "",
    imgUrl: "",
  });

  type SignUpField = keyof typeof signUpFormData;

  // Functions
  const handleChange =
    (field: SignUpField) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSignUpFormData((prevData) => ({
        ...prevData,
        [field]: event.target.value,
      }));
    };

  return (
    <div className="flex w-full flex-col bg-white">
      <span>매장 정보 및 운영에 필요한 설정을 할 수 있습니다.</span>
      <h3>매장 정보</h3>
      <form className="flex h-[100vh] flex-col rounded-l border border-solid">
        <h3>매장명</h3>
        <SignUpInput
          value={signUpFormData.name}
          placeholder="매장명"
          onChange={handleChange("name")}
        />

        <Button title="저장하기" width={120} height={60} />
      </form>
    </div>
  );
};

export default FormContainer;
