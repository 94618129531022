const formatTime = (time: number) => {
  let sec: string | number = time % 60;
  let min: string | number = Math.floor(time / 60);

  if (sec < 10) sec = "0" + sec;
  if (min < 10) min = "0" + min;

  return `${min}:${sec}`;
};

export default formatTime;
