import { Link, useLocation, useNavigate } from "react-router-dom";
import Span from "../span/Span";
import { useSetRecoilState } from "recoil";
import { loginInfoAtom } from "../../lib/recoils/atoms/LoginInfoAtom";

const LeftNav = () => {
  // Hooks
  const location = useLocation();
  const navigate = useNavigate();

  // Vars
  const isHq = location.pathname.includes("hq");

  // Recoils
  const setLoginInfo = useSetRecoilState(loginInfoAtom);

  // Functions
  const onClickSignOut = () => {
    console.log("wqeqwe");
    localStorage.clear();
    setLoginInfo(false);
    navigate("/");
  };

  // Classes
  const spanClass =
    "text-[1.25rem] text-custom-black font-bold leading-[1.75rem] whitespace-nowrap";

  return (
    <nav
      className={`box-border flex h-screen w-[13vw] min-w-[120px] shrink-0 flex-col  justify-between bg-white pb-[5.5vh] pt-[calc(60px+5.5vh)]`}
    >
      {!isHq && (
        <ul className="flex w-full flex-col gap-y-[4rem] px-[3.8vw] ">
          <li className="list-none ">
            <Link to="/or">
              <span className={spanClass}>주문</span>
            </Link>
          </li>
          <li className="list-none ">
            <Link to="/io">
              <span className={spanClass}>입출고 현황</span>
            </Link>
          </li>
          <li className="list-none ">
            <Link to="/rq">
              <span className={spanClass}>요청 및 회수</span>
            </Link>
          </li>
          <li className="list-none ">
            <Link to="/sc">
              <span className={spanClass}>고객</span>
            </Link>
          </li>
          <li className="list-none ">
            <Link to="/sd">
              <span className={spanClass}>기사</span>
            </Link>
          </li>
          <li className="list-none ">
            <Link to="/sm">
              <span className={spanClass}>메뉴</span>
            </Link>
          </li>
          {/* <li className="list-none ">
            <Link to="/hq/io">
              <span className={spanClass}>HQ으로(테스트)용</span>
            </Link>
          </li> */}
        </ul>
      )}
      {isHq && (
        <ul className="flex w-full flex-col gap-y-[65px] px-[3.8vw] ">
          <li className="list-none ">
            <Link to="/hq/io">
              <span className={spanClass}>입출고 현황</span>
            </Link>
          </li>

          <li className="list-none ">
            <Link to="/hq/shops">
              <span className={spanClass}>매장</span>
            </Link>
          </li>
          <li className="list-none ">
            <Link to="/hq/drivers">
              <span className={spanClass}>기사</span>
            </Link>
          </li>
          <li className="list-none ">
            <Link to="/hq/containers">
              <span className={spanClass}>박스&용기</span>
            </Link>
          </li>
          {/* <li className="list-none ">
            <Link to="/io">
              <span className={spanClass}>shop으로(테스트)용</span>
            </Link>
          </li> */}
        </ul>
      )}

      <div className="flex w-full justify-end px-8">
        <div className="cursor-pointer" onClick={onClickSignOut}>
          <Span type="20pxBoldGray">로그아웃</Span>
        </div>
      </div>
    </nav>
  );
};

export default LeftNav;
