import ContainerType from "../types/ContainerTypes";
import { HandleType, HqBoxContainerStatus, Material } from "../types/HqTypes";
import { DeliveryStatusType } from "../types/OrderTypes";
import { ChildType } from "./recoils/atoms/ShopMenuPage/ShopMenuFormAtom";

export type WeekDay = "mon" | "tue" | "wed" | "thu" | "fri";

export const KOREAN_WEEKDAY: {
  [key in WeekDay]: string;
} = {
  mon: "월",
  tue: "화",
  wed: "수",
  thu: "목",
  fri: "금",
};

export const KOREAN_CHILDTYPE: {
  [key in ChildType]: string;
} = {
  "": "",
  superEarly: "초기",
  early: "초중기",
  middle: "중기",
  lateMiddle: "중후기",
  late: "후기",
  completion: "완료기",
  infant: "유아식",
  etc: "기타",
};

export const KOREAN_CONTAINER: { [key in ContainerType]: string } = {
  glass: "유리용기",
  disposable: "일반용기",
};

export const KOREAN_DELIVERY_STATUS: { [key in DeliveryStatusType]: string } = {
  ready: "배송준비",
  delivering: "배송중",
  delivered: "배송완료",
  collecting: "수거중",
  collected: "수거완료",
};

export const KOREAN_HQ_CONTAINER: { [key in Material]: string } = {
  "": "알 수 없음",
  dispose: "박스",
  glass: "유리",
};

export const HANDLE_TYPE: HandleType[] = [
  "new",
  "repair",
  "dispose",
  "complete",
];

export const KOREAN_HQ_BOXCONTAINER_STATUS: {
  [key in HqBoxContainerStatus]: string;
} = {
  transferDriverMatched: "이송요청",
  transferring: "이송중",
  resendDriverMatched: "반납요청",
  resending: "반납중",
  inStock: "재고입고",
  resendReady: "반납대기",
  transferReady: "이송대기",
  transferred: "이송완료",
};

export const CHILDTYPE_ORDER: { [key: string]: number } = {
  infant: 1,
  superEarly: 2,
  early: 3,
  middle: 4,
  lateMiddle: 5,
  late: 6,
  completion: 7,
  etc: 8,
};

export const CHILDTYPE_ORDER_TOTAL_ORDER: { [key: string]: number } = {
  superEarly: 1,
  early: 2,
  middle: 3,
  lateMiddle: 4,
  late: 5,
  completion: 6,
  infant: 7,
};
