import { InputBaseProps } from "./InputTypes";
import { ReactComponent as SearchIcon } from "../../assets/svgs/Search.svg";

const InputBase = ({
  id,
  name,
  title,
  width,
  height,
  heightVh,
  onChange,
  fullWidth,
  placeholder,
  value,
  onClickInput,
  disabled,
  inputType,
  additionalText,
  whereTo,
  searchIcon,
  ...props
}: InputBaseProps) => {
  // Classes
  const widthClass = fullWidth ? "100%" : width ? width : "auto";
  const heightClass = heightVh
    ? `${heightVh}vh`
    : height
    ? typeof height === "string"
      ? `4vh`
      : `52px`
    : `52px`;

  return (
    <div className="flex flex-col">
      {title && (
        <label
          htmlFor={id}
          className="mb-[1.85vh] text-[1rem] font-bold leading-5"
        >
          {title}
        </label>
      )}
      <div className={`relative h-fit ${fullWidth ? "w-full" : "w-fit"}`}>
        <input
          id={id}
          name={name}
          placeholder={placeholder ? placeholder : ""}
          value={value}
          style={{
            width: widthClass,
            height: heightClass,
          }}
          className={`box-border rounded-lg border border-solid p-4`}
          onChange={onChange}
          onClick={onClickInput}
          disabled={disabled}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
            }
          }}
          type={inputType}
          {...props}
        />
        {searchIcon && (
          <SearchIcon className="absolute right-[0.63vw] top-1/2 -translate-y-1/2 cursor-pointer" />
        )}
      </div>
      {additionalText && (
        <span className="mt-[1.1vh] self-end text-[0.875rem] leading-[1.25rem] text-custom-mainYellow">
          {additionalText}
        </span>
      )}
    </div>
  );
};

export default InputBase;
