type formatMobileStringFunc = (mobileString: string) => string;

export const formatMobileString: formatMobileStringFunc = (
  mobileString: string
) => {
  const formattedMobile = mobileString
    .replace(/[^0-9]/g, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

  return formattedMobile;
};
