import axios, { isAxiosError } from "axios";
import { DepositFormDataProps } from "../components/ShopCustomerAndDriverPage/DepositModal";
import {
  ChildType,
  SelectedDay,
} from "../lib/recoils/atoms/ShopMenuPage/ShopMenuFormAtom";
import { ShopSettingStateProps } from "../lib/recoils/atoms/ShopSettingPage/ShopSettingAtom";
import webStorage from "../lib/utils/WebStorage";
import { ShopInAndOutFilterType } from "../pages/InAndOutStatusPage";
import { HqBoxContainerStatus } from "../types/HqTypes";
import { ShopMenu } from "../types/MenuType";
import {
  ClosedDateTimeRes,
  Container,
  FinishedCallingDriverDateTimeRes,
  InAndOutBoxContainerStatus,
  InAndOutDeliveryStatus,
  ModifyedMenuItem,
  OrderByDriverRes,
  OrderDriverRes,
  SortType,
  TotalMenuItem,
} from "../types/OrderTypes";
import { ShopRequestToHqRes } from "../types/ShopBoxContainerPageTypes";
import { DriverProps, ShopInfo } from "../types/UserTypes";
import { DayOffType } from "../types/ShopSettingTypes";
import { CHILDTYPE_ORDER_TOTAL_ORDER } from "../lib/Consts";

interface ShopMenuRes {
  message: string;
  data: ShopMenu[];
}

interface GetTotalOrderListRes {
  message: string;
  data: TotalMenuItem[];
}

const shopsApi = {
  // 매장 정보 불러오기
  getShopInfo: async () => {
    try {
      const { data } = await axios<{ message: string; data: ShopInfo[] }>(
        "shops/shop-info",
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  // 매장에서 고객 등륵
  registerUser: async (
    name: string,
    address: string,
    addressDetail: string,
    mobile: string,
    containerType: string,
    discount: number,
    description: string,
  ) => {
    try {
      const { data, status } = await axios.post("/shops/user-sign-up", {
        name,
        address,
        addressDetail,
        mobile,
        containerType,
        discount,
        description,
      });
      return { data, status };
    } catch (error) {
      return { data: error, status: 400 };
    }
  },

  // 매장-고객 고객 삭제
  shopDeleteCustomer: async (userId: number) => {
    try {
      const { data, status } = await axios.patch("/shops/user-delete", {
        userId,
      });
      return { data, status };
    } catch (error) {
      if (isAxiosError(error)) {
        const message = error.response?.data;
        return { data: message, status: 400 };
      }
      return { data: error, status: 400 };
    }
  },

  // 매장-고객 고객 업데이트
  shopUpdateCustomer: async (
    name: string,
    mobile: string,
    address: string,
    addressDetail: string,
    containerType: string,
    discount: number,
    description: string,
    userId: number,
  ) => {
    try {
      const { data, status } = await axios.patch("/shops/user-update", {
        name,
        mobile,
        address,
        addressDetail,
        containerType,
        discount,
        description,
        userId,
      });
      return { data, status };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error.response?.data;
        return { data: message, status: 400 };
      }
      return { data: error, status: 400 };
    }
  },

  // 매장-고객-고객등록 매장 고객 중복 체크
  checkDuplicatedUser: async (mobile: string) => {
    try {
      const { data, status } = await axios.post("/shops/user-duplicate-check", {
        mobile,
      });

      return { data, status };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error.response?.data;
        return { data: message, status: 400 };
      }
      return { data: error, status: 400 };
    }
  },

  // 고객 예치금 설정
  setUserDeposit: async (formData: DepositFormDataProps) => {
    try {
      const { data, status } = await axios.post("/shops/user-deposit", {
        name: formData.name,
        mobile: formData.mobile,
        amount: formData.totalDeposit,
        remarks: formData.remarks,
      });

      return { data, status };
    } catch (error) {
      if (isAxiosError(error)) {
        const message = error.response?.data;
        alert(message);
        return { error };
      }
      alert(error);
      return { error };
    }
  },

  // 매장-고객 : 고객 리스트 불러오기
  getCustomerList: async (
    pageNum: number,
    sort: "desc" | "asc",
    searchText?: string,
  ) => {
    try {
      const { data } = await axios.get("/shops/user-list", {
        params: {
          sort,
          pageNum,
          perPage: 10,
          searchText,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      return {
        message: "NOT EXIST DATA",
        data: [],
        totalCount: 0,
      };
    }
  },

  // 매장-기사 기사 등록
  shopRegisterDriver: async (
    name: string,
    mobile: string,
    vehicleNum: string,
    vehicleModel: string,
    vehicleType: string,
  ) => {
    try {
      const { data, status } = await axios.post("/shops/driver-sign-up", {
        name,
        mobile,
        vehicleNum,
        vehicleModel,
        vehicleType,
      });
      return { data, status };
    } catch (error) {
      if (isAxiosError(error)) {
        console.log(error.response);
        const { data, status } = error.response!;
        return { data, status };
      }
      return { data: error, status: 400 };
    }
  },

  // 매장-기사 기사 삭제
  shopDeleteDriver: async (driverId: number) => {
    try {
      const { data, status } = await axios.patch("/shops/driver-delete", {
        driverId,
      });
      return { data, status };
    } catch (error) {
      if (isAxiosError(error)) {
        const message = error.response?.data;
        return { data: message, status: 400 };
      }
      return { data: error, status: 400 };
    }
  },

  // 매장-기사 : 모든 기사 리스트 불러오기
  getDriverList: async (
    pageNum: number,
    sort: "desc" | "asc",
    searchText?: string,
    checkDeliveryWaiting?: string,
  ) => {
    try {
      const { data } = await axios.get("/shops/driver-list", {
        params: {
          sort,
          pageNum,
          perPage: 10,
          searchText,
          checkDeliveryWaiting,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        console.log(error);
        const message = error.response?.data;
        if (message === "NOT EXIST DATA") {
          return {
            message: "NOT EXIST DATA",
            data: [],
            totalCount: 0,
          };
        }
      }
      // alert(error);
      return {
        message: "NOT EXIST DATA",
        data: [],
        totalCount: 0,
      };
    }
  },

  // 매장-기사-기사 정보 수정 기사 중복 확인
  checkDuplicatedDriver: async (mobile: string) => {
    try {
      const { status } = await axios.post("/shops/driver-duplicate-check", {
        mobile,
      });
      return status;
    } catch (error) {
      return 400;
    }
  },

  // 매장-기사-기사 정보 수정
  updateDriverInfo: async (
    driver: Omit<DriverProps, "createdAt" | "totalCount" | "delivery_status">,
  ) => {
    try {
      const { data, status } = await axios({
        method: "patch",
        url: "shops/driver-update",
        data: { ...driver },
      });

      return { data, status };
    } catch (error) {
      return { data: error, status: 401 };
    }
  },

  // 매장-주문 order-list api
  getOrderList: async (
    date: string,
    perPage: number,
    pageNum: number,
    sort: SortType,
    searchText: string,
  ) => {
    const isRegular = sort === "regular" ? "YES" : sort === "short" ? "NO" : "";
    console.log("api에서 pageNumb", pageNum);
    try {
      // 원래 API
      // const REQ_URL = "/shops/order-list";
      // 수정된 API
      const REQ_URL = "/shops/shop-order-list";

      const { data } = await axios.get(REQ_URL, {
        params: { date, perPage, pageNum, isRegular, searchText },
      });

      return data;
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        console.log(error);
        const message = error.response?.data;
        if (message === "NOT EXIST DATA") {
          return {
            message: "NOT EXIST DATA",
            data: [],
            totalCount: 0,
          };
        }
      }
      // alert(error);
      return {
        message: "NOT EXIST DATA",
        data: [],
        totalCount: 0,
      };
    }
  },

  // 매장-주문 전체주문 보기
  getTotalOrderList: async (date: string | undefined) => {
    try {
      const { data } = await axios.get<GetTotalOrderListRes>(
        "/shops/total-order-list",
        { params: { date } },
      );

      if (data.data.length > 0) {
        const orderData = data.data;

        const collectObject = orderData.find(
          (obj) => obj.orderType === "collect",
        );
        const orderObjects = orderData.filter(
          (obj) => obj.orderType === "order",
        );

        const orderMap: { [key: string]: ModifyedMenuItem } = {};

        orderObjects.forEach((data) => {
          const containerMap: { [key: string]: number } = {};

          const containerItems = data.container?.split(",");
          containerItems?.forEach((item) => {
            const [containerType, quantityStr] = item.split(":");
            const quantity = parseInt(quantityStr);

            if (containerMap.hasOwnProperty(containerType)) {
              containerMap[containerType] += quantity;
            } else {
              containerMap[containerType] = quantity;
            }
          });

          const containers: Container[] = Object.entries(containerMap).map(
            ([container, quantity]) => ({
              container,
              quantity: Number(quantity),
            }),
          );

          const key = `${data.name}-${data.foodAmount}`;

          if (orderMap[key]) {
            orderMap[key].containers =
              orderMap[key].containers.concat(containers);
          } else {
            orderMap[key] = {
              name: data.name!,
              childType: data.childType! as ChildType,
              food_amount: Number(data.foodAmount!),
              containers,
            };
          }
        });

        const modifiedTotalOrders: any[] = [...Object.values(orderMap)];

        if (collectObject) {
          modifiedTotalOrders.push({
            name: "collectObject",
            childType: null,
            food_amount: null,
            quantity: Number(collectObject.collectContainer),
          });
        }

        console.log("modifiedTotalOrders", modifiedTotalOrders);

        const orderedTotalOrders = modifiedTotalOrders.sort((a, b) => {
          const orderA = CHILDTYPE_ORDER_TOTAL_ORDER[a.childType] || 0;
          const orderB = CHILDTYPE_ORDER_TOTAL_ORDER[b.childType] || 0;

          return orderA - orderB;
        });

        return orderedTotalOrders;
        // return modifiedTotalOrders;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  // 매장-주문 드라이버 조회 order-driver-list
  getOrderDriverList: async (
    sort: string,
    perPage: number,
    pageNum: number,
  ) => {
    try {
      const { data } = await axios.get<OrderDriverRes>(
        "/shops/order-driver-list",
        {
          params: {
            searchText: sort,
            perPage,
            pageNum,
          },
        },
      );
      return data;
    } catch (error) {
      console.log(error);
      return {
        message: "",
        data: [],
        totalCount: 0,
      };
    }
  },

  // 매장-주문 기사 배정 (저장)
  assignDriver: async (driverId: number, orderId: number[]) => {
    try {
      const { status } = await axios.patch("/shops/update-assign-driver", {
        driverId,
        orderId,
      });
      return status;
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        return 400;
      }
      return 400;
    }
  },

  // 마감할때는 parameter 없음
  finishTodayOrder: async () => {
    try {
      // 왜 이게 되고있지?
      // const { status } = await axios.post("/shops/delivery-status-change");
      // 마감시간 변경
      const { status } = await axios.patch<{ message: string; data: string }>(
        "shops/closed-for-today",
      );

      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 매장 - 기사호출
  // 기사호출때만 parameter 넘겨줌
  finishCallingDrivers: async (changeType: string, boxQuantity: number) => {
    try {
      const { status } = await axios.post("/shops/delivery-status-change", {
        changeType,
        boxQuantity,
      });
      return { status };
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 매장-주문 지정된 기사 배정 취소
  cancelAssignedDriver: async (orderId: number, driverId: number) => {
    try {
      const { status } = await axios.patch("/shops/driver-order-cancel", {
        orderId,
        driverId,
      });
      console.log("기사 배정 취소 완료");
      return status;
    } catch (error) {
      return 400;
    }
  },

  // 매장-특이사항 수정
  remarksDescriptionInput: async (remarks: string, userId: number) => {
    try {
      const { status } = await axios.patch("/shops/shop-remarks-input", {
        remarks,
        userId,
      });
      return status;
    } catch (error) {
      return 400;
    }
  },

  // Presinged URL로 사진 업로드 하고 업로든된 사진의 S3 url 받기
  uploadFileToS3: async (key: string, files: File[]) => {
    if (!files) alert("사진을 선택해주세요.");
    try {
      let publicPhotoUrls: string[] = [];
      for (const file of files) {
        console.log("API 요청의 파일", file);
        const {
          data: { url },
        } = await axios.post("/presigned", {
          fileName: file.name,
          key,
        });

        const response = await fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });
        // const response = await axios.put(url, file, {
        //   headers: { "Content-Type": file.type, Authorization: "" },
        // });

        console.log("aws response", response);
        if (response.status === 200) {
          console.log("File uploaded successfully", url);
          const bucketInfo = process.env.REACT_APP_BUCKET_INFO;
          const objectKey = `${key}/${file.name}`;
          const publicPhotoUrl = `${bucketInfo}/${objectKey}`;
          publicPhotoUrls.push(publicPhotoUrl);
        } else {
          console.log("File upload failed");
        }
      }
      return publicPhotoUrls;
    } catch (error) {
      console.log(error);
      alert("업로드 중 에러 발생");
      return;
    }
  },

  // 매장-주문 수동 주문 추가
  addManualOrder: async (userId: number, amount: number, date: string) => {
    try {
      const { status } = await axios.post("/shops/manual-order-create", {
        userId,
        amount,
        date,
      });
      return status;
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        alert("배송 수동 추가 중 문제 발생");
        return error.response?.status;
      }
      return error;
    }
  },

  cancelManualOrder: async (orderId: number) => {
    try {
      const { data, status } = await axios.post("/shops/cancel-manual-order", {
        orderId,
      });
      return { data, status };
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        const message = error.response?.data;
        const status = error.response?.status;
        return { data: message, status };
      }
      return { data: null, status: 500 };
    }
  },

  cancelOrder: async (orderId: number) => {
    try {
      const { data, status } = await axios.post("/shops/cancel-order", {
        orderId,
      });
      return { data, status };
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        const message = error.response?.data;
        const status = error.response?.status;
        return { data: message, status };
      }
      return { data: null, status: 500 };
    }
  },

  // 매장-메뉴 생성
  createMenu: async (
    name: string,
    childType: ChildType,
    week: SelectedDay,
    foodAmount: number,
    price: number,
  ) => {
    try {
      const { status } = await axios.post("/shops/menu-create", {
        name,
        childType,
        week,
        foodAmount,
        price,
      });
      return status;
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        const message = error.response?.data;
        alert(message);
        return error.response?.status;
      }
    }
  },

  // 매장-메뉴 모든 메뉴 조회
  getTotalMenu: async () => {
    try {
      if (!axios.defaults.baseURL) {
        axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
        console.log("Setting baseURL to:", axios.defaults.baseURL);
      }
      const { data } = await axios.get<ShopMenuRes>("/shops/get-menu-list");
      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  // 매장-메뉴 메뉴 삭제
  deleteMenu: async (menuId: number) => {
    try {
      const { status } = await axios.patch("/shops/menu-delete", { menuId });
      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 매장 - 기사호출 시 배송할 전체 리스트 가져오기
  allDeliveryLists: async (date: string, isAdditional?: boolean) => {
    try {
      const { data } = await axios.get<OrderByDriverRes>(
        "/shops/all-delivery-lists",
        {
          params: {
            date,
            isAdditional,
          },
        },
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  // 마감한 시간 가져오기
  getClosedDateTime: async () => {
    try {
      const { data } = await axios<ClosedDateTimeRes>(
        "/shops/order-list-closed-datetime",
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return [{ closed_datetime: null }];
    }
  },

  // 기사 호출한 시간 불러오기
  getFinishedCallingDriversDatetime: async () => {
    try {
      const { data } = await axios<FinishedCallingDriverDateTimeRes>(
        "/shops/finished-calling-drivers-datetime",
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return [
        {
          finished_calling_drivers_datetime: null,
        },
      ];
    }
  },

  // 입출고 현황 박스 개수 현황
  getBoxStatusCount: async () => {
    try {
      const { data } = await axios<{
        message: string;
        data: InAndOutBoxContainerStatus[];
      }>("/shops/product-current-status-count");
      return data.data[0];
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  // 입출고 현황 용기 개수 현황
  getContainerStatusCount: async () => {
    try {
      const { data } = await axios<{
        message: string;
        data: InAndOutBoxContainerStatus[];
      }>("/shops/container-current-status-count");
      return data.data[0];
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  // 입출고 현황 운송 내역
  getDeliveryStatus: async (
    startDate?: string,
    endDate?: string,
    pageNum = 1,
    perPage = 10,
    searchText?: string,
    statusFilter?: ShopInAndOutFilterType,
  ) => {
    try {
      const { data } = await axios<{
        message: string;
        data: {
          allDeliveryStatusData: InAndOutDeliveryStatus[];
          allDeliveryStatusTotal: { totalCount: number }[] | [];
        };
      }>("shops/all-delivery-status", {
        params: {
          startDate,
          endDate,
          pageNum,
          perPage,
          searchText,
          statusFilter,
        },
      });

      const totalCount = data.data.allDeliveryStatusTotal;

      return {
        data: data.data.allDeliveryStatusData,
        totalCount: totalCount.length > 0 ? totalCount[0]["totalCount"] : null,
      };
    } catch (error) {
      console.log(error);
      return undefined;
    }
  },

  updateShopInfo: async (shopFormData: ShopSettingStateProps) => {
    const accessToken = webStorage.getStorage("local", "accessToken");
    try {
      const { status } = await axios.patch(
        "/shops/update",
        {
          ...shopFormData,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 박스 및 용기 관리 매장 현황
  getContainerBoxStatus: async () => {
    try {
      const { data } = await axios<ShopRequestToHqRes>(
        "/shops/shop-request-to-hq-list",
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  // 박스 및 용기 요청
  requestContainerBoxToHq: async (
    status: HqBoxContainerStatus,
    containerId: number,
    quantity: number,
  ) => {
    try {
      await axios.post("/shops/shop-to-hq-request", {
        status,
        containerId,
        quantity,
      });
      return 200;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 요청 및 반납 모달
  getRequestAndRecall: async () => {
    try {
      const { data } = await axios<{
        message: string;
        data: {
          id: number;
          driver_id: null | number;
          container_id: number;
          status: HqBoxContainerStatus;
          quantity: null | number;
          updated_at: string;
        }[];
      }>("/shops/shop-to-hq-request-total-list");
      const onlyReady = data.data.filter(
        (item) =>
          item.status === "transferReady" || item.status === "resendReady",
      );
      return onlyReady;
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  // 요청 및 반납 진행
  proceedRequestAndRecall: async (
    shopId: number,
    containerHistoriesId: number[],
  ) => {
    try {
      const { status } = await axios.patch<{ message: string }>(
        "/shops/shop-to-hq-request-proceed",
        {
          shopId,
          containerHistoriesId,
        },
      );
      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  createFirstBoxContainer: async (
    reqBody: { containerId: number; quantity: number }[],
  ) => {
    try {
      const { status } = await axios.post(
        "/shops/shop-to-hq-first-order",
        reqBody,
      );
      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  setClosedTimeYesterday: async () => {
    try {
      const { status } = await axios.patch("/shops/closed-time");
      if (status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  setClosedTimeTomorrow: async () => {
    try {
      const { status } = await axios.patch("/shops/closed-time-tomorrow");
      if (status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  setDayOff: async () => {
    try {
      const { status } = await axios.patch("/shops/day-off");
      if (status === 200) {
        console.log(status);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("setDayOff API ERROR", error);
      return false;
    }
  },

  getDayOff: async () => {
    try {
      const { data } = await axios.get<DayOffType>("/shops/day-off");
      return data;
    } catch (error) {
      console.error("getDayOff API ERROR", error);
      return;
    }
  },

  // proceedRequestAndRecall: async (
  //   status: HqBoxContainerStatus,
  //   toBeStatus: HqBoxContainerStatus,
  // ) => {
  //   try {
  //     const { data } = await axios<{ data: RequestRecallData[] }>(
  //       "/shops/hq-to-shop-request-total-list",
  //       {
  //         params: {
  //           status,
  //           toBeStatus,
  //         },
  //       },
  //     );
  //     return data.data;
  //   } catch (error) {
  //     console.log(error);
  //     return [];
  //   }
  // },
};

export default shopsApi;
