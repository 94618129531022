import { ChangeEvent, ReactNode, useState } from "react";
import Button from "../button/Button";
import ContentTitle from "../common/ContentTitle";
import Separator from "../common/Separator";
import useAllDeliveryListsQuery from "../../lib/queries/UseAllDeliveryListsQuery";
import dayjs from "dayjs";
import { useModal } from "../../lib/hooks/UseModal";
import AlertModal from "../modal/AlertModal";
import shopsApi from "../../api/Shops";
import { KOREAN_CONTAINER } from "../../lib/Consts";
import { ModifiedOrderByDriver } from "../../types/OrderTypes";
import { useRecoilValue } from "recoil";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";

const Span = ({
  children,
  isGray,
}: {
  children: ReactNode;
  isGray?: boolean;
}) => {
  const spanClass = `text-[0.875rem] leading-[1.25rem] font-bold ${
    isGray ? "text-custom-gray" : "text-custom-black"
  }`;
  return <span className={spanClass}> {children}</span>;
};

const Badge = ({ variant }: { variant: "delivery" | "collecting" }) => {
  return (
    <div
      className={`flex items-center justify-center rounded-[8px] px-[0.41vw] py-[0.37vh] text-[0.75rem] leading-[1rem] ${
        variant === "delivery"
          ? "bg-custom-lightYellow text-custom-mainYellow"
          : "bg-custom-lightBlue text-custom-mainBlue"
      }`}
    >
      {variant === "delivery" ? "배송" : "수거"}
    </div>
  );
};

const CallDriverModal = ({ isAdditional }: { isAdditional?: boolean }) => {
  // Hooks
  const { openModal, changeModal, closeModal } = useModal();

  // Const
  const DATE = dayjs(new Date()).format("YYYY-MM-DD");

  // Query
  const { modifiedOrderByDriver, totalGeneralContainer, totalGlassContainer } =
    useAllDeliveryListsQuery(DATE, isAdditional);

  // Recoil
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  // States
  const [boxInputValue, setBoxInputValue] = useState<Record<string, number>>(
    {},
  );

  // Vars
  const totalBoxes = Object.values(boxInputValue).reduce(
    (sum, value) => sum + Number(value),
    0,
  );

  // Functions
  const finishCallingDrivers = async () => {
    // 마감은 이미 됨, 기사호출 API
    const changeType = "deliveringAndCollecting";
    const finishCallingDrivers = await shopsApi.finishCallingDrivers(
      changeType,
      totalBoxes,
    );
    if (finishCallingDrivers !== 400) {
      refetchFunctions &&
        refetchFunctions.refetchFinishDriverCallDateTime &&
        refetchFunctions.refetchFinishDriverCallDateTime();
      changeModal({
        content: (
          <AlertModal alertType="check" message="기사를 호출하였습니다." />
        ),
      });
    } else {
      changeModal({
        content: <AlertModal alertType="denied" message="오류 발생" />,
      });
    }
  };

  const onClickCallDrivers = () => {
    openModal({
      content: (
        <AlertModal
          alertType="warning"
          message="기사를 호출할까요?"
          onClickWarning={finishCallingDrivers}
        />
      ),
    });
  };

  const onChangeTotalContainer = (
    e: ChangeEvent<HTMLInputElement>,
    item: ModifiedOrderByDriver,
  ) => {
    const { value } = e.target;
    if (isNaN(Number(value))) return;
    setBoxInputValue((prev) => ({
      ...prev,
      [item.driverName]: Number(value),
    }));
  };

  return (
    <div className="z-20 box-border flex w-[38.5vw] flex-col justify-between rounded-[8px] bg-white px-custom-modal-x py-custom-modal-x">
      <ContentTitle isCenter>기사 호출</ContentTitle>
      <div className="flex w-full items-center justify-between">
        <ContentTitle>배송 준비</ContentTitle>
        <div className="flex gap-x-[1.45vw]">
          <div className="flex flex-col items-end">
            <Span>배송</Span>
            <Span>일반용기 : {totalGeneralContainer ?? 0}</Span>
            <Span>유리용기 : {totalGlassContainer ?? 0}</Span>
          </div>

          <div className="flex flex-col items-end">
            <Span>수거</Span>
            <Span>유리용기 : 0</Span>
          </div>
        </div>
      </div>
      <div className="flex h-[43.33vh] w-full flex-col overflow-auto rounded-[8px] border border-solid border-custom-mediumGray px-[1.6vw] py-[1.85vh]">
        {modifiedOrderByDriver && modifiedOrderByDriver.length > 0 ? (
          modifiedOrderByDriver.map((item, index) => (
            <section
              key={index + item.driverName}
              className="flex w-full flex-col"
            >
              <div className="mb-[2.6vh] flex w-full items-center justify-between">
                <div className="flex items-center gap-x-4">
                  <ContentTitle isBlack>{item.driverName}</ContentTitle>
                  <Span>{item.driverMobile}</Span>
                </div>
                {/* <div className="flex items-center gap-x-[0.68vw]">
                  <Span>지급되는 박스 수량</Span>
                  <InputBase
                    onChange={(e) => {
                      onChangeTotalContainer(e, item);
                    }}
                    height={"4vh"}
                  />
                </div> */}
              </div>
              <div className="flex flex-col gap-y-[2.96vh]">
                {item.orders && item.orders.length > 0
                  ? item.orders.map((order, index, arr) => {
                      const isLast = arr.length - 1 === index;
                      return (
                        <>
                          <div
                            key={index + order.id}
                            className="flex w-full items-center justify-between"
                          >
                            <div className="flex items-center gap-x-[1vw]">
                              <Span>{order.userName}</Span>
                              <Badge
                                variant={
                                  order.orderType === "order" ||
                                  order.orderType === "manualOrder"
                                    ? "delivery"
                                    : "collecting"
                                }
                              />
                            </div>
                            <div className="flex items-center gap-x-[2.24vw]">
                              <Span>
                                {KOREAN_CONTAINER[order.containerType]}
                              </Span>
                              <Span>{order.quantity ?? 0}병</Span>
                            </div>
                          </div>
                          {isLast && (
                            <div className="mb-[2.6vh]">
                              <Separator />
                            </div>
                          )}
                        </>
                      );
                    })
                  : null}
              </div>
            </section>
          ))
        ) : (
          <div>호출 할 수 있는 기사가 없습니다.</div>
        )}
      </div>
      <div className="mt-[5.6vh] flex w-full justify-center gap-x-[0.68vw]">
        <Button
          title="배송 취소"
          buttoncolor="custom-gray"
          width={103}
          height={52}
          onClick={closeModal}
        />
        <Button
          title="배송 진행"
          buttoncolor="custom-mainYellow"
          width={219}
          height={52}
          onClick={onClickCallDrivers}
        />
      </div>
    </div>
  );
};

export default CallDriverModal;
