import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import shopsApi from "../../api/Shops";
import { DayOffType } from "../../types/ShopSettingTypes";

export const useSetDayOff = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: shopsApi.setDayOff,
    onError: (err) => {
      console.error("SetDayOff Error", err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["day_off"] });
    },
  });
};

export const useGetDayOff = () => {
  const [isDayOff, setIsDayOff] = useState(0);
  const { data, isLoading } = useQuery({
    queryFn: shopsApi.getDayOff,
    queryKey: ["day_off"],
  });

  useEffect(() => {
    if (!isLoading && data?.success) {
      setIsDayOff(data.data.day_off);
    }
  }, [data, isLoading]);

  return { isDayOff };
};
