import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { HandleType, HqContainer } from "../../types/HqTypes";
import Badge, { ColorType } from "../common/Badge";
import Span from "../span/Span";

const HQContainersPageComponentRow = ({
  container,
  index,
}: {
  container: HqContainer;
  index: number;
}) => {
  const [text, setText] = useState("");
  const [badgeColor, setBadeColor] = useState("");
  const [textColor, setTextColor] = useState("");

  useEffect(() => {
    const badgeStyle = (handleType: HandleType) => {
      let badgeColor = "";
      let textColor = "";
      let text = "";

      if (handleType === "new") {
        badgeColor = "custom-lightBlue";
        textColor = "custom-mainBlue";
        text = "신규";
      } else if (handleType === "complete") {
        badgeColor = "custom-lightGreen";
        textColor = "custom-mainGreen";
        text = "완료";
      } else if (handleType === "dispose") {
        badgeColor = "custom-lightRed";
        textColor = "custom-mainRed";
        text = "폐기";
      } else if (handleType === "repair") {
        badgeColor = "custom-lightYellow";
        textColor = "custom-mainYellow";
        text = "수리";
      }

      setText(text);
      setBadeColor(badgeColor);
      setTextColor(textColor);
    };

    badgeStyle(container.handle_type);
  }, []);

  return (
    <div className="grid w-full grid-cols-custom-hq-containers">
      <div>
        <Span type="20pxBoldBlack">{index}</Span>
      </div>
      <div>
        <Span type="14pxBoldBlack">
          {container.name === "glass" ? "유리" : "박스"}
        </Span>
      </div>
      <div>
        <Badge
          badgeColor={badgeColor as ColorType}
          textColor={textColor as ColorType}
        >
          {text}
        </Badge>
      </div>
      <div className="px-12">
        <Span type="16pxNormalGray">
          {container.material === "glass" ? "유리" : "플라스틱"}
        </Span>
      </div>
      <div>
        <Span type="16pxNormalGray">{container.detail}</Span>
      </div>
      <div>
        <Span type="16pxNormalGray">{container.quantity}</Span>
      </div>
      <div className="px-8">
        <Span type="16pxNormalGray">{container.remarks}</Span>
      </div>
      <div className="justify-self-end">
        <Span type="14pxNormalGray">
          등록일 : {dayjs(container.reg_date).format("YYYY-MM-DD")}
        </Span>
      </div>
    </div>
  );
};

export default HQContainersPageComponentRow;
