import { ChangeEvent, useState } from "react";
import { useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import { formatMobileString } from "../../lib/utils/FormatMobileString";
import { FlatOrderList, ShopOrder } from "../../types/OrderTypes";
import Button from "../button/Button";
import ContentTitle from "../common/ContentTitle";
import ModalContainer from "../common/ModalContainer";
import InputBase from "../input/InputBase";
import AlertModal from "../modal/AlertModal";
import Span from "../span/Span";

const SpecialNoteAndCheckOrderAddressModal = ({
  isSpecialNote,
  data,
}: {
  isSpecialNote: boolean;
  data?: FlatOrderList;
}) => {
  // Hooks
  const { closeModal, changeModal } = useModal();

  // Recoils
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  // States
  const [remarksTerm, setRemarksTerm] = useState(data?.remarks ?? "");

  // Functions
  const onChangeSpeicalNote = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setRemarksTerm(value);
  };

  const onClickUpdateRemarks = async () => {
    if (!data) return;
    if (remarksTerm === "") alert("특이사항을 입력해주세요");
    const status = await shopsApi.remarksDescriptionInput(
      remarksTerm,
      data.userId,
    );
    if (status !== 400) {
      refetchFunctions &&
        refetchFunctions.refetchShop &&
        refetchFunctions.refetchShop();
      changeModal({
        content: (
          <AlertModal alertType="check" message="특이사항이 입력되었습니다." />
        ),
      });
    } else {
      changeModal({
        content: <AlertModal alertType="denied" message="특이사항 입력 실패" />,
      });
    }
  };

  return (
    <ModalContainer width={550}>
      <ContentTitle hasMb isCenter>
        {isSpecialNote ? "특이사항" : "배송 주소 확인"}
      </ContentTitle>
      <div className="custom-border-container flex flex-col px-[1.1vw] py-[1.85vh]">
        <div className="flex items-center">
          <Span type="20pxBoldBlack" style={{ marginRight: "0.31vw" }}>
            {(data && data.userName) ?? ""}
          </Span>
          <Span type="14pxBoldGray">
            {(data && formatMobileString(data.mobile)) ?? ""}
          </Span>
        </div>
        <div className="mt-[1.85vh]">
          <Span type="14pxNormalBlack">
            {isSpecialNote
              ? "특이사항 입력란 입니다."
              : "고객이 입력한 정보 입니다."}
          </Span>
        </div>
        <form className="my-[5vh] w-full">
          {isSpecialNote ? (
            <InputBase
              placeholder={`한 고객 당 한 개만 입력 가능하며, 다음에도 보여집니다.`}
              fullWidth
              style={{ height: "4vh", width: "100%" }}
              onChange={onChangeSpeicalNote}
              value={remarksTerm}
            />
          ) : (
            <>
              <InputBase
                disabled
                placeholder={(data && data.address) ?? ""}
                fullWidth
                style={{ height: "4vh", width: "100%" }}
              />
              <InputBase
                disabled
                placeholder={(data && data.description) ?? ""}
                fullWidth
                style={{ height: "4vh", width: "100%", marginTop: "1.67vh" }}
              />
            </>
          )}
        </form>
      </div>

      <div className="mt-[3.52vh] flex w-full justify-center">
        <Button
          title="확인"
          style={{ width: 120, height: "5.6vh" }}
          onClick={isSpecialNote ? onClickUpdateRemarks : closeModal}
        />
      </div>
    </ModalContainer>
  );
};

export default SpecialNoteAndCheckOrderAddressModal;
