import React from "react";
import { HqBoxContainerData } from "../../../types/HqInAndOutPageTypes";
import ContentTitle from "../ContentTitle";

interface BoxContainerCardProps {
  type: "box" | "container" | "shopBox" | "shopContainer";

  data: HqBoxContainerData[] | undefined;
}

interface DetailColumnProps {
  title: string;
  value: string | number;
  fullWidth?: boolean;
}

const Seperator = () => {
  return <div className="h-[160px] w-[0.5px] bg-custom-mediumGray" />;
};

const DetailColumn = ({ title, value, fullWidth }: DetailColumnProps) => {
  return (
    <div className={`flex flex-col items-center justify-center gap-y-[60px]`}>
      <ContentTitle isCenter>{title}</ContentTitle>
      <ContentTitle isCenter>{value}</ContentTitle>
    </div>
  );
};

const HqBoxContainerCard = ({ type, data }: BoxContainerCardProps) => {
  const contentTitle = type === "box" ? "박스" : "용기";
  const boxData = data?.filter((item) => item.name === "box");
  const glassData = data?.filter((item) => item.name === "glass");

  return (
    <div className="flex h-[23.14vh] w-full flex-col rounded-[8px] border border-solid border-custom-mediumGray bg-white px-[40px] py-[2.3vh]">
      <ContentTitle>{contentTitle}</ContentTitle>

      <div className="flex justify-between">
        <DetailColumn
          title={"이송요청"}
          fullWidth
          value={
            type === "box" || type === "shopBox"
              ? boxData?.find((item) => item.status === "transferReady")
                ? boxData?.find((item) => item.status === "transferReady")
                    ?.quantity ?? 0
                : "0"
              : glassData?.find((item) => item.status === "transferReady")
              ? glassData?.find((item) => item.status === "transferReady")
                  ?.quantity ?? 0
              : "0"
          }
        />
        <Seperator />
        <DetailColumn
          title={"이송중"}
          value={
            type === "box" || type === "shopBox"
              ? boxData?.find((item) => item.status === "transferring")
                ? boxData?.find((item) => item.status === "transferring")
                    ?.quantity ?? 0
                : "0"
              : glassData?.find((item) => item.status === "transferring")
              ? glassData?.find((item) => item.status === "transferring")
                  ?.quantity ?? 0
              : "0"
          }
        />
        <Seperator />

        {type === "shopBox" || type === "shopContainer" ? (
          <React.Fragment>
            <DetailColumn
              title={"이송완료"}
              value={
                type.includes("box")
                  ? boxData?.find((item) => item.status === "transferred")
                    ? boxData?.find((item) => item.status === "transferred")
                        ?.quantity ?? 0
                    : "0"
                  : glassData?.find((item) => item.status === "transferred")
                  ? glassData?.find((item) => item.status === "transferred")
                      ?.quantity ?? 0
                  : "0"
              }
            />
            <Seperator />
          </React.Fragment>
        ) : null}

        <DetailColumn
          title={"반납요청"}
          value={
            type === "box" || type === "shopBox"
              ? boxData?.find((item) => item.status === "resendReady")
                ? boxData?.find((item) => item.status === "resendReady")
                    ?.quantity ?? 0
                : "0"
              : glassData?.find((item) => item.status === "resendReady")
              ? glassData?.find((item) => item.status === "resendReady")
                  ?.quantity ?? 0
              : "0"
          }
        />
        <Seperator />
        <DetailColumn
          title={"반납중"}
          value={
            type === "box" || type === "shopBox"
              ? boxData?.find((item) => item.status === "resending")
                ? boxData?.find((item) => item.status === "resending")
                    ?.quantity ?? 0
                : "0"
              : glassData?.find((item) => item.status === "resending")
              ? glassData?.find((item) => item.status === "resending")
                  ?.quantity ?? 0
              : "0"
          }
        />
        <Seperator />

        <DetailColumn
          title={"재고"}
          value={
            type === "box" || type === "shopBox"
              ? boxData?.find((item) => item.status === "inStock")
                ? boxData?.find((item) => item.status === "inStock")
                    ?.quantity ?? 0
                : "0"
              : glassData?.find((item) => item.status === "inStock")
              ? glassData?.find((item) => item.status === "inStock")
                  ?.quantity ?? 0
              : "0"
          }
        />
      </div>
    </div>
  );
};

export default HqBoxContainerCard;
