import { debounce } from "lodash";
import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import useShopEveryCustomerQuery from "../../lib/queries/UseShopEveryCustomerQuery";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import { formatMobileString } from "../../lib/utils/FormatMobileString";
import { UserProps } from "../../types/UserTypes";
import Button from "../button/Button";
import ContentTitle from "../common/ContentTitle";
import ModalContainer from "../common/ModalContainer";
import Separator from "../common/Separator";
import InputBase from "../input/InputBase";
import AlertModal from "../modal/AlertModal";
import Span from "../span/Span";

interface ExtendedUserProps extends UserProps {
  index: number;
}

interface AddManualOrderModalProps {
  shopOrderDate: string;
}

const AddManualOrderModal = ({ shopOrderDate }: AddManualOrderModalProps) => {
  // Hooks
  const [searchText, setSearchText] = useState("");
  const { allCustomerData, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useShopEveryCustomerQuery(searchText);
  const { changeModal, closeModal } = useModal();

  // Recoil
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  // States
  const [selectedCustomer, setSelectedCustomer] =
    useState<ExtendedUserProps | null>(null);
  const [price, setPrice] = useState("");

  // Refs
  const scrollableCustomerDivRef = useRef<HTMLDivElement>(null);

  // Functions
  const onChangePriceInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (Number.isNaN(Number(value))) {
      return;
    }
    setPrice(value);
  };

  const onClickAddManualOrder = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!price) {
      alert("금액을 입력해주세요");
      return;
    }
    if (!selectedCustomer) {
      alert("고객을 선택해주세요.");
      return;
    }

    const manualOrderStatus = await shopsApi.addManualOrder(
      selectedCustomer.userId,
      Number(price),
      shopOrderDate,
    );
    if (manualOrderStatus === 201) {
      refetchFunctions &&
        refetchFunctions.refetchShop &&
        refetchFunctions.refetchShop();
      changeModal({
        content: <AlertModal alertType="check" message="배송 수동 추가 완료" />,
      });
      console.log("배송 수동 추가 완료");
    }
  };

  const debounceChange = debounce((value: string) => {
    setSearchText(value);
  }, 700);

  const onChangeSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    debounceChange(value);
  };

  // Effects
  useEffect(() => {
    const scrollableCustomerDiv = scrollableCustomerDivRef.current;

    const checkScrollPosition = debounce(() => {
      if (!scrollableCustomerDiv) return;

      // scrollHeight 는 원글 처음부터 화면 바깥까지 포함한 글의 전체 높이
      // scrollTop 은 원글 처음부터 현재 화면에 표시되고 있는 글의 top 까지의 높이
      // clientHeight는 현재 화면에서 보이는 height
      const isNearBottom =
        scrollableCustomerDiv.scrollHeight - scrollableCustomerDiv.scrollTop <=
        scrollableCustomerDiv.clientHeight + 100;

      if (isNearBottom) {
        if (isFetchingNextPage || !hasNextPage) return;
        fetchNextPage();
      }
    }, 250);

    if (scrollableCustomerDiv) {
      scrollableCustomerDiv.addEventListener("scroll", checkScrollPosition);
    }

    return () => {
      if (scrollableCustomerDiv) {
        scrollableCustomerDiv.removeEventListener(
          "scroll",
          checkScrollPosition,
        );
      }
    };
  }, []);

  return (
    <ModalContainer width={550}>
      <ContentTitle isCenter>배송 수동 추가</ContentTitle>
      <form className="flex w-full flex-col">
        <InputBase
          title="검색"
          fullWidth
          searchIcon
          style={{ height: "4vh", width: "100%" }}
          onChange={onChangeSearchInput}
        />

        <div className="custom-border-container mt-[1.85vh] overflow-hidden">
          <div
            className="flex h-[34.53vh] w-full flex-col overflow-auto"
            ref={scrollableCustomerDivRef}
          >
            {/* map으로 돌릴 div */}
            {allCustomerData && allCustomerData.length > 0 ? (
              allCustomerData.map((user: UserProps, index, arr) => {
                if (!user) return null;
                // if (user.inUseApp === "YES") return null;
                return (
                  <div
                    key={index + Math.random()}
                    className={`flex w-full cursor-pointer flex-col px-[1.41vw] pt-4 ${
                      selectedCustomer?.index === index
                        ? "bg-custom-lightBlue"
                        : null
                    }`}
                    onClick={() => {
                      setSelectedCustomer((prev) => ({ ...user, index }));
                    }}
                  >
                    <section className="flex w-full items-center ">
                      <Span
                        type="20pxBoldBlack"
                        style={{ marginRight: "2.14vw" }}
                      >
                        {user.userName}
                      </Span>
                      <Span type="14pxBoldBlack">
                        {formatMobileString(user.mobile)}
                      </Span>
                    </section>
                    <section className="mt-[1.2vh]">
                      <Span type="16pxNormalGray">
                        {user.address} {user.addressDetail}
                      </Span>
                    </section>

                    <div className="mt-[2vh]">
                      {arr.length === index + 1 ? null : <Separator />}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex w-full grow items-center justify-center">
                <Span type="16pxBoldPureBlack">해당 고객이 없습니다.</Span>
              </div>
            )}
          </div>
        </div>
        <div className="mt-[1.85vh] flex w-full items-start justify-between">
          <Span type="16pxBoldPureBlack">금번 결제 금액 (원)</Span>
          <InputBase
            style={{ height: "4vh" }}
            additionalText="입력 시 고객의 예치금에서 차감 됩니다."
            value={price}
            onChange={onChangePriceInput}
          />
        </div>

        <div className="mt-[2.78vh] flex justify-center ">
          <Button
            title="닫기"
            buttoncolor="custom-gray"
            style={{ width: 103, height: "4vh", marginRight: "0.68vw" }}
            onClick={closeModal}
          />
          <Button
            title="배송 수동 추가"
            buttoncolor="custom-mainBlue"
            style={{ width: 219, height: "4vh", marginRight: "0.68vw" }}
            onClick={onClickAddManualOrder}
          />
        </div>
      </form>
    </ModalContainer>
  );
};

export default AddManualOrderModal;
