import { HandleType } from "../../types/HqTypes";

const badgeStyle = (handleType: HandleType) => {
  let badgeColor = "";
  let textColor = "";
  let text = "";

  if (handleType === "new") {
    badgeColor = "custom-lightBlue";
    textColor = "custom-mainBlue";
    text = "신규";
  } else if (handleType === "complete") {
    badgeColor = "custom-lightGreen";
    textColor = "custom-mainGreen";
    text = "완료";
  } else if (handleType === "dispose") {
    badgeColor = "custom-lightRed";
    textColor = "custom-mainRed";
    text = "폐기";
  } else if (handleType === "repair") {
    badgeColor = "custom-lightYellow";
    textColor = "custom-mainYellow";
    text = "수리";
  }

  return { text, textColor, badgeColor };
};

export default badgeStyle;
