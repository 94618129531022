import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import useShopSettingControl from "../../lib/hooks/UseShopSettingControl";
import { ShopSettingStateProps } from "../../lib/recoils/atoms/ShopSettingPage/ShopSettingAtom";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import Button from "../button/Button";
import FileInput from "../input/FileInput";
import ItemSlider from "../MenuPage/ItemSlider";
import AlertModal from "../modal/AlertModal";
import Span from "../span/Span";

const ImageSelection = () => {
  // Hooks
  const { openModal } = useModal();
  const { shopSettingState, shopInfo, setShopInfo } = useShopSettingControl();

  // States
  const INITIAL = [null, null, null, null, null, null, null, null, null, null];
  const [files, setFiles] = useState<File[]>([]);
  const [displayFiles, setDisplayFiles] = useState<(string | null)[]>(INITIAL);

  // Recoil
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  // Functions
  const onChangeFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files) {
      let notNullDisplayFilesLength = 0;

      if (displayFiles) {
        notNullDisplayFilesLength = displayFiles.filter(
          (file) => file !== null,
        ).length;
      }

      if (notNullDisplayFilesLength + files.length > 10) {
        openModal({
          content: (
            <AlertModal
              alertType="denied"
              message="최대 10장까지 등록할 수 있습니다."
            />
          ),
        });
        return;
      }

      const fileArray = Array.from(files);
      const displayFileUrls = fileArray.map((file) =>
        URL.createObjectURL(file),
      );

      console.log(
        "dasdasdsadasdasdasdasdasdasdasdsadasdasdsadsdasdsadasd",
        displayFileUrls,
      );

      let willNewDisplayFiles = [...displayFiles];

      displayFileUrls.forEach((tempDisplayUrl) => {
        const nullIndexOfDisplayFile = willNewDisplayFiles.findIndex(
          (item) => item === null,
        );

        willNewDisplayFiles[nullIndexOfDisplayFile] = tempDisplayUrl;
      });
      willNewDisplayFiles.map((file) => {
        console.log(file === "");
        return file === "" ? null : file;
      });
      console.log("willNewOne", willNewDisplayFiles);
      setDisplayFiles(willNewDisplayFiles);
      // 이 아이들은 presigned url로 업로드할 아이
      setFiles(fileArray);
    }
  };
  const checkBlob = (data: any) => {
    const imageKeys = [
      "img_url",
      "img_url2",
      "img_url3",
      "img_url4",
      "img_url5",
      "img_url6",
      "img_url7",
      "img_url8",
      "img_url9",
      "img_url10",
    ];

    for (const key of imageKeys) {
      const imageUrl = data[key];
      if (imageUrl && imageUrl.startsWith("blob")) {
        console.error(`Invalid image URL detected in ${key}`);
        return false;
      }
    }
    return true;
  };

  const submitShopSetting = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let mainMenuImageUrls;
    let willNewDisplayFiles = [...displayFiles];
    let logoUrl = shopSettingState.logo;

    const shopKey = `shop-${shopSettingState.shopId}`;
    // 로고 업로드
    if (logoUrl && typeof logoUrl === "object") {
      // object (File) 이면 input에서 바꾸 것이므로 업로드 진행

      const uploadLogoRes = await shopsApi.uploadFileToS3(shopKey, [
        shopSettingState.logo as File,
      ]);
      logoUrl = uploadLogoRes ? uploadLogoRes[0] : logoUrl;
    }

    try {
      if (files.length > 0) {
        let tryUploadImageCount = 0;

        /* 1. 이미지 업로드 */
        mainMenuImageUrls = await shopsApi.uploadFileToS3(shopKey, files);
        tryUploadImageCount += 1;

        /* 2. DB조회 */
        const shopData = await shopsApi.getShopInfo();
        const validationImage = checkBlob(shopData);

        /* 3. 이미지 blob여부 확인 후 재 업로드 */
        if (!validationImage) {
          mainMenuImageUrls = await shopsApi.uploadFileToS3(shopKey, files);
          tryUploadImageCount += 1;
        }

        /* 업로드에 3번 이상 실패한 경우 에러처리 */
        if (tryUploadImageCount > 3) {
          await shopsApi.uploadFileToS3(shopKey, files);
          throw new Error("Exceeding the image upload limit of 3 times");
        }
      }
    } catch (error) {
      /* 에러 발생시 sweetalert으로 업로드 실패 알려줌 */
      console.error("Error during image file upload:", error);
      return openModal({
        content: (
          <AlertModal
            alertType="warning"
            message="이미지 업로드 실패, 다시시도하세요"
          />
        ),
      });
    }

    if (mainMenuImageUrls) {
      // 현재 BLOB인 아이들을 찾아 AWS 버켓 이미지로 교체
      mainMenuImageUrls.forEach((awsUrl) => {
        const blobIndex = willNewDisplayFiles.findIndex(
          (displayFile) => displayFile !== null && displayFile.includes("blob"),
        );
        willNewDisplayFiles[blobIndex] = awsUrl;
      });
    }

    const shopSettingFormData: ShopSettingStateProps = {
      shopId: shopSettingState.shopId,
      name: shopSettingState.name,
      address: shopSettingState.address,
      addressDetail: shopSettingState.addressDetail,
      mobile: shopSettingState.mobile,
      owner: shopSettingState.owner,
      logo: logoUrl === null ? "" : logoUrl,
      logoUrl: shopSettingState.logoUrl ?? null,
      imgUrl: willNewDisplayFiles[0],
      imgUrl2: willNewDisplayFiles[1],
      imgUrl3: willNewDisplayFiles[2],
      imgUrl4: willNewDisplayFiles[3],
      imgUrl5: willNewDisplayFiles[4],
      imgUrl6: willNewDisplayFiles[5],
      imgUrl7: willNewDisplayFiles[6],
      imgUrl8: willNewDisplayFiles[7],
      imgUrl9: willNewDisplayFiles[8],
      imgUrl10: willNewDisplayFiles[9],
    };

    const updateRes = await shopsApi.updateShopInfo(shopSettingFormData);
    if (updateRes !== 400) {
      openModal({
        content: <AlertModal alertType="check" message="정보 변경 성공" />,
      });
      refetchFunctions &&
        refetchFunctions.refetchShopSetting &&
        refetchFunctions.refetchShopSetting();
    }

    console.log(updateRes);
  };

  // Effects
  useEffect(() => {
    if (shopSettingState) {
      // const realShopInfo = shopInfo[0];
      const realShopInfo = shopSettingState;
      console.log("realShopInfo", realShopInfo);
      const {
        address,
        addressDetail,
        mobile,
        name,
        owner,
        logo,
        shopId,
        logoUrl,
        ...images
      } = realShopInfo;
      console.log("images", images);
      const imagesArray = images
        ? Object.values(images)
        : [null, null, null, null, null];
      const notEmptyImagesArray = imagesArray.map((item) =>
        item === "" ? null : item,
      );
      setDisplayFiles(notEmptyImagesArray);
    }
  }, [shopSettingState, shopInfo]);

  useEffect(() => {
    console.log("displayFiles", displayFiles);
  }, [displayFiles]);

  useEffect(() => {
    console.log("shopSettingState", shopSettingState);
  }, [shopSettingState]);

  return (
    <div className="flex flex-col justify-between">
      <div>
        <Span type="14pxNormalBlack">이용자 앱 메인 이미지 (최대 10장)</Span>
        <div className="custom-border-container w-[15.625vw] p-6">
          <div className="mb-5">
            <FileInput
              placeholder="파일 검색"
              heightVh={4}
              id="fileInput"
              onChange={onChangeFileInput}
            />
          </div>
          {displayFiles ? (
            <ItemSlider
              files={files}
              displayFiles={Object.values(displayFiles)}
              setFiles={setFiles}
              setDisplayFiles={setDisplayFiles}
            />
          ) : null}
        </div>
      </div>
      <div className="flex w-full justify-center">
        <Button
          title="저장하기"
          style={{ width: "6.25vw", height: "4vh" }}
          onClick={submitShopSetting}
        />
      </div>
    </div>
  );
};

export default ImageSelection;
