export const pxToVh = (
  px: string | number,
  resultType: "number" | "string",
): string | number => {
  let givenPx;
  typeof px === "string" ? (givenPx = Number(px)) : (givenPx = px);
  const resultVh = (givenPx / 1080) * 100;
  const resultVhString = `${resultVh}vh`;
  if (resultType === "number") {
    return resultVh;
  } else {
    return resultVhString;
  }
};

export const pxToVw = (
  px: string | number,
  resultType: "number" | "string",
): string | number => {
  let givenPx;
  typeof px === "string" ? (givenPx = Number(px)) : (givenPx = px);
  const resultVw = (givenPx / 1920) * 100;
  const resultVwString = `${resultVw}vw`;
  if (resultType === "number") {
    return resultVw;
  } else {
    return resultVwString;
  }
};
