import { ChangeEvent, useEffect, useState } from "react";
import FilterButton from "../components/button/FilterButton";
import ContentTitle from "../components/common/ContentTitle";
import BoxContainerCard from "../components/InAndOutPage/BoxContainerCard";
import InAndOutPageContentContainer from "../components/InAndOutPage/InAndOutPageContentContainer";
import SearchInput from "../components/input/SearchInput";
import { ReactComponent as ChevronDoubleLeftIcon } from "../assets/svgs/DoubleChevronLeft.svg";
import { ReactComponent as ChevronDoubleRightIcon } from "../assets/svgs/DoubleChevronRight.svg";
import { ReactComponent as ChevronLeftIcon } from "../assets/svgs/ChevronLeft.svg";
import { ReactComponent as ChevronRightIcon } from "../assets/svgs/ChevronRight.svg";
import { useModal } from "../lib/hooks/UseModal";
import Span from "../components/span/Span";
import HQDatePickerComponent from "../components/InAndOutPage/HQDatePickerComponent";
import dayjs from "dayjs";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import hqApis from "../api/HqApis";
import HqBoxContainerCard from "../components/common/HqInAndOutPage/HqBoxContainerCard";
import { KOREAN_HQ_BOXCONTAINER_STATUS } from "../lib/Consts";
import { padStart } from "lodash";
import Pagination from "../components/pagination/Pagination";

type HQInAndOutFilterType =
  | ""
  | "transferDriverMatched"
  | "transferring"
  | "resendDriverMatched"
  | "resending"
  | "inStock";
export interface HQInAndOutFilter {
  startDate: Date | null;
  endDate: Date | null;
  filter: HQInAndOutFilterType;
  searchTerm: string;
}

const HQShopTransactionPage = () => {
  // Hooks
  const { openModal } = useModal();
  const [searchParams] = useSearchParams();
  const { shopId } = useParams<{ shopId: string }>();

  const { data: boxContainerData } = useQuery({
    queryKey: [searchParams.get("name"), "boxContainer"],
    queryFn: async () => hqApis.getBoxContainerStatus(Number(shopId)),
  });

  // States
  const [inAndOutFilter, setInAndOutFilter] = useState<HQInAndOutFilter>({
    startDate: dayjs().subtract(7, "day").toDate(),
    endDate: dayjs().add(1, "day").toDate(),
    filter: "",
    searchTerm: "",
  });

  const [currentListPage, setCurrentListPage] = useState(1);
  const [totalListPage, setTotalListPage] = useState(1);

  // Query
  const { data: transferList, isError } = useQuery({
    queryKey: [
      searchParams.get("name"),
      "transferList",
      inAndOutFilter.searchTerm,
      inAndOutFilter.filter,
      inAndOutFilter.startDate,
      inAndOutFilter.endDate,
      `page:${currentListPage}`,
    ],
    queryFn: async () =>
      hqApis.getHqTransferLIst(
        inAndOutFilter.filter,
        inAndOutFilter.searchTerm,
        inAndOutFilter.startDate,
        inAndOutFilter.endDate,
        10,
        currentListPage,
        Number(shopId),
      ),
  });

  // Functions
  const onClickFilter = (filter: HQInAndOutFilterType) => {
    const isAlreadySelected = inAndOutFilter.filter === filter;
    if (isAlreadySelected) {
      setInAndOutFilter((prev) => ({ ...prev, filter: "" }));
    } else {
      setInAndOutFilter((prev) => ({ ...prev, filter }));
    }
  };

  const onChangeSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInAndOutFilter((prev) => ({ ...prev, searchTerm: value }));
  };

  const onClickPage = (page: number) => {
    if (page <= 0) return null;
    else if (page > totalListPage) return null;
    setCurrentListPage(page);
  };

  // Effects
  useEffect(() => {
    console.log("params", searchParams.get("name"));
  }, [searchParams]);

  useEffect(() => {
    if (!transferList) return;
    console.log(transferList);
    if (
      transferList.data.totalCount &&
      transferList.data.totalCount.length > 0
    ) {
      const totalCount = transferList.data.totalCount[0].count ?? 0;
      if (!totalCount) setTotalListPage(1);
      else setTotalListPage(Math.ceil(totalCount / 10));
    }
  }, [transferList]);

  // Classes
  const spanClass = `text-[0.875rem] leading-[1.25rem] text-custom-black`;

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex items-start gap-x-[2.375rem]">
        <ContentTitle hasMb>
          {searchParams.get("name") ?? "OOO"} (매장명)
        </ContentTitle>
        <Span type="20pxBoldGray">거래 현황</Span>
      </div>
      <div className="relative mb-[4.62vh] flex w-full justify-between gap-x-[50px]">
        <HqBoxContainerCard type="shopBox" data={boxContainerData} />
        <HqBoxContainerCard type="shopContainer" data={boxContainerData} />
      </div>
      <InAndOutPageContentContainer>
        <section className="flex items-center justify-between">
          <section>
            <ContentTitle>운송내역</ContentTitle>
            <span className={spanClass}>
              매장을 선택하면 해당 매장의 거래 내역 페이지로 이동합니다.
            </span>
          </section>
          <section className="flex items-center">
            <HQDatePickerComponent
              placeholder="시작일"
              isStart
              inAndOutFilter={inAndOutFilter}
              setInAndOutFilter={setInAndOutFilter}
            />
            <span className="mx-[0.62vw] text-[1rem] leading-[1.25rem] text-custom-gray">
              ~
            </span>
            <HQDatePickerComponent
              placeholder="종료일"
              inAndOutFilter={inAndOutFilter}
              setInAndOutFilter={setInAndOutFilter}
            />
          </section>
          <section className="flex">
            <div className="mr-[0.5vw] flex items-center gap-x-[0.41vw]">
              <FilterButton
                title="이송요청"
                whereTo="inAndOut"
                isSelected={inAndOutFilter.filter.includes(
                  "transferDriverMatched",
                )}
                onClick={() => onClickFilter("transferDriverMatched")}
              />
              <FilterButton
                title="이송중"
                whereTo="inAndOut"
                isSelected={inAndOutFilter.filter.includes("transferring")}
                onClick={() => onClickFilter("transferring")}
              />
              <FilterButton
                title="반납요청"
                whereTo="inAndOut"
                isSelected={inAndOutFilter.filter.includes(
                  "resendDriverMatched",
                )}
                onClick={() => onClickFilter("resendDriverMatched")}
              />
              <FilterButton
                title="반납중"
                whereTo="inAndOut"
                isSelected={inAndOutFilter.filter.includes("resending")}
                onClick={() => onClickFilter("resending")}
              />
              <FilterButton
                title="재고입고"
                whereTo="inAndOut"
                isSelected={inAndOutFilter.filter.includes("inStock")}
                onClick={() => onClickFilter("inStock")}
              />
            </div>
            <SearchInput
              placeholder="고객,배송기사"
              whereTo="inAndOut"
              height={"4vh"}
              name="searchTerm"
              onChange={onChangeSearchInput}
            />
          </section>
        </section>

        <section className="relative box-border h-full rounded-[8px] border border-solid border-custom-mediumGray px-[1vw] py-[0.5vh] ">
          {/* Grid */}
          <div className="grid grid-cols-custom-hq-shop-transaction items-center gap-y-[0.85vh] whitespace-nowrap">
            {/* Header */}
            <div className={`${spanClass}`}>#</div>
            <div className={`text-center ${spanClass}`}>유형</div>
            <div className={`text-center ${spanClass}`}>일시</div>
            <div className={`text-center ${spanClass}`}>
              고객 (전화번호 뒷번호 4자리)
            </div>
            <div className={`text-center ${spanClass}`}>
              배송기사명 (차량번호)
            </div>
            <div className={`text-center ${spanClass}`}>비고</div>

            {/* Data */}
            {transferList ? (
              !isError &&
              transferList.data.hqTransferListData &&
              transferList.data.hqTransferListData.length > 0 ? (
                transferList.data.hqTransferListData.map((item, index) => (
                  <>
                    <div key={index + 1} className={`${spanClass}`}>
                      {index + 1}
                    </div>
                    <div key={index + 2} className={`${spanClass} text-center`}>
                      {KOREAN_HQ_BOXCONTAINER_STATUS[item.status]}
                    </div>
                    <div key={index + 3} className={`${spanClass} text-center`}>
                      {item.updated_at
                        ? dayjs(item.updated_at).format("YYYY-MM-DD")
                        : ""}
                    </div>
                    <div key={index + 4} className={`${spanClass} text-center`}>
                      {item?.shopMobile
                        ? padStart(item.shopMobile.slice(-4), 4, "0")
                        : "알 수 없음"}
                    </div>
                    <div key={index + 5} className={`${spanClass} text-center`}>
                      {item.driverName ?? "알 수 없음"}
                    </div>
                    <div key={index + 6} className={`${spanClass} text-center`}>
                      1
                    </div>
                  </>
                ))
              ) : null
            ) : (
              <div className="flex h-full w-full grow">내역이 없습니다.</div>
            )}
          </div>
        </section>
        {/* PageButton */}
        {totalListPage > 0 && (
          <Pagination
            totalPage={totalListPage}
            currentPage={currentListPage}
            onClickPage={onClickPage}
          />
        )}
        {/* <nav className="mt-[1.85vh] flex w-full items-center justify-center gap-x-10">
          <ChevronDoubleLeftIcon
            className="cursor-pointer"
            onClick={() => {
              setCurrentListPage(1);
            }}
          />
          <ChevronLeftIcon
            onClick={() => {
              const prevPage = currentListPage - 1;
              if (prevPage === 0) return;
              setCurrentListPage(prevPage);
            }}
          />
          <ul className="flex gap-x-10">
            {Array(totalListPage)
              .fill("")
              .map((item, index) => {
                return (
                  <li
                    key={index + Math.random()}
                    onClick={() => onClickPage(index + 1)}
                    className={`border-soild box-border flex h-[24px] w-[24px] shrink-0  cursor-pointer items-center justify-center rounded-[8px] border border-custom-mainYellow text-[1rem] font-medium leading-[1.25rem] text-custom-mainYellow ${
                      currentListPage === index + 1
                        ? "bg-custom-lightYellow"
                        : "bg-custom-white"
                    } `}
                  >
                    {index + 1}
                  </li>
                );
              })}
          </ul>
          <ChevronRightIcon
            className="cursor-pointer"
            onClick={() => {
              const nextPage = currentListPage + 1;
              if (nextPage <= totalListPage) {
                setCurrentListPage((prev) => prev + 1);
              } else {
                return;
              }
            }}
          />
          <ChevronDoubleRightIcon
            className="cursor-pointer"
            onClick={() => {
              setCurrentListPage(totalListPage);
            }}
          />
        </nav> */}
      </InAndOutPageContentContainer>
    </div>
  );
};

export default HQShopTransactionPage;
