import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import shopsApi from "../../api/Shops";

const useShopDriverQuery = (
  sort: "desc" | "asc",
  pageNum?: number,
  searchText?: string,
  checkDeliveryWaiting?: string,
) => {
  const pageNumber = pageNum ? pageNum : 1;
  const { data, isLoading, isFetched, isFetching, refetch } = useQuery({
    queryKey: [
      "shopDriverList",
      sort,
      pageNum,
      searchText,
      checkDeliveryWaiting,
    ],
    queryFn: async () =>
      shopsApi.getDriverList(
        pageNumber,
        sort,
        searchText,
        checkDeliveryWaiting,
      ),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    console.log(data);
  }, [data]);

  return {
    data,
    isLoading,
    isFetched,
    isFetching,
    refetch,
  };
};

export default useShopDriverQuery;
