import { HTMLAttributes, ReactNode } from "react";

interface ContentTitleProps extends HTMLAttributes<HTMLHeadingElement> {
  children: ReactNode;
  isCenter?: boolean;
  isBlack?: boolean;
  hasMb?: boolean;
}
const ContentTitle = ({
  children,
  isCenter,
  isBlack,
  hasMb,
  ...props
}: ContentTitleProps) => {
  return (
    <h1
      className={`text-[1.25rem] font-bold leading-[1.75rem]  ${
        isBlack ? `text-black` : "text-custom-black"
      } ${isCenter ? `self-center` : ""} ${hasMb ? "mb-[3.4vh]" : ""}`}
      {...props}
    >
      {children}
    </h1>
  );
};

export default ContentTitle;
