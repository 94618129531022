import { useQuery } from "@tanstack/react-query";
import shopsApi from "../../api/Shops";

// 입출고 현황에서 사용하는 쿼리
const useContainerAndBoxQuery = () => {
  // 박스 쿼리
  const { data: boxData } = useQuery({
    queryKey: ["boxStatusCount"],
    queryFn: shopsApi.getBoxStatusCount,
  });
  // 용기 쿼리
  const { data: containerData } = useQuery({
    queryKey: ["containerStatusCount"],
    queryFn: shopsApi.getContainerStatusCount,
  });

  return { boxData, containerData };
};

export default useContainerAndBoxQuery;
