import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const loginInfoAtom = atom({
  key: "loginInfo",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
