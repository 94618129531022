import React from "react";
import { HqBoxContainerData } from "../../types/HqInAndOutPageTypes";
import { InAndOutBoxContainerStatus } from "../../types/OrderTypes";
import ContentTitle from "../common/ContentTitle";
import Span from "../span/Span";

interface BoxContainerCardProps {
  type:
    | "box"
    | "container"
    | "hqBox"
    | "hqContainer"
    | "hqShopTransitBox"
    | "hqShopTransitContainer";
  data: InAndOutBoxContainerStatus | null | undefined;
}

interface DetailColumnProps {
  title: string;
  value: string | number;
  fullWidth?: boolean;
}

const Seperator = () => {
  return <div className="h-[160px] w-[0.5px] bg-custom-mediumGray" />;
};

const DetailColumn = ({ title, value, fullWidth }: DetailColumnProps) => {
  return (
    <div className={`flex flex-col items-center justify-center gap-y-[60px]`}>
      <ContentTitle isCenter>{title}</ContentTitle>
      <ContentTitle isCenter>{value}</ContentTitle>
    </div>
  );
};

const BoxContainerCard = ({ type, data }: BoxContainerCardProps) => {
  const isHq = type === "hqBox" || type === "hqContainer";
  const contentTitle = type === "box" || type === "hqBox" ? "박스" : "용기";

  return (
    <div className="flex h-[23.14vh] w-full flex-col rounded-[8px] border border-solid border-custom-mediumGray bg-white px-[40px] py-[2.3vh]">
      {type === "hqShopTransitBox" || type === "hqShopTransitContainer" ? (
        <div className="flex items-center gap-x-[0.75rem]">
          <ContentTitle isCenter>
            {type === "hqShopTransitBox" ? "박스" : "용기"}
          </ContentTitle>
          <Span type="20pxBoldGray"> 매장에 있는 잔여 수 : 30</Span>
        </div>
      ) : (
        <ContentTitle>{contentTitle}</ContentTitle>
      )}

      <div className="flex justify-between">
        <DetailColumn
          title={isHq ? "이송요청" : "배송준비"}
          fullWidth
          value={
            data
              ? String(data.deliveryPreparation).padStart(3, "0")
              : "알 수 없음"
          }
        />
        <Seperator />
        <DetailColumn
          title={isHq ? "이송중" : "배송중"}
          value={data ? String(data.delivering).padStart(3, "0") : "알 수 없음"}
        />
        <Seperator />
        <DetailColumn
          title={isHq ? "반납요청" : "배송완료"}
          value={data ? String(data.delivered).padStart(3, "0") : "알 수 없음"}
        />
        <Seperator />
        <DetailColumn
          title={isHq ? "반납중" : "수거준비"}
          value={
            data
              ? String(data.collectPreparation).padStart(3, "0")
              : "알 수 없음"
          }
        />
        <Seperator />
        {isHq ? null : (
          <React.Fragment>
            <DetailColumn
              title={isHq ? "반납중" : "수거중"}
              value={
                data ? String(data.collecting).padStart(3, "0") : "알 수 없음"
              }
            />
            <Seperator />
          </React.Fragment>
        )}

        <DetailColumn
          fullWidth
          title={isHq ? "재고" : "보관"}
          value={data ? String(data.collected).padStart(3, "0") : "알 수 없음"}
        />
      </div>
    </div>
  );
};

export default BoxContainerCard;
