import React from "react";
import { InputWithButtonProps } from "./InputTypes";

const InputWithButton = ({
  id,
  name,
  title,
  placeholder,
  onChange,
  width,
  inputType,
  buttonTitle,
  onButtonClick,
  value,
  heightVh,
  ...props
}: InputWithButtonProps) => {
  return (
    <div>
      <div className="box-border flex w-full flex-col items-stretch justify-center gap-x-[53px] gap-y-[1.25rem]">
        {title && (
          <label htmlFor={id} className="text-[1rem] font-bold leading-5">
            {title}
          </label>
        )}
        <div className="flex w-full items-center justify-between">
          <input
            id={id}
            name={name}
            disabled={name === "address"}
            placeholder={placeholder}
            type={inputType ? inputType : "text"}
            value={value}
            onChange={onChange}
            className={`h-[52px] min-w-[326px] rounded-lg border border-solid p-4  ${
              width ? `w-[${width}px]` : "w-auto"
            }`}
            style={{ height: heightVh ? heightVh : "auto" }}
            {...props}
          />
          <button
            className="box-border h-[36px] w-[76px] rounded-[1.25rem] bg-custom-gray px-[12px] py-2 text-[0.875rem] text-white"
            onClick={onButtonClick}
          >
            {buttonTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputWithButton;
