import { atom } from "recoil";

interface RefetchFunctions {
  refetchShop?: () => void;
  refetchShopMenu?: () => void;
  refetchFinishDriverCallDateTime?: () => void;
  refetchShopSetting?: () => void;
  refetchShopRequestAndRecall?: () => void;
  refetchHqShops?: () => void;
  refetchHqDrivers?: () => void;
  refetchHqContainers?: () => void;
  refetchFinishHqDriverCall?: () => void;
}

export const refetchFunctionsAtom = atom<RefetchFunctions | undefined>({
  key: "refetchFunctions",
  default: undefined,
});
