import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import { pxToVh, pxToVw } from "../../lib/utils/TransformPxToViewPort";
import { HqBoxContainerStatus } from "../../types/HqTypes";
import { ShopInfo } from "../../types/UserTypes";
import Button from "../button/Button";
import Badge from "../common/Badge";
import ContentTitle from "../common/ContentTitle";
import ModalContainer from "../common/ModalContainer";
import Separator from "../common/Separator";
import AlertModal from "../modal/AlertModal";
import Span from "../span/Span";

const FullDetailsModal = () => {
  // Hooks
  const { changeModal, closeModal } = useModal();
  // Query
  const { data, isError, isLoading } = useQuery({
    queryKey: ["shopRequestRecall"],
    queryFn: shopsApi.getRequestAndRecall,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  // States
  const [boxData, setBoxData] = useState<
    {
      id: number;
      driver_id: null | number;
      container_id: number;
      status: HqBoxContainerStatus;
      quantity: null | number;
      updated_at: string;
    }[]
  >([]);

  const [containerData, setContainerData] = useState<
    {
      id: number;
      driver_id: null | number;
      container_id: number;
      status: HqBoxContainerStatus;
      quantity: null | number;
      updated_at: string;
    }[]
  >([]);

  // Functions
  const onClickProceed = async () => {
    if (!data || data.length === 0) return;

    const { data: shopInfo } = await axios<{
      message: string;
      data: ShopInfo[];
    }>("/shops/shop-info");

    const shopId = shopInfo.data[0].id;
    if (!shopId) return;
    const containerHistoriesIds: number[] = [];
    data.forEach((item) => containerHistoriesIds.push(item.id));
    console.log(containerHistoriesIds, "and", shopId);
    const proceedStatus = await shopsApi.proceedRequestAndRecall(
      shopId,
      containerHistoriesIds,
    );
    if (proceedStatus !== 400) {
      changeModal({
        content: <AlertModal message="성공" alertType="check" />,
      });
      return;
    } else {
      alert("오류 발생");
      return;
    }
  };

  // Effects
  useEffect(() => {
    if (data && data.length > 0) {
      setBoxData(data.filter((item) => item.container_id === 1));
      setContainerData(data.filter((item) => item.container_id === 2));
    }
  }, [data]);

  return (
    <ModalContainer widthVw={pxToVw(512, "number")}>
      <ContentTitle isCenter>요청 및 반납</ContentTitle>
      <ContentTitle hasMb>전체 내역</ContentTitle>
      <div className="custom-border-container max-h-[43.33vh] w-full overflow-auto px-[0.83vw] py-[1.85vh]">
        <section className="flex w-full flex-col">
          <ContentTitle hasMb>박스</ContentTitle>
          {!isError && !isLoading && boxData.length > 0 ? (
            boxData.map((box, index, arr) => {
              console.log("boxbxobxobxoboxboo", box);
              return (
                <div
                  key={index + Math.random() + box.status}
                  className={`flex w-full flex-col gap-y-[2.96vh] ${
                    arr.length === index + 1 ? "" : "mb-3"
                  }`}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center gap-x-[1.35vw]">
                      <Badge
                        textColor={`${
                          box.status === "transferReady"
                            ? "custom-mainYellow"
                            : "custom-mainBlue"
                        }`}
                        badgeColor={`${
                          box.status === "transferReady"
                            ? "custom-lightYellow"
                            : "custom-lightBlue"
                        }`}
                      >
                        {box.status === "transferReady" ? "요청" : "반납"}
                      </Badge>
                      <Span type="14pxBoldBlack">Type A</Span>
                    </div>
                    <div className="flex justify-center gap-x-[3vw]">
                      <Span type="14pxBoldGray">세부항목</Span>
                      <Span type="14pxBoldBlack">{box.quantity ?? 0}개</Span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex w-full justify-center">내역이 없습니다.</div>
          )}

          <div className="my-[2.96vh]">
            <Separator />
          </div>
        </section>

        <section className="flex w-full flex-col">
          <ContentTitle hasMb>용기</ContentTitle>
          {!isError && !isLoading && containerData.length > 0 ? (
            containerData.map((container, index, arr) => {
              return (
                <div
                  key={index + Math.random() + container.status}
                  className={`flex w-full flex-col gap-y-[2.96vh]  ${
                    arr.length === index + 1 ? "" : "mb-3"
                  }`}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center gap-x-[1.35vw]">
                      <Badge
                        textColor={`${
                          container.status === "resendReady"
                            ? "custom-mainYellow"
                            : "custom-mainBlue"
                        }`}
                        badgeColor={`${
                          container.status === "resendReady"
                            ? "custom-lightYellow"
                            : "custom-lightBlue"
                        }`}
                      >
                        {container.status === "resendReady" ? "요청" : "반납"}
                      </Badge>
                      <Span type="14pxBoldBlack">Type A</Span>
                    </div>
                    <div className="flex items-center justify-center gap-x-[3vw]">
                      <Span type="14pxBoldGray">플라스틱</Span>
                      <Span type="14pxBoldBlack">{container.quantity}개</Span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex  w-full justify-center">내역이 없습니다.</div>
          )}
        </section>
      </div>

      <div className="mt-[7.4vh] flex w-full justify-center gap-2">
        <Button
          width={71}
          heightVh={pxToVh(52, "number")}
          buttoncolor="custom-gray"
          title="취소"
          onClick={closeModal}
        />
        <Button
          width={152}
          heightVh={pxToVh(52, "number")}
          buttoncolor="custom-mainYellow"
          title="요청 및 반납 진행"
          onClick={onClickProceed}
        />
      </div>
    </ModalContainer>
  );
};

export default FullDetailsModal;
