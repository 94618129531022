import { ChangeEvent, ReactNode, useEffect, useRef } from "react";
import Button from "../components/button/Button";
import FilterButton from "../components/button/FilterButton";
import ContentTitle from "../components/common/ContentTitle";
import MainContentContainer from "../components/common/MainContentContainer";
import InputBase from "../components/input/InputBase";
import DateClickComponent from "../components/ShopOrderPage/DateClickComponent";
import Span from "../components/span/Span";
import { pxToVh } from "../lib/utils/TransformPxToViewPort";
import { useModal } from "../lib/hooks/UseModal";
import AddManualOrderModal from "../components/ShopOrderPage/AddManualOrderModal";
import TotalOrderModal from "../components/ShopOrderPage/TotalOrderModal";
import useShopOrderControl from "../lib/hooks/UseShopOrderControl";
import useShopOrderQuery from "../lib/queries/UseShopOrderQuery";
import OrderRow from "../components/ShopOrderPage/OrderRow";
import useShopOrderDriverQuery from "../lib/queries/UseShopOrderDriverQuery";
import DriverList from "../components/ShopOrderPage/DriverList";
import { useRecoilState } from "recoil";
import { shopOrderAtom } from "../lib/recoils/atoms/ShopOrderPage/ShopOrderAtom";
import shopsApi from "../api/Shops";
import AlertModal from "../components/modal/AlertModal";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import CallDriverModal from "../components/InAndOutPage/CallDriverModal";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { getTimePassedForSameDay } from "../lib/utils/GetIsTimePassed";
import useCsv from "../lib/hooks/UseCsv";
import { useGetDayOff, useSetDayOff } from "../lib/queries/UseShopDayOffQuery";

type SortType = "regular" | "short";

const FixedHeader = ({ children }: { children: ReactNode }) => {
  return (
    <div className="sticky top-0 z-10 mb-[0.74vh] grid w-full grid-cols-custom-orderContainer items-center bg-white pt-[1.5vh]">
      {children}
    </div>
  );
};

const ShopOrderPage = () => {
  // Recoil
  const [
    { sortType, isToday, driverSearchTerm, selectedDate },
    setShopOrderStates,
  ] = useRecoilState(shopOrderAtom);

  // Query
  // 얘가 화면에 뿌려지는 데이터 엑셀 작업 얘로하면 될 듯
  const { flatData, allModifiedOrderData, fetchNextPage, isFetchingNextPage } =
    useShopOrderQuery(300, 1);
  const { data: closedTime, refetch: refetchClosedTime } = useQuery({
    queryKey: ["shopClosedDateTime"],
    queryFn: shopsApi.getClosedDateTime,
  });
  const {
    allDriverData,
    fetchNextPage: fetchNextDriver,
    isFetchingNextPage: isFechingNextDriver,
    hasNextPage: hasNextDriverPage,
  } = useShopOrderDriverQuery(driverSearchTerm, 10, 1);

  // Refs
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  // Hooks
  const { openModal, changeModal } = useModal();
  const { setSelectedDate, setIsToday, checkIsToday, shopOrderStates } =
    useShopOrderControl();

  const navigate = useNavigate();
  const { CSVLinkComponent, downloadCsv, convertCsvToXlsx, csvData } = useCsv(
    allModifiedOrderData,
    selectedDate,
  );
  const { mutate: setDayOffMutate } = useSetDayOff();
  const { isDayOff } = useGetDayOff();

  // Functions
  const onClickSortFilter = (sort: SortType) => {
    const isSame = sort === sortType;
    if (isSame) {
      setShopOrderStates((prev) => ({ ...prev, sortType: "" }));
    } else {
      setShopOrderStates((prev) => ({ ...prev, sortType: sort }));
    }
  };

  // 배송 수동 추가 모달 오픈
  const onClickAddManualOrder = () => {
    /* 원래 금일 주문이 마감되면 수동 주문 추가도 막았다가 풀어달라해서 주석처리 */
    // const disabled = getTimePassedForSameDay(
    //   closedTime ? closedTime[0].closed_datetime : null,
    // );

    // if (disabled) {
    //   openModal({
    //     content: (
    //       <AlertModal alertType="denied" message="이미 마감되었습니다." />
    //     ),
    //   });
    //   return;
    // }
    openModal({
      content: (
        <AddManualOrderModal
          shopOrderDate={dayjs(shopOrderStates.selectedDate).format(
            "YYYY-MM-DD",
          )}
        />
      ),
    });
  };

  const onClickTotalOrder = () => {
    openModal({
      content: <TotalOrderModal />,
    });
  };

  const finishTodayOrderRequest = async () => {
    const finishOrderStatus = await shopsApi.finishTodayOrder();
    if (finishOrderStatus === 200) {
      refetchClosedTime();
      changeModal({
        content: (
          <AlertModal
            alertType="check"
            message="마감하였습니다. 확인 버튼을 눌러 기사 호출로 이동합니다."
            onClickCheck={() => {
              navigate("/io");
              openModal({
                content: <CallDriverModal />,
              });
            }}
          />
        ),
      });
    } else {
      changeModal({
        content: (
          <AlertModal alertType="denied" message="마감에 실패했습니다." />
        ),
      });
    }
  };

  const onClickFinishTodayOrder = () => {
    // 주말에 클릭했을때
    if (dayjs().day() === 0 || dayjs().day() === 6) {
      openModal({
        content: (
          <AlertModal
            alertType="denied"
            message="주말에는 마감할 수 없습니다."
          />
        ),
      });
      return;
    }
    // 이미 마감되었을때
    const disabled = getTimePassedForSameDay(
      closedTime ? closedTime[0].closed_datetime : null,
    );
    // selectedDay가 오늘과 다른 날짜일때
    const isDifferentDay = dayjs(selectedDate).day() !== dayjs().day();
    if (isDifferentDay) {
      openModal({
        content: (
          <AlertModal alertType="denied" message="주문 일자를 확인해주세요." />
        ),
      });
      return;
    }
    if (disabled) {
      openModal({
        content: (
          <AlertModal
            alertType="denied"
            message="금일 주문이 이미 마감되었습니다."
          />
        ),
      });
      return;
    }

    if (
      allModifiedOrderData &&
      allDriverData.length > 0 &&
      !allModifiedOrderData.some((order) => order.driverId)
    ) {
      openModal({
        content: (
          <AlertModal alertType="denied" message="기사를 배정해주세요." />
        ),
      });
      return;
    }

    openModal({
      content: (
        <AlertModal
          alertType="warning"
          message="금일 주문을 마감할까요?"
          onClickWarning={finishTodayOrderRequest}
        />
      ),
    });
  };

  const debouncedChange = debounce((value: string) => {
    setShopOrderStates((prev) => ({
      ...prev,
      customerAndProductSearchTerm: value,
    }));
  }, 800);

  const onChangeCustomerAndDriverInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    debouncedChange(value);
  };

  const handleTempClosedTimeChange = async () => {
    const changeClosedResult = await shopsApi.setClosedTimeYesterday();
    if (changeClosedResult) {
      openModal({
        content: <AlertModal alertType="check" message="마감시간 변경 완료" />,
      });
    } else {
      openModal({
        content: <AlertModal alertType="denied" message="마감시간 변경 실패" />,
      });
    }
    refetchClosedTime();
    return;
  };

  const tempChangeCloseTime = async () => {
    if (!closedTime) {
      console.log("마감 시간 없음");
      return;
    }

    openModal({
      content: (
        <AlertModal
          alertType="warning"
          message="금일 마감을 취소하시겠습니까?"
          onClickWarning={handleTempClosedTimeChange}
        />
      ),
    });
  };
  const dayOff = () => {
    onToggleDayOff();
    openModal({
      content: (
        <AlertModal alertType="check" message="고객의 주문을 중단합니다." />
      ),
    });
  };
  const dayOffCancel = () => {
    onToggleDayOff();
    openModal({
      content: (
        <AlertModal
          alertType="check"
          message="고객의 주문을 받기 시작합니다."
        />
      ),
    });
  };

  const changeClosedTimeTomorrow = async () => {
    const changeClosedResult = await shopsApi.setClosedTimeTomorrow();
    if (changeClosedResult) {
      openModal({
        content: <AlertModal alertType="check" message="마감시간 변경 완료" />,
      });
    } else {
      openModal({
        content: <AlertModal alertType="denied" message="마감시간 변경 실패" />,
      });
    }
    refetchClosedTime();
    return;
  };

  const openClosedTimeTomorrowModal = async () => {
    openModal({
      content: (
        <AlertModal
          alertType="warning"
          message="내일의 주문을 마감하시겠습니까?"
          onClickWarning={changeClosedTimeTomorrow}
        />
      ),
    });
  };

  const onToggleDayOff = () => {
    setDayOffMutate();
  };

  // Effects
  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;

    const checkScrollPosition = () => {
      if (!scrollableDiv) return;

      const isNearBottom =
        scrollableDiv.scrollHeight - scrollableDiv.scrollTop <=
        scrollableDiv.clientHeight + 100;

      if (isNearBottom) {
        console.log("끝에 도달");
        fetchNextPage();
      }
    };

    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", checkScrollPosition);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, [fetchNextPage, isFetchingNextPage]);

  return (
    <div className="flex h-full w-full flex-col">
      <ContentTitle hasMb>주문</ContentTitle>
      <MainContentContainer>
        {/** Absolute position 버튼 영역 */}
        <div className="absolute -top-[1.67vh] right-0 flex -translate-y-full flex-col items-end gap-y-1">
          <div className="flex items-center gap-x-10 ">
            {closedTime && closedTime[0].closed_datetime ? (
              <div className="flex flex-col ">
                <Span type="16pxBoldPureBlack">
                  {`마지막 주문 마감 시간 : ${dayjs(
                    closedTime[0].closed_datetime,
                  ).format("YYYY-MM-DD HH:mm")}`}
                </Span>
                <Span type="16pxBoldPureBlack">
                  {`내일의 주문 마감 여부 : ${
                    closedTime[0].tomorrow_closed_datetime
                      ? "마감 됨"
                      : "마감 안됨"
                  }`}
                </Span>
              </div>
            ) : null}

            {/** 마감 시간 임시 조정 버튼 시작 */}
            {/* <div
              className="h- flex h-10 w-40  cursor-pointer items-center justify-center bg-transparent"
              onClick={() => tempChangeCloseTime()}
            /> */}
            {/** 마감 시간 임시 조정 버튼 끝 */}

            <Button
              title={isDayOff === 1 ? "휴가중" : "휴가"}
              width={120}
              heightVh={pxToVh(60, "number")}
              onClick={isDayOff ? dayOffCancel : dayOff}
              buttoncolor={
                isDayOff === 1 ? "custom-mainBlue" : "custom-mainRed"
              }
              disabled={!closedTime}
            />

            <Button
              title="초기화"
              width={120}
              heightVh={pxToVh(60, "number")}
              onClick={tempChangeCloseTime}
              buttoncolor="custom-mainRed"
              // 현재 시간이 주말이거나 dayjs().day() 0이면 일요일 6이면 토요일
              // 선택된 날이 당일이 아니거나
              // 주문 마감 시간이 당일이여도 마감 시간 이후인 경우
              disabled={!closedTime}
            />

            <Button
              title="금일 주문 마감"
              width={120}
              heightVh={pxToVh(60, "number")}
              onClick={onClickFinishTodayOrder}
              buttoncolor="custom-mainBlue"
              // 현재 시간이 주말이거나 dayjs().day() 0이면 일요일 6이면 토요일
              // 선택된 날이 당일이 아니거나
              // 주문 마감 시간이 당일이여도 마감 시간 이후인 경우
              disabled={
                dayjs().day() === 0 ||
                dayjs().day() === 6 ||
                dayjs(selectedDate).day() !== dayjs().day() ||
                getTimePassedForSameDay(
                  closedTime ? closedTime[0].closed_datetime : null,
                )
              }
            />

            <Button
              title="내일의 주문 마감"
              width={120}
              heightVh={pxToVh(60, "number")}
              onClick={openClosedTimeTomorrowModal}
              buttoncolor="custom-black"
              // 현재 시간이 주말이거나 dayjs().day() 0이면 일요일 6이면 토요일
              // 선택된 날이 당일이 아니거나
              // 주문 마감 시간이 당일이여도 마감 시간 이후인 경우
              disabled={!closedTime}
            />

            <Button
              title="전체 주문보기"
              width={120}
              heightVh={pxToVh(60, "number")}
              onClick={onClickTotalOrder}
            />
          </div>
          <Span type="12pxNormalGray">
            현황 업데이트를 누르면 새로운 주문정보가 보입니다.
          </Span>
        </div>

        {/** 하얀 영역 */}
        <div className="flex h-full w-full  ">
          <article className="mr-[1vw] flex  h-full w-[70vw] flex-col">
            <section className="flex h-[5vh] w-full">
              <div className="grow-0">
                <Span type="14pxNormalBlack">
                  모든 주문 내역 입니다. 검색을 통해서 확인이 가능 합니다.
                </Span>
              </div>
              <div className="flex grow flex-col items-center">
                <Button
                  title="배송 수동 추가"
                  buttoncolor="custom-mainBlue"
                  width={120}
                  heightVh={pxToVh(38, "number")}
                  onClick={onClickAddManualOrder}
                  /* 원래 금일 주문이 마감되면 수동 주문 추가도 막았다가 풀어달라해서 주석처리 */
                  // disabled={getTimePassedForSameDay(
                  //   closedTime ? closedTime[0].closed_datetime : null,
                  // )}
                />

                <Span type="12pxNormalGray">
                  고객이 앱을 사용하지 않고 전화해서 주문했을 경우 사용
                </Span>
              </div>
              <div className="flex grow justify-between">
                <div className="flex gap-x-2">
                  <FilterButton
                    whereTo="order"
                    title="정기배송"
                    onClick={() => onClickSortFilter("regular")}
                    isSelected={sortType === "regular"}
                  />
                  <FilterButton
                    whereTo="order"
                    title="단기배송"
                    onClick={() => onClickSortFilter("short")}
                    isSelected={sortType === "short"}
                  />
                </div>
                <div className="flex h-[4vh] cursor-pointer items-center justify-center rounded-lg border-[1px] border-solid px-2">
                  {/* <div className="hidden">{CSVLinkComponent}</div> */}
                  {/* <button
                    onClick={downloadCsv}
                    className="flex items-center gap-x-2"
                  >
                    <img
                      src={require("../assets/images/excel.png")}
                      alt="엑셀다운로드"
                      className="f-ull w-6"
                    />
                    <Span type="14pxNormalExcelGreen">엑셀 다운로드</Span>
                  </button> */}
                  <button
                    onClick={() => {
                      convertCsvToXlsx(csvData);
                    }}
                    className="flex items-center gap-x-2"
                  >
                    <img
                      src={require("../assets/images/excel.png")}
                      alt="엑셀다운로드"
                      className="f-ull w-6"
                    />
                    <Span type="14pxNormalExcelGreen">엑셀 다운로드</Span>
                  </button>
                </div>
                <InputBase
                  searchIcon
                  height={"4vh"}
                  placeholder="고객명,제품명"
                  name="customerAndProductSearchTerm"
                  onChange={onChangeCustomerAndDriverInput}
                />
              </div>
            </section>

            <section className="flex h-full w-full flex-col">
              <Span type="14pxNormalBlack">주문일자 확인</Span>
              <div className="custom-border-container relative flex h-[62.96vh]  w-full flex-col overflow-hidden ">
                <div
                  className="h-full w-full overflow-auto px-[0.31vw] pb-[1.85vh]"
                  ref={scrollableDivRef}
                >
                  <FixedHeader>
                    <DateClickComponent
                      isToday={isToday}
                      checkIsToday={checkIsToday}
                      setSelectedDate={setSelectedDate}
                      setIsToday={setIsToday}
                    />
                    <Span alignSelf="center" type="14pxBoldBlack">
                      금번 결제 금액
                    </Span>
                    {selectedDate ? (
                      dayjs(selectedDate).format("YYYY-MM-DD") ===
                      dayjs().format("YYYY-MM-DD") ? (
                        <Span alignSelf="center" type="14pxBoldBlack">
                          예치금
                        </Span>
                      ) : (
                        <div></div>
                      )
                    ) : null}

                    <Span alignSelf="center" type="14pxBoldBlack">
                      특이사항
                    </Span>
                    <Span type="14pxBoldBlack">기사배정</Span>
                    <div className="w-full pl-[0.94vw]">
                      <Span type="14pxBoldBlack">상품 주문 내역</Span>
                    </div>
                  </FixedHeader>
                  <div className="flex w-full flex-grow flex-col">
                    {flatData && flatData.length === 0 && (
                      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 items-center justify-center">
                        <Span type="16pxBoldBlack">
                          해당 날짜에 주문 정보가 없습니다.
                        </Span>
                      </div>
                    )}
                    {flatData &&
                      flatData.length > 0 &&
                      flatData.map((order, index) => {
                        return (
                          <div key={order.address + index}>
                            <OrderRow
                              data={order}
                              disabled={getTimePassedForSameDay(
                                closedTime
                                  ? closedTime[0].closed_datetime
                                  : null,
                              )}
                              closedTime={
                                closedTime ? closedTime[0].closed_datetime : ""
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
          </article>

          <DriverList
            orders={allModifiedOrderData}
            drivers={allDriverData}
            fetchNextPage={fetchNextDriver}
            isFechingNextPage={isFechingNextDriver}
            hasNextPage={hasNextDriverPage}
            disabled={getTimePassedForSameDay(
              closedTime ? closedTime[0].closed_datetime : null,
            )}
          />
        </div>
      </MainContentContainer>
    </div>
  );
};

export default ShopOrderPage;
