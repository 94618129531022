import dayjs from "dayjs";

export const getTimePassed = (date: string | undefined | null) => {
  if (!date) return false;
  const currentDate = dayjs(new Date());
  const targetDate = dayjs(date);

  // targetDate 가 현재 시점보다 이전이면 true 이후이면 false
  const isTimePassed = targetDate.isBefore(currentDate);
  return isTimePassed;
};

// 같은 요일(월~일)일 때만 지났는지 안 지났는지 비교
export const getTimePassedForSameDay = (date: string | undefined | null) => {
  if (!date) return false;

  const currentDate = dayjs(new Date());
  const targetDate = dayjs(date);

  // 요일이 같으면 그때 시간 전후 비교
  if (currentDate.day() === targetDate.day()) {
    const isTimePassed = targetDate.isBefore(currentDate);
    return isTimePassed;
  } else {
    return false;
  }
};
