import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loginInfoAtom } from "../../lib/recoils/atoms/LoginInfoAtom";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const loginInfo = useRecoilValue(loginInfoAtom);

  if (!loginInfo) return <Navigate to={"/signin"} />;
  return children;
};

export default ProtectedRoute;
