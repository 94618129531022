import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import { UserProps } from "../../types/UserTypes";
import Button from "../button/Button";
import ContentTitle from "../common/ContentTitle";
import Separator from "../common/Separator";
import InputBase from "../input/InputBase";
import AlertModal from "../modal/AlertModal";

export interface DepositFormDataProps {
  name: string;
  mobile: string;
  totalDeposit: number | "";
  remarks?: string;
}

const DepositModal = ({
  user,
  refetch,
}: {
  user: UserProps;
  refetch?: () => void;
}) => {
  // Hooks
  const { changeModal, closeModal } = useModal();
  // State
  const [depositFormData, setDepositFormData] = useState<DepositFormDataProps>({
    name: user.userName,
    mobile: user.mobile,
    totalDeposit: "",
    remarks: "",
  });

  // Functions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    e.preventDefault();
    setDepositFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDepositChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setDepositFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitDeposit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const { data, status } = await shopsApi.setUserDeposit(depositFormData);
    console.log(data, status);
    if (status === 201) {
      refetch && refetch();
      changeModal({
        content: (
          <AlertModal alertType="check" message="변경이 완료 되었습니다." />
        ),
      });
    }
  };

  useEffect(() => {
    console.log(depositFormData);
    console.log(user);
  }, [depositFormData, user]);

  return (
    <div className="z-20 box-border flex  w-[550px] flex-col rounded-[8px]  bg-white px-[48px] py-custom-modal-y">
      <ContentTitle isCenter>예치금 관리</ContentTitle>
      <form className="flex h-full w-full flex-col">
        <section className="flex w-full flex-col gap-y-[3.8vh]">
          <InputBase
            id="name"
            name="name"
            title="이름"
            placeholder="홍길동"
            value={depositFormData.name}
            onChange={handleOnChange}
            disabled
            style={{ width: "16.98vw", height: "4vh" }}
          />
          <InputBase
            id="mobile"
            name="mobile"
            title="휴대폰 번호"
            placeholder="01012345678"
            value={depositFormData.mobile}
            onChange={handleOnChange}
            disabled
            style={{ width: "16.98vw", height: "4vh" }}
          />
          <InputBase
            id="amount"
            name="totalDeposit"
            title="금번 입금액"
            fullWidth
            placeholder="금액을 입력해 주세요."
            additionalText="* 입금된 금액을 입력하여 주세요."
            inputType="number"
            value={depositFormData.totalDeposit}
            onChange={handleDepositChange}
            style={{ height: "4vh", width: "100%" }}
          />
          <InputBase
            id="remarks"
            name="remarks"
            title="비고"
            fullWidth
            placeholder="비고"
            inputType="string"
            value={depositFormData.remarks}
            onChange={handleDepositChange}
            style={{ height: "4vh", width: "100%", marginBottom: "3rem" }}
          />
        </section>
        <section className="flex flex-col gap-y-[3.8vh]">
          <div className="flex flex-col items-center justify-center gap-y-[18px]">
            <span className="text-[1rem] font-bold leading-[1.25rem] text-custom-black">
              예치금
            </span>
            <strong className="text-[1.5rem] font-bold leading-[2rem] text-custom-black">
              {`${
                user.totalDeposit === null
                  ? "0"
                  : Number(user.totalDeposit).toLocaleString("ko-KR")
              }원`}
            </strong>
          </div>

          <Separator />
          <div className="flex items-center justify-around">
            <Button
              title="취소"
              buttoncolor="custom-gray"
              width={103}
              height={52}
              onClick={closeModal}
            />
            <Button
              title="저장하기"
              buttoncolor="custom-mainYellow"
              width={219}
              height={52}
              onClick={submitDeposit}
            />
          </div>
        </section>
      </form>
    </div>
  );
};

export default DepositModal;
