import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import { DriverProps } from "../../types/UserTypes";
import Button from "../button/Button";
import ContentTitle from "../common/ContentTitle";
import Separator from "../common/Separator";
import InputBase from "../input/InputBase";
import InputWithButton from "../input/InputWithButton";
import AlertModal from "../modal/AlertModal";
import SelectDriver from "../select/SelectDriver";
import Span from "../span/Span";

interface ManageDriverModalProps {
  type: "register" | "modify" | "hqRegister" | "hqModify";
  driver?: DriverProps;
  refetch?: () => void;
}

const ManageDriverModal = ({
  type,
  driver,
  refetch,
}: ManageDriverModalProps) => {
  // Consts
  const selectOptions = [{ value: "company" }, { value: "personal" }];
  const INITIAL_MOBILE = driver ? driver.mobile ?? null : null;

  // Hooks
  const { closeModal, changeModal } = useModal();

  // States
  const [driverFormData, setDriverFormData] = useState<DriverProps>(
    driver
      ? driver
      : {
          driverId: 0,
          name: "",
          mobile: "",
          vehicleNum: "",
          vehicleModel: "",
          vehicleType: "",
          createdAt: "",
          delivery_status: "",
          totalCount: 0,
          delivery_waiting: "NO",
        },
  );
  const [isDuplicated, setIsDuplicated] = useState<boolean>();

  // Functions
  const onChangeInput = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;

    setDriverFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // 중복 확인
  const checkDuplicate = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (
      !driverFormData.name ||
      !driverFormData.mobile ||
      driverFormData.mobile.length < 10
    ) {
      alert("이름, 전화번호를 확인해주세요.");
      return;
    }
    const checkDuplicateDricerRes = await shopsApi.checkDuplicatedDriver(
      driverFormData.mobile,
    );
    if (checkDuplicateDricerRes === 400) {
      setIsDuplicated(true);
    } else {
      setIsDuplicated(false);
    }
  };

  // 기사 등록(생성)
  const registerDriver = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isDuplicated === null || isDuplicated === true) {
      console.log(isDuplicated);
      alert("이름과 휴대폰 번호를 확인해주세요.");
      return;
    }
    const {
      createdAt,
      driverId,
      delivery_status,
      totalCount,
      ...restDriverData
    } = driverFormData;
    const { name, mobile, vehicleModel, vehicleNum, vehicleType } =
      restDriverData;
    const { status } = await shopsApi.shopRegisterDriver(
      name,
      mobile,
      vehicleNum,
      vehicleModel,
      vehicleType,
    );
    if (status === 201) {
      refetch && refetch();
      changeModal({
        content: (
          <AlertModal alertType="check" message="등록이 완료 되었습니다." />
        ),
      });
    } else {
      alert("에러 발생");
    }
  };

  const onClickDeleteDriver = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { status } = await shopsApi.shopDeleteDriver(driverFormData.driverId);
    if (status === 200) {
      refetch && refetch();
      changeModal({
        content: (
          <AlertModal alertType="check" message="삭제가 완료 되었습니다." />
        ),
      });
    } else {
      alert("삭제 실패");
    }
  };

  const onClickUpdateDriver = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (INITIAL_MOBILE === driverFormData.mobile) {
      const { createdAt, totalCount, delivery_status, ...restDriverFormData } =
        driverFormData;

      const { data, status } = await shopsApi.updateDriverInfo({
        ...restDriverFormData,
      });
      console.log(data, status);
      if (status === 200) {
        refetch && refetch();
        changeModal({
          content: (
            <AlertModal alertType="check" message="변경이 완료 되었습니다." />
          ),
        });
      } else {
        alert("정보를 확인 해주세요");
      }
    } else {
      if (isDuplicated === null || isDuplicated === true) {
        alert("이름과 휴대폰 번호를 확인해주세요.");
        return;
      }
      const { createdAt, totalCount, delivery_status, ...restDriverFormData } =
        driverFormData;

      const { data, status } = await shopsApi.updateDriverInfo({
        ...restDriverFormData,
      });
      console.log(data, status);
      if (status === 200) {
        refetch && refetch();
        changeModal({
          content: (
            <AlertModal alertType="check" message="변경이 완료 되었습니다." />
          ),
        });
      } else {
        alert("정보를 확인 해주세요");
      }
    }
  };

  // 디버깅
  useEffect(() => {
    console.log("컴포넌트 처음", driverFormData);
    console.log(driverFormData);
  }, [driverFormData]);

  return (
    <div className="box-border] z-20 flex w-[550px] flex-col rounded-[8px] bg-white px-custom-modal-x py-custom-modal-y">
      <ContentTitle isCenter>
        {type === "modify" ? "기사 정보 수정" : "기사 등록"}
      </ContentTitle>
      <form className="flex w-full flex-col">
        <section className="flex flex-col gap-y-[3.8vh]">
          <InputBase
            id="name"
            name="name"
            title="이름"
            value={driverFormData.name}
            placeholder="이름을 입력하여 주세요."
            width={326}
            onChange={onChangeInput}
          />
          <div className="flex flex-col">
            <InputWithButton
              id="mobile"
              name="mobile"
              title="휴대폰 번호"
              placeholder="'-'없이 입력해 주세요."
              width={326}
              value={driverFormData.mobile}
              inputType="number"
              buttonTitle="중복확인"
              onChange={onChangeInput}
              onButtonClick={checkDuplicate}
            />
            {isDuplicated !== undefined ? (
              <div className="mt-3">
                {isDuplicated ? (
                  <Span textColor="custom-mainRed" type="14pxBoldMainRed">
                    이미 등록된 기사 입니다.
                  </Span>
                ) : (
                  <Span textColor="custom-mainYellow" type="14pxBoldMainYellow">
                    등록 가능한 기사 입니다.
                  </Span>
                )}
              </div>
            ) : null}
          </div>
          <div className="flex flex-col gap-y-[1.3vh]">
            <InputBase
              id="vehicleModel"
              name="vehicleModel"
              value={driverFormData.vehicleModel}
              title="차량 정보"
              placeholder="차종을 입력해 주세요."
              onChange={onChangeInput}
            />
            <InputBase
              id="vehicleNum"
              name="vehicleNum"
              placeholder="차량번호"
              value={driverFormData.vehicleNum}
              onChange={onChangeInput}
            />
            <SelectDriver
              fullWidth
              id="vehicleType"
              name="vehicleType"
              additionalText="* 회사차량은 매장의 차량을 의미합니다."
              placeholder="회사차량/개인차량"
              value={driverFormData.vehicleType}
              options={selectOptions}
              onChange={onChangeInput}
            />
          </div>
        </section>

        <section className="mb-[5.2vh] mt-[2vh]">
          <Separator />
        </section>
        <section className="flex items-center justify-center gap-x-[13px]">
          {type === "modify" && (
            <Button
              title="삭제"
              buttoncolor="custom-mainRed"
              width={103}
              height={52}
              onClick={onClickDeleteDriver}
            />
          )}
          <Button
            title="취소"
            buttoncolor="custom-gray"
            width={103}
            height={52}
            onClick={closeModal}
          />
          {type === "modify" ? (
            <Button
              title="저장하기"
              buttoncolor="custom-mainYellow"
              width={219}
              height={52}
              onClick={onClickUpdateDriver}
            />
          ) : (
            <Button
              title="저장하기"
              buttoncolor="custom-mainYellow"
              width={219}
              height={52}
              onClick={registerDriver}
            />
          )}
        </section>
      </form>
    </div>
  );
};

export default ManageDriverModal;
