import React from "react";

interface SignUpInputProps {
  value: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SignUpInput = ({ value, placeholder, onChange }: SignUpInputProps) => {
  return (
    <input
      className="box-border h-[64px] w-full border border-solid p-3"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default SignUpInput;
