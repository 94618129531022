import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { loginInfoAtom } from "../recoils/atoms/LoginInfoAtom";
import webStorage from "../utils/WebStorage";
import { useNavigate } from "react-router-dom";

const useAxiosInterceptor = () => {
  const setLoginInfo = useSetRecoilState(loginInfoAtom);
  const navigate = useNavigate();
  const [isSetupComplete, setIsSetupComplete] = useState(false);

  useEffect(() => {
    axios.defaults.baseURL =
      process.env.REACT_APP_PRODUCTION === "true"
        ? process.env.REACT_APP_BASE_URL
        : process.env.REACT_APP_BASE_URL_TEST;
    // process.env.REACT_APP_PRODUCTION === "true"
    //   ? process.env.REACT_APP_BASE_URL
    //   : process.env.REACT_APP_BASE_URL_TEST;

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<any, any>) => {
        const { response } = error;
        const originRequest = error.config as AxiosRequestConfig<any> & {
          sent: boolean;
        };

        // 액세스 토큰 만료 핸들링
        // 에러에서 가져온 response 객체의 상태값 확인
        if (response?.status === 403) {
          originRequest.sent = true;
          // 액세스 토큰 요청이 실패했을떄를 핸들링
          if (originRequest.url === "/auth/shop-refreshtoken") {
            localStorage.clear();
            axios.defaults.headers.common = {};
            setLoginInfo(false);
            navigate("/");
            return Promise.reject(error);
          }

          // grab refresh token
          const refreshToken = webStorage.getStorage("local", "refreshToken");
          if (refreshToken && originRequest.headers) {
            // fetch and dispatch new accessToken
            const { data } = await axios("/auth/shop-refreshtoken", {
              headers: { Authorization: `Bearer ${refreshToken}` },
            });
            const { accessToken } = data;
            setLoginInfo(true);
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            originRequest.headers.Authorization = `Bearer ${accessToken}`;

            return axios(originRequest);
          }
        }
      },
    );

    setIsSetupComplete(true);

    // const requestInterceptor = axios.interceptors.request.use(
    //   (config) => {
    //     const accessToken = webStorage.getStorage("local", "accessToken");
    //     if (!accessToken) {
    //       setLoginInfo(false);
    //       navigate("/");
    //       return Promise.reject(new Error("액세스 토큰 없음"));
    //     }

    //     config.headers.Authorization = `Bearer ${accessToken}`;
    //     return config;
    //   },
    //   (error) => {
    //     return Promise.reject(error);
    //   },
    // );

    return () => {
      axios.interceptors.response.eject(interceptor);
      // axios.interceptors.request.eject(requestInterceptor);
    };
  }, [setLoginInfo]);

  return isSetupComplete;
};

export default useAxiosInterceptor;
