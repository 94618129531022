import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import shopsApi from "../../api/Shops";

export interface OrderDriverProps {
  driverId: number;
  name: string;
  vehicleNum: string;
  deliveryWaiting: "YES" | "NO";
}

const useShopOrderDriverQuery = (
  sort: string,
  perPage: number,
  pageNum: number,
) => {
  // States
  const [allDriverData, setAllDriverData] = useState<OrderDriverProps[]>([]);

  // Query
  const { data, fetchNextPage, isFetching, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["shopOrderDriver", sort, perPage, pageNum],
      queryFn: async ({ pageParam = 1 }) =>
        shopsApi.getOrderDriverList(sort, perPage, pageParam),
      getNextPageParam: (lastPage, allPage) => {
        const totalCount = lastPage.totalCount;
        const totalPage = Math.ceil(totalCount / perPage);
        const nextPage = allPage.length + 1;

        return nextPage <= totalPage ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    });

  // Effects
  useEffect(() => {
    if (data) {
      const allDriverDataArrary = data.pages.flatMap((page) => page.data) || [];
      setAllDriverData(allDriverDataArrary);
    }
  }, [data]);

  return {
    allDriverData,
    data,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useShopOrderDriverQuery;
