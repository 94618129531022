import React from "react";
import { ReactComponent as ChevronDoubleLeftIcon } from "../../assets/svgs/DoubleChevronLeft.svg";
import { ReactComponent as ChevronDoubleRightIcon } from "../../assets/svgs/DoubleChevronRight.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/svgs/ChevronLeft.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/svgs/ChevronRight.svg";
import { createPagination } from "./Pagination.util";

interface PaginationProps {
  totalPage: number;
  currentPage: number;
  onClickPage: (num: number) => void;
}
export default function Pagination({
  totalPage,
  onClickPage,
  currentPage,
}: PaginationProps) {
  const pagination = createPagination({ totalPages: totalPage, currentPage });
  return (
    <div className="flex items-center justify-center gap-8">
      <ChevronDoubleLeftIcon
        className="cursor-pointer"
        onClick={() => {
          onClickPage(1);
        }}
      />
      <ChevronLeftIcon
        className="cursor-pointer"
        onClick={() => {
          onClickPage(currentPage - 1);
        }}
      />
      <ul className="flex gap-x-10">
        {pagination.map((index) => {
          return (
            <li
              key={index + Math.random()}
              onClick={() => onClickPage(index)}
              className={`box-border flex shrink-0 cursor-pointer items-center justify-center text-[1.2rem] font-medium leading-[1.25rem] text-custom-mainYellow ${
                currentPage === index ? "bg-custom-lightYellow" : "bg-white"
              } `}
            >
              {index}
            </li>
          );
        })}
      </ul>
      <ChevronRightIcon
        className="cursor-pointer"
        onClick={() => {
          const nextPage = currentPage + 1;
          if (nextPage <= totalPage) {
            onClickPage(currentPage + 1);
          } else {
            return;
          }
        }}
      />
      <ChevronDoubleRightIcon
        className="cursor-pointer"
        onClick={() => {
          onClickPage(totalPage);
        }}
      />
    </div>
  );
}
