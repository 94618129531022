import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import Button from "../../components/button/Button";
import PostCode from "../../components/modal/PostCode";
import RegisterInput from "../../components/SignUpPage/RegisterInput";
import authApis from "../../api/Auth";
import ModalContainer from "../common/ModalContainer";
import InputBase from "../input/InputBase";
import { useModal } from "../../lib/hooks/UseModal";
import AlertModal from "../modal/AlertModal";
import { useRecoilValue } from "recoil";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import { HqShop } from "../../types/HqTypes";
import hqApis from "../../api/HqApis";

interface RegisterShopModalProps {
  type: "register" | "modify";
  shopData?: HqShop;
}

export interface RegisterShopFormData {
  name: string;
  owner: string;
  address: string;
  addressDetail: string;
  mobile: string;
}

const RegisterShopModal = ({ type, shopData }: RegisterShopModalProps) => {
  // Consts
  const INITIAL_MOBILE = shopData ? shopData.mobile ?? null : null;
  // Hooks
  const { changeModal, closeModal } = useModal();
  // States
  const [registerShopFormData, setRegisterShopFormData] =
    useState<RegisterShopFormData>({
      name: shopData ? shopData.name : "",
      address: shopData ? shopData.address : "",
      addressDetail: shopData ? shopData.address2 : "",
      mobile: shopData ? shopData.mobile : "",
      owner: shopData ? shopData.owner : "",
    });
  const [showPostCode, setShowPostCode] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState<boolean | undefined>(
    undefined,
  );

  // Recoil
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  // Functions
  const handleInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let onlyNumsString: string;

    if (name === "mobile") {
      if (value.length > 11) return;
      onlyNumsString = value.replace(/[^0-9]/g, "");
    }
    setRegisterShopFormData((prev) => ({
      ...prev,
      [name]: name !== "mobile" ? value : onlyNumsString,
    }));
  };

  const handlePostCode = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPostCode(true);
  };

  const submitSignUp = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      const registerShopRes = await authApis.signUpShop(registerShopFormData);
      if (registerShopRes.status === 400) {
        alert("이미 존재하는 매장 입니다.");
        return;
      } else {
        changeModal({
          content: <AlertModal alertType="check" message="매장 등록 성공" />,
        });
        refetchFunctions &&
          refetchFunctions.refetchHqShops &&
          refetchFunctions.refetchHqShops();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClickDeleteShop = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!shopData) {
      alert("매장 정보가 없어 삭제할 수 없습니다.");
      return;
    }
    const deleteShopStatus = await hqApis.deleteShop(shopData.shopId);

    // TODO : 삭제 후 리패치

    if (deleteShopStatus === 400) {
      alert("매장 삭제에 실패했습니다.");
    } else {
      refetchFunctions &&
        refetchFunctions.refetchHqShops &&
        refetchFunctions.refetchHqShops();
      changeModal({
        content: <AlertModal alertType="check" message="매장 삭제 성공" />,
      });
    }
  };

  const checkDuplicate = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const checkDuplicatedShopRes = await hqApis.checkDuplicatedShop(
      registerShopFormData.mobile,
    );

    if (checkDuplicatedShopRes !== 400) {
      setIsDuplicated(false);
    } else {
      setIsDuplicated(true);
    }
  };

  const updateShopInfo = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { address, addressDetail, mobile, name, owner } =
      registerShopFormData;
    console.log(address, addressDetail, mobile, name, owner);
    if (!address || !addressDetail || !mobile || !name || !owner) {
      alert("입력을 확인해주세요");
      return;
    }
    if (!shopData) {
      alert("매장 정보가 없습니다.");
      return;
    }

    if (INITIAL_MOBILE === registerShopFormData.mobile) {
      const updateShopRes = await hqApis.updateShop(
        name,
        owner,
        mobile,
        address,
        addressDetail,
        shopData.shopId,
      );

      if (updateShopRes !== 400) {
        changeModal({
          content: <AlertModal alertType="check" message="수정 성공" />,
        });
      } else {
        alert("수정에 실패했습니다.");
      }
    } else {
      if (isDuplicated) {
        alert("연락처를 확인해주세요");
        return;
      }
    }
  };

  // 디버깅
  useEffect(() => {
    return () => {
      setIsDuplicated(undefined);
    };
  }, []);

  return (
    <ModalContainer>
      <h1 className="mb-[3.7vh] text-center text-[1.25rem] font-bold leading-[1.75rem]">
        매장 등록 관리
      </h1>
      <form className="flex w-full flex-col gap-y-[4.35vh]">
        <InputBase
          style={{ height: "4vh", width: "100%" }}
          title="매장명"
          name="name"
          placeholder="매장명을 입력해 주세요."
          value={registerShopFormData.name}
          onChange={handleInputValue}
          fullWidth
        />
        <InputBase
          style={{ height: "4vh", width: "100%" }}
          title="대표자명"
          name="owner"
          placeholder="성명"
          value={registerShopFormData.owner}
          onChange={handleInputValue}
          fullWidth
        />

        {type === "register" ? (
          <InputBase
            style={{ height: "4vh", width: "100%" }}
            title="매장 연락처"
            name="mobile"
            placeholder="전화번호"
            value={registerShopFormData.mobile}
            onChange={handleInputValue}
            fullWidth
          />
        ) : (
          <div className="relative flex w-full items-center">
            <RegisterInput
              title="매장 연락처"
              name="mobile"
              type="phoneNumber"
              onChange={handleInputValue}
              checkDuplicatedMobile={checkDuplicate}
              formData={registerShopFormData}
            />
            {isDuplicated !== undefined ? (
              <div className="absolute -bottom-8 right-0">
                {isDuplicated ? (
                  <span className="text-red-400">
                    연락처가 같은 매장이 있습니다.
                  </span>
                ) : (
                  <span className="text-custom-mainYellow">수정 가능</span>
                )}
              </div>
            ) : null}
          </div>
        )}

        <RegisterInput
          title="배송 받으실 주소"
          type="address"
          onPostCodeClick={handlePostCode}
          name="addressDetail"
          onChange={handleInputValue}
          formData={registerShopFormData}
        />
        {type === "modify" && (
          <div className="flex w-full items-center justify-center gap-x-[0.78vw]">
            <Button
              title="삭제"
              onClick={onClickDeleteShop}
              style={{ height: "4vh", width: "5.36vw" }}
              buttoncolor="custom-mainRed"
            />
            <Button
              title="취소"
              onClick={closeModal}
              style={{ height: "4vh", width: "5.36vw" }}
              buttoncolor="custom-gray"
            />
            <Button
              title="저장하기"
              onClick={updateShopInfo}
              style={{ height: "4vh", width: "11.467vw" }}
            />
          </div>
        )}
        {type === "register" && (
          <Button
            title="저장하기"
            onClick={submitSignUp}
            style={{ height: "4vh" }}
          />
        )}
      </form>
      {showPostCode && (
        <PostCode
          onClose={() => setShowPostCode(false)}
          setAddress={setRegisterShopFormData}
        />
      )}
    </ModalContainer>
  );
};

export default RegisterShopModal;
