import InputBase from "./InputBase";
import { InputBaseProps } from "./InputTypes";

const DiscountInput = ({
  name,
  value,
  onChange,
  inputType,
}: InputBaseProps) => {
  return (
    <div className="flex w-full flex-col">
      <label className="text-[1rem] font-bold leading-[1.25rem] text-black">
        할인율
      </label>
      <div className="mt-[1.25rem] flex items-center gap-x-[8px]">
        <InputBase
          placeholder="할인율 입력"
          name={name}
          value={value}
          onChange={onChange}
          inputType={inputType}
        />
        <span>%</span>
      </div>
    </div>
  );
};

export default DiscountInput;
