import React, { MouseEvent, ReactNode, useEffect } from "react";
import Button from "../button/Button";
import { useModal } from "../../lib/hooks/UseModal";
import ContentTitle from "../common/ContentTitle";
import RequestsAndRecallsModal from "./RequestsAndRecallsModal";

interface RecallsContainerProps {
  title: string;
  isBox?: boolean;
  children?: ReactNode;
  data: any[];
}

const RecallsContainer = ({ data, title, isBox }: RecallsContainerProps) => {
  // Hooks
  const { openModal } = useModal();

  // Functions
  const onClickRequestButton = (
    e: MouseEvent<HTMLButtonElement>,
    detail: string,
    containerId: number,
    currentRequestedQuantity?: number,
  ) => {
    e.preventDefault();
    openModal({
      content: (
        <RequestsAndRecallsModal
          isRequest={false}
          type={isBox ? "recallBox" : "recallContainer"}
          detail={detail}
          containerId={containerId}
          currentRequestedQuantity={currentRequestedQuantity}
        />
      ),
    });
  };

  // Effects
  useEffect(() => {
    console.log("recalls!!!!!", data);
  }, [data]);

  return (
    <div className="relative flex h-[38.42vh] w-full flex-col rounded-[8px] bg-white px-[1.5vw] py-[2.4vh]">
      <div className="w-full bg-white">
        <ContentTitle>{title}</ContentTitle>
      </div>
      <div
        className={`relative mt-[1.5vh] grid h-full w-full auto-rows-min grid-cols-custom-recallsContainer gap-y-[5vh]  overflow-auto pl-[1.98vw]`}
      >
        <section className="sticky top-0 col-span-full grid h-fit w-full grid-cols-custom-recallsContainer grid-rows-[2.96vh] bg-white">
          <div>
            <ContentTitle>유형</ContentTitle>
          </div>
          <div className="flex justify-center">
            <ContentTitle>보유 재고</ContentTitle>
          </div>
          <div className="flex justify-center">
            <ContentTitle>수거중</ContentTitle>
          </div>
          <div className="flex justify-center">
            <ContentTitle>신청 완료 수량</ContentTitle>
          </div>
        </section>

        {data && data.length > 0 ? (
          data.map((item, index) => {
            // 수거중
            const resending = item.data.filter(
              (each: any) => each.status === "resending",
            );
            //신청 완료 수량
            const requested = item.data.filter(
              (each: any) => each.status === "resendReady",
            );
            // 보유 재고
            const inStock = item.data.filter(
              (each: any) => each.status === "inStock",
            );

            const currentRequestedQuatity = requested
              ? requested.reduce((acc: number, cur: any) => {
                  acc += cur.quantity;
                  return acc;
                }, 0)
              : 0;

            // if (
            //   resending.length === 0 &&
            //   requested.length === 0 &&
            //   inStock.length === 0
            // )
            //   return (
            //     <div
            //       key={index + Math.random()}
            //       className="flex grow items-center justify-center"
            //     >
            //       요청이 없습니다.
            //     </div>
            //   );

            return (
              <React.Fragment key={index + Math.random()}>
                <div>
                  <ContentTitle>{item.name}</ContentTitle>
                </div>
                <div className="flex justify-center">
                  <ContentTitle>
                    {inStock
                      ? inStock.reduce((acc: number, cur: any) => {
                          acc += cur.quantity;
                          return acc;
                        }, 0)
                      : "0"}
                  </ContentTitle>
                </div>
                <div className="flex justify-center">
                  <ContentTitle>
                    {resending
                      ? resending.reduce((acc: number, cur: any) => {
                          acc += cur.quantity;
                          return acc;
                        }, 0)
                      : "0"}
                  </ContentTitle>
                </div>
                <div className="flex justify-center">
                  <ContentTitle>{currentRequestedQuatity ?? 0}</ContentTitle>
                </div>
                <div className="flex justify-end">
                  <Button
                    onClick={(e) =>
                      onClickRequestButton(
                        e,
                        item.name,
                        item.data[0].containerId,
                        currentRequestedQuatity,
                      )
                    }
                    title="회수 신청"
                    width={120}
                    heightVh={2.59}
                  />
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <div className="flex grow items-center justify-center">
            요청이 없습니다.
          </div>
        )}
      </div>
    </div>
  );
};

export default RecallsContainer;
