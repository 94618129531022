import { Link } from "react-router-dom";
import { ReactComponent as MainLogo } from "../../assets/svgs/MainLogo.svg";
import { ReactComponent as SettingIcon } from "../../assets/svgs/Settings.svg";

const Header = () => {
  return (
    <header className="fixed flex h-[60px] w-full min-w-custom-minWidth  items-center justify-between bg-white px-custom-header-nav-x shadow-[0_0_30px_0_rgba(0,0,0,0.05)]">
      <Link to={"/"}>
        <MainLogo width={60} height={60} />
      </Link>
      <Link to={"/shop/setting"}>
        <SettingIcon />
      </Link>
    </header>
  );
};

export default Header;
