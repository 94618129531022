import React, { SetStateAction } from "react";
import { Dispatch } from "react";
import { useRecoilState } from "recoil";
import {
  ChildType,
  shopMemuFormAtom,
} from "../../lib/recoils/atoms/ShopMenuPage/ShopMenuFormAtom";
import { CurrentStageIndex } from "../../pages/MenuPage";
import { ShopMenu } from "../../types/MenuType";

const ChildTypeList = ({
  filteredMenu,
  setCurrentStgeIndex,
  currentStageIndex,
}: {
  filteredMenu: ShopMenu[];
  currentStageIndex: CurrentStageIndex;
  setCurrentStgeIndex: Dispatch<SetStateAction<CurrentStageIndex>>;
}) => {
  // CONSTS
  const CHILD_TYPES = [
    "superEarly",
    "early",
    "middle",
    "lateMiddle",
    "late",
    "completion",
    "infant",
    "etc",
  ];
  // States
  const [shopMenuForm, setShopMenuForm] = useRecoilState(shopMemuFormAtom);

  // Functionsa
  const handleStage = () => {
    if (currentStageIndex > 2) {
      return;
    } else {
      setCurrentStgeIndex(2);
    }
  };
  console.log(filteredMenu);

  return (
    <>
      <div>단계</div>
      <div className="flex h-[64vh] w-full flex-col justify-between overflow-hidden rounded-[8px] border border-solid border-custom-gray">
        <div className="box-border  px-[22px] py-[2.4vh]">
          <span className="whitespace-pre-wrap text-[0.875rem] font-normal leading-[1.25rem] text-custom-black">
            아래의 시기 (단계) 를 누르시면 오른쪽에서 메뉴를 선택할 수 있습니다.
            아무 메뉴가 없으면 해당 카테고리는 비활성화 됩니다. 비활성화 된
            메뉴는 이용자 앱에서 보여지지 않습니다.
          </span>
        </div>

        <div className="flex h-full w-full flex-col overflow-auto rounded-lg">
          <ul className="flex w-full flex-col items-center">
            {CHILD_TYPES.map((type, index) => {
              const menuCount = filteredMenu.filter(
                (menu) => menu.childType === type,
              ).length;
              return (
                <li
                  key={type + index}
                  className={`flex h-[10vh] w-full cursor-pointer list-none items-center  justify-between px-8 ${
                    shopMenuForm.childType === type ? "bg-custom-lightBlue" : ""
                  }`}
                  onClick={() => {
                    handleStage();
                    setShopMenuForm((prev) => ({
                      ...prev,
                      childType: type as ChildType,
                    }));
                  }}
                >
                  <div className="flex items-center">
                    {type === "early" || type === "superEarly" ? (
                      <img
                        src={require("../../assets/images/early.png")}
                        alt="초기&초중기"
                        width={52}
                        height={52}
                      />
                    ) : null}

                    {type === "middle" || type === "lateMiddle" ? (
                      <img
                        src={require("../../assets/images/middle.png")}
                        alt="중기&중후기"
                        width={52}
                        height={52}
                      />
                    ) : null}
                    {type === "late" && (
                      <img
                        src={require("../../assets/images/late.png")}
                        alt="후기"
                        width={52}
                        height={52}
                      />
                    )}
                    {type === "completion" && (
                      <img
                        src={require("../../assets/images/completion.png")}
                        alt="완료기"
                        width={52}
                        height={52}
                      />
                    )}
                    {type === "infant" && (
                      <img
                        src={require("../../assets/images/infant.png")}
                        alt="완료기"
                        width={52}
                        height={52}
                      />
                    )}

                    <span className={`text-[1.5rem] font-bold leading-[2rem]`}>
                      {type === "superEarly" && "초기"}
                      {type === "early" && "초중기"}
                      {type === "middle" && "중기"}
                      {type === "lateMiddle" && "중후기"}
                      {type === "late" && "후기"}
                      {type === "completion" && "완료기"}
                      {type === "infant" && "유아식"}
                      {type === "etc" && "기타"}
                    </span>
                  </div>
                  <div>
                    <span>총 메뉴 : {menuCount}개</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ChildTypeList;
