import dayjs from "dayjs";
import { MouseEvent, ReactNode } from "react";
import { useModal } from "../../lib/hooks/UseModal";
import { formatMobileString } from "../../lib/utils/FormatMobileString";
import { HqDriver } from "../../types/HqTypes";
import ManageHqDriverModal from "./ManageHqDriverModal";

interface PageUserRowProps {
  data: HqDriver;
  refetch?: () => void;
  index: number;
}

const Title = ({
  children,
  isMobile,
}: {
  children: ReactNode;
  isMobile?: boolean;
}) => {
  return (
    <strong
      className={`font-bold  text-custom-black ${
        isMobile
          ? "text-[0.875rem] leading-[1.25rem]"
          : "text-[1.25rem] leading-[1.75rem]"
      } `}
    >
      {children}
    </strong>
  );
};

const StatusBadge = ({
  isAppRegisterd,
  canDeliver,
}: {
  isAppRegisterd?: boolean;
  canDeliver?: boolean;
}) => {
  return (
    <div
      className={` box-border flex h-[24px] items-center justify-center whitespace-nowrap px-[8px] py-[4px] text-[0.75rem] leading-[1rem] opacity-0 ${
        isAppRegisterd &&
        "text-custom rounded-[8px] bg-[#E8F2FE] text-custom-mainBlue opacity-100"
      } ${
        canDeliver &&
        "text-custom rounded-[8px] bg-[#E8F2FE] text-custom-mainBlue opacity-100"
      }`}
    >
      {isAppRegisterd && "앱 가입 완료"}
      {canDeliver && "배송 가능"}
    </div>
  );
};

const HQDriversPageComponentRow = ({
  refetch,
  data,
  index,
}: PageUserRowProps) => {
  // Hooks
  const { openModal } = useModal();

  // Functions
  const onClickDriver = (e: MouseEvent<HTMLLIElement>) => {
    openModal({
      content: <ManageHqDriverModal type="modify" driver={data} />,
    });
  };

  return (
    <li className="cursor-pointer list-none" onClick={onClickDriver}>
      <div className="grid w-full grid-cols-custom-driverRow items-center">
        <Title>{index}</Title>
        <Title>{data.name}</Title>
        <Title isMobile>{formatMobileString(data.mobile)}</Title>
        <span className="text-[1rem] leading-[1.5rem] text-custom-gray ">
          {data.vehicleModel}
        </span>
        <span className="text-[1rem] leading-[1.5rem] text-custom-gray ">
          {data.vehicleType}
        </span>
        <span className="text-[1rem] leading-[1.5rem] text-custom-gray ">
          {data.vehicleNum}
        </span>

        <span className="text-[0.875rem]  font-medium  leading-[1.25rem] text-custom-gray">
          {`등록일 : ${dayjs(data.createdAt).format("YYYY-MM-DD")} `}
        </span>
        <span className="text-[0.875rem]  font-medium leading-[1.25rem] text-custom-gray">
          {`총 발송 횟수 : ${String(data.totalCount).padStart(3, "0")}`}
        </span>

        <StatusBadge
          isAppRegisterd={false}
          canDeliver={data.delivery_waiting === "YES"}
        />
      </div>
    </li>
  );
};

export default HQDriversPageComponentRow;
