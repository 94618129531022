import { useRecoilState } from "recoil";
import { modalStateAtom } from "../recoils/atoms/ModalStateAtom";

interface ModalProps {
  content: JSX.Element;
}

export const useModal = () => {
  const [modalState, setModalState] = useRecoilState(modalStateAtom);

  const openModal = ({ content }: ModalProps) => {
    setModalState({
      isOpen: true,
      content,
    });
  };

  const closeModal = () => {
    setModalState({
      isOpen: false,
      content: <></>,
    });
  };

  const changeModal = ({ content }: ModalProps) => {
    setModalState((prev) => ({
      ...prev,
      content,
    }));
  };

  return { modalState, setModalState, openModal, closeModal, changeModal };
};
