import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import shopsApi from "../../api/Shops";
import { UserProps } from "../../types/UserTypes";

const useShopEveryCustomerQuery = (searchText: string) => {
  // Vars
  let pageNumber = 1;

  // Query
  const {
    data,
    isLoading,
    isFetched,
    isFetching,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["shopEveryCustomer", searchText],
    queryFn: async ({ pageParam = pageNumber }) =>
      shopsApi.getCustomerList(pageParam, "asc", searchText),
    getNextPageParam: (lastPage, allPage) => {
      const totalPage = Math.ceil(lastPage.totalCount / 10);
      const nextPage = allPage.length + 1;
      const hasNextPage = nextPage <= totalPage ? nextPage : undefined;
      return hasNextPage;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  // State
  const [allCustomerData, setAllCustomerData] = useState<UserProps[]>([]);

  // Effects
  useEffect(() => {
    if (!data) return;
    const allCustomer = data.pages.flatMap((page) => page.data);
    const inAppUseNoCustomers = allCustomer.filter(
      (customer: UserProps) => customer.inUseApp === "NO",
    );
    setAllCustomerData(allCustomer);
  }, [data]);

  return {
    allCustomerData,
    data,
    isFetched,
    isFetching,
    isLoading,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  };
};

export default useShopEveryCustomerQuery;
