import { atom } from "recoil";
import { SortType } from "../../../../types/OrderTypes";

interface ShopOrderAtomProps {
  selectedOrder: number[];
  selectedDriverId: number;
  customerAndProductSearchTerm: string;
  driverSearchTerm: string;
  sortType: SortType;
  selectedDate: Date | null;
  isToday: boolean;
  drivers: any[];
}

export const shopOrderAtom = atom<ShopOrderAtomProps>({
  key: "shopOrderAtom",
  default: {
    selectedOrder: [],
    selectedDriverId: 0,
    customerAndProductSearchTerm: "",
    driverSearchTerm: "",
    sortType: "",
    selectedDate: new Date(),
    isToday: true,
    // 기사 배정에 쓰는 order ID, driver ID 배열
    drivers: [],
  },
});
