import ContentTitle from "../common/ContentTitle";
import InputWithAddress from "../input/InputWithAddress";
import InputWithButton from "../input/InputWithButton";
import InputBase from "../input/InputBase";
import DiscountInput from "../input/DiscountInput";
import ContainerSelect from "../select/ContainerSelect";
import Button from "../button/Button";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import PostCode from "../modal/PostCode";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import AlertModal from "../modal/AlertModal";
import { UserProps } from "../../types/UserTypes";
import Span from "../span/Span";

interface ManageCustomerModalProps {
  type: "register" | "modify";
  user?: UserProps;
  refetch?: () => void;
}

const Separator = () => {
  return <div className="my-[4.6vh] h-[0.5px] w-full bg-[#f2f2f2]" />;
};

const ManageCustomerModal = ({
  type,
  refetch,
  user,
}: ManageCustomerModalProps) => {
  // Hooks
  const { changeModal, closeModal } = useModal();

  // Const
  const INITIAL_MOBILE = user ? user.mobile ?? null : null;

  // States
  const [userData, setUserData] = useState<UserProps>(
    user
      ? user
      : {
          userName: "",
          address: "",
          addressDetail: "",
          description: "",
          createdAt: "",
          inUseApp: "NO",
          totalDeposit: "",
          totalOrder: 0,
          mobile: "",
          containerType: "glass",
          discount: 0,
          userId: 0,
        },
  );
  const [showPostCode, setShowPostCode] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState<boolean | null>(null);

  // Functions
  const openPostCode = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPostCode(true);
  };

  const handlePostCodeAddress = (address: string) => {
    setUserData((prev) => ({
      ...prev,
      address,
    }));
  };

  const handleOnChangeInput = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value, type } = e.target;
    const parsedValue =
      type === "number" ? (name === "mobile" ? value : Number(value)) : value;

    setUserData((prev) => ({
      ...prev,
      [name]: parsedValue,
    }));
  };

  // 유저 중복 체크
  const handleMobileDuplicated = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!userData.userName || !userData.mobile || userData.mobile.length < 10) {
      alert("이름, 전화번호를 확인해주세요.");
      return;
    }

    const { status } = await shopsApi.checkDuplicatedUser(userData.mobile);
    if (status === 400) {
      setIsDuplicated(true);
    } else {
      setIsDuplicated(false);
    }
  };

  // 유저 등록(생성)
  const submitRegisterCustomer = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isDuplicated === null || isDuplicated === true) {
      console.log(isDuplicated);
      alert("이름과 휴대폰 번호를 확인해주세요.");
      return;
    }
    const {
      totalOrder,
      userId,
      inUseApp,
      createdAt,
      totalDeposit,
      ...restUserData
    } = userData;

    const {
      userName,
      address,
      addressDetail,
      mobile,
      containerType,
      discount,
      description,
    } = restUserData;

    const { status } = await shopsApi.registerUser(
      userName,
      address,
      addressDetail,
      mobile,
      containerType,
      discount,
      description,
    );

    if (status === 201) {
      refetch && refetch();
      changeModal({
        content: (
          <AlertModal alertType="check" message="등록이 완료 되었습니다." />
        ),
      });
    } else {
      alert("에러 발생");
    }
  };

  // 유저 삭제
  const onClickDeleteUser = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { status } = await shopsApi.shopDeleteCustomer(userData.userId);
    if (status === 200) {
      refetch && refetch();
      changeModal({
        content: (
          <AlertModal alertType="check" message="삭제가 완료 되었습니다." />
        ),
      });
    } else {
      alert("삭제에 실패했습니다.");
    }
  };

  // 유저 업데이트
  const onClickUpdateUser = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (INITIAL_MOBILE === userData.mobile) {
      const { totalOrder, inUseApp, createdAt, totalDeposit, ...restUserData } =
        userData;
      const {
        userName,
        mobile,
        address,
        addressDetail,
        containerType,
        discount,
        description,
        userId,
      } = restUserData;

      const { status } = await shopsApi.shopUpdateCustomer(
        userName,
        mobile,
        address,
        addressDetail,
        containerType,
        discount,
        description,
        userId,
      );

      if (status === 200) {
        refetch && refetch();
        changeModal({
          content: (
            <AlertModal alertType="check" message="변경이 완료 되었습니다." />
          ),
        });
      } else {
        alert("변경에 실패했습니다. 입력을 확인해주세요.");
      }
    } else {
      if (isDuplicated === null || isDuplicated === true) {
        alert("연락처 변경시 중복 인증이 필요합니다.");
        return;
      }

      const { totalOrder, inUseApp, createdAt, totalDeposit, ...restUserData } =
        userData;
      const {
        userName,
        mobile,
        address,
        addressDetail,
        containerType,
        discount,
        description,
        userId,
      } = restUserData;

      const { status } = await shopsApi.shopUpdateCustomer(
        userName,
        mobile,
        address,
        addressDetail,
        containerType,
        discount,
        description,
        userId,
      );

      if (status === 200) {
        refetch && refetch();
        changeModal({
          content: (
            <AlertModal alertType="check" message="변경이 완료 되었습니다." />
          ),
        });
      } else {
        alert("변경에 실패했습니다.");
      }
    }
  };

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  return (
    <div className="z-20 box-border flex w-[550px] flex-col justify-between rounded-[8px] bg-white px-custom-modal-x py-custom-modal-y">
      <ContentTitle isCenter>고객 등록 관리</ContentTitle>
      <form
        className="flex w-full flex-col"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="flex flex-col gap-y-[2vh]">
          <InputBase
            id="userName"
            title="이름"
            name="userName"
            value={userData.userName}
            placeholder="이름을 입력하여 주세요."
            width={326}
            onChange={handleOnChangeInput}
          />
          <div className="flex flex-col">
            <InputWithButton
              title="휴대폰 번호"
              name="mobile"
              value={userData.mobile}
              placeholder="'-'없이 입력하여 주세요."
              id="mobile"
              buttonTitle="중복확인"
              inputType="number"
              onChange={handleOnChangeInput}
              onButtonClick={handleMobileDuplicated}
            />
            {isDuplicated !== null && isDuplicated === false && (
              <div className="mt-3">
                <Span textColor="custom-mainYellow" type="14pxBoldMainYellow">
                  등록 가능한 고객 입니다.
                </Span>
              </div>
            )}
            {isDuplicated !== null && isDuplicated === true && (
              <div className="mt-3">
                <Span textColor="custom-mainRed" type="14pxBoldMainRed">
                  이미 등록된 고객 입니다.
                </Span>
              </div>
            )}
          </div>
          <InputWithAddress
            title="배송 받으실 주소"
            name="address"
            showFrontDoorPassword
            address={userData.address}
            addressDetail={userData.addressDetail}
            description={userData.description}
            onChange={handleOnChangeInput}
            onButtonClick={openPostCode}
            addressDetailInputName="addressDetail"
            descriptionInputName="description"
          />
          <div className="flex gap-x-[30px]">
            <DiscountInput
              name="discount"
              value={String(userData.discount)}
              onChange={handleOnChangeInput}
              inputType="number"
              onKeyDown={(e) => console.log(e.currentTarget)}
            />
            <ContainerSelect
              title="용기 선택"
              width={147}
              name="containerType"
              value={userData.containerType}
              onChange={handleOnChangeInput}
            />
          </div>
        </div>
        <Separator />
        <div className="flex items-center justify-around">
          <Button
            type="button"
            title="삭제"
            buttoncolor="custom-mainRed"
            height={52}
            width={103}
            onClick={onClickDeleteUser}
          />
          <Button
            type="button"
            title="취소"
            buttoncolor="custom-gray"
            width={103}
            height={52}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          />
          {type === "modify" ? (
            <Button
              title="저장하기"
              buttoncolor="custom-mainYellow"
              width={219}
              height={52}
              onClick={onClickUpdateUser}
              type="button"
            />
          ) : (
            <Button
              type="button"
              title="저장하기"
              buttoncolor="custom-mainYellow"
              width={219}
              height={52}
              onClick={submitRegisterCustomer}
            />
          )}
        </div>
      </form>
      {showPostCode && (
        <PostCode
          onClose={() => setShowPostCode(false)}
          handleOnComplete={handlePostCodeAddress}
        />
      )}
    </div>
  );
};

export default ManageCustomerModal;
