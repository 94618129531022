import { useQuery } from "@tanstack/react-query";
import { MouseEvent, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import shopsApi from "../api/Shops";
import Button from "../components/button/Button";
import ContentTitle from "../components/common/ContentTitle";
import FullDetailsModal from "../components/RequestAndRecallsPage/FullDetailsModal";
import RecallsContainer from "../components/RequestAndRecallsPage/RecallsContainer";
import RequestContainer from "../components/RequestAndRecallsPage/RequestContainer";
import { useModal } from "../lib/hooks/UseModal";
import { refetchFunctionsAtom } from "../lib/recoils/RefetchFunctionsAtom";

const RequestsAndRecallsPage = () => {
  // Hooks
  const { openModal } = useModal();

  // Query
  const { data, refetch } = useQuery({
    queryKey: ["boxContainerStatus"],
    queryFn: shopsApi.getContainerBoxStatus,
  });

  // State
  const [boxRequestData, setBoxRequestData] = useState<
    { name: string; data: any[] }[]
  >([]);
  const [containerRequestData, setContainerRequestData] = useState<
    { name: string; data: any[] }[]
  >([]);

  // Recoil
  const setRefetchFunctions = useSetRecoilState(refetchFunctionsAtom);

  // Functions
  const onClickOpenFullDetailModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openModal({
      content: <FullDetailsModal />,
    });
  };

  // Effects
  useEffect(() => {
    if (!data) return;
    const boxData = data.filter((item, index) => item.name === "box");
    const containerData = data.filter((item, index) => item.name !== "box");

    const groupedByDetailBox = boxData.reduce((acc, cur) => {
      if (!acc[cur.detail]) {
        acc[cur.detail] = [];
      }

      acc[cur.detail].push(cur);
      return acc;
    }, {} as any);

    const groupedByDetailContainer = containerData.reduce((acc, cur) => {
      if (!acc[cur.detail]) {
        acc[cur.detail] = [];
      }

      acc[cur.detail].push(cur);
      return acc;
    }, {} as any);

    setBoxRequestData(
      Object.keys(groupedByDetailBox).map((key) => ({
        name: key,
        data: groupedByDetailBox[key],
      })),
    );

    setContainerRequestData(
      Object.keys(groupedByDetailContainer).map((key) => ({
        name: key,
        data: groupedByDetailContainer[key],
      })),
    );
  }, [data]);

  useEffect(() => {
    setRefetchFunctions((prev) => ({
      ...prev,
      refetchShopRequestAndRecall: refetch,
    }));
  }, [refetch, setRefetchFunctions]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="mb-[2vh]">
        <ContentTitle>박스 및 용기 관리</ContentTitle>
      </div>
      <div className="relative grid h-full w-full grid-cols-2 grid-rows-2 gap-x-[2.6vw] gap-y-[4.1vh]">
        <div className="absolute -top-[2.3vh] right-0 flex -translate-y-full items-center gap-x-[2.08vw]">
          <Button
            onClick={onClickOpenFullDetailModal}
            title="요청 및 반납"
            width={120}
            heightVh={5.5}
            buttoncolor="custom-mainBlue"
          />
          <Button
            title="현황 업데이트"
            width={120}
            heightVh={5.5}
            buttoncolor="custom-mainYellow"
            onClick={() => refetch()}
          />
        </div>

        <RequestContainer isBox title="박스 요청" data={boxRequestData} />
        <RecallsContainer isBox title="박스 반납" data={boxRequestData} />
        <RequestContainer
          isBox={false}
          title="용기 요청"
          data={containerRequestData}
        />
        <RecallsContainer
          isBox={false}
          title="용기 반납"
          data={containerRequestData}
        />
      </div>
    </div>
  );
};

export default RequestsAndRecallsPage;
