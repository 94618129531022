interface CreatePaginationProps {
  totalPages: number;
  currentPage: number;
}
const SIZE = 5;

export const createPagination = ({
  totalPages,
  currentPage,
}: CreatePaginationProps) => {
  let start = Math.max(currentPage - Math.floor(SIZE / 2), 1);
  const end = Math.min(start + SIZE - 1, totalPages);

  // 마지막 페이지 윈도우가 넘어가지 않도록 조정
  if (end === totalPages) {
    start = Math.max(totalPages - SIZE + 1, 1);
  }

  // 페이지 범위가 `size`보다 작을 때 시작 페이지 조정
  if (end - start + 1 < SIZE) {
    start = Math.max(end - SIZE + 1, 1);
  }

  const pagination: number[] = [];
  for (let i = start; i <= end; i++) {
    pagination.push(i);
  }
  return pagination;
};
