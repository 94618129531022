import React, { ChangeEvent } from "react";
import { ReactComponent as UserIcon } from "../../assets/svgs/AuthUser.svg";
import { ReactComponent as LockIcon } from "../../assets/svgs/AuthLock.svg";
import Button from "../button/Button";
import Span from "../span/Span";
import formatTime from "../../lib/utils/FormatTime";

interface AuthInputProps {
  type:
    | "mobile"
    | "password"
    | "phoneNumber"
    | "authenticationNumber"
    | "newPassword"
    | "confirmNewPassword";
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onButtonClick?: () => void;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  timer?: number;
  placeholder?: string;
  name?: string;
}

const placeholders = {
  mobile: "아이디를 입력하여 주세요.",
  password: "비밀번호를 입력하여 주세요.",
  phoneNumber: "전화번호",
  authenticationNumber: "인증번호",
  newPassword: "새 비밀번호",
  confirmNewPassword: "비밀번호 확인",
};

const AuthInput = ({
  type,
  value,
  onChange,
  onButtonClick,
  required,
  minLength,
  maxLength,
  timer,
  placeholder,
  name,
}: AuthInputProps) => {
  return (
    <div className="box-border flex w-full space-x-5">
      <div className="relative flex w-full space-x-8 border-b border-solid border-gray-400 ">
        {type === "mobile" || type === "phoneNumber" ? (
          <UserIcon />
        ) : (
          <LockIcon />
        )}
        <input
          required={required}
          value={value}
          onChange={onChange}
          className="w-full text-xl font-semibold text-gray-400"
          placeholder={placeholder ?? placeholders[type]}
          name={name ? name : type === "phoneNumber" ? "mobile" : type}
          type={type === "password" ? "password" : "text"}
          minLength={minLength}
          maxLength={maxLength}
        />
        {type === "authenticationNumber" ? (
          <div className="">
            <Span type="14pxBoldMainRed">
              {timer === 180
                ? null
                : timer === 0
                ? "인증번호를 다시 받아주세요"
                : formatTime(timer!)}
            </Span>
          </div>
        ) : null}
      </div>
      {type === "phoneNumber" && (
        <Button
          width={188}
          height={54}
          title="인증번호 받기"
          onClick={onButtonClick}
        />
      )}
    </div>
  );
};

export default AuthInput;
