import { TransformedOrder } from "../../types/OrderTypes";
import { generateCsv } from "../utils/GenerateCsv";
import { CSVLink } from "react-csv";
import { useRef } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import * as XLSX from "xlsx";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Seoul");

const useCsv = (
  allModifiedOrderData: TransformedOrder[],
  date: Date | null,
) => {
  // CSV로 만들 데이터
  const csvData = generateCsv(allModifiedOrderData, date);

  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const downloadCsv = () => {
    if (csvLinkRef && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };

  const csvReport = {
    filename: `${dayjs().tz().format("YYYY-MM-DD")} 주문내역`,
    data: csvData,
    ref: csvLinkRef,
  };

  const convertCsvToXlsx = (csvData: (string | number | null)[][]) => {
    const headerStyle = {
      fill: {
        fgColor: { rgb: "FFFFAA00" }, // This sets the background color to orange, for example.
      },
      font: {
        color: { rgb: "FFFFFFFF" }, // This sets the font color to white.
        bold: true,
      },
    };

    const workbook = XLSX.utils.book_new();
    // csv to worksheet로 변환
    const worksheet = XLSX.utils.aoa_to_sheet(csvData);
    //workbook 에 위의 worksheet 추가
    for (let R = 0; R < csvData.length; R++) {
      for (let C = 0; C < csvData[R].length; C++) {
        const cellRef = XLSX.utils.encode_cell({ c: C, r: R });
        const cellValue = csvData[R][C];
        if (worksheet[cellRef] && (C === 7 || C >= 10)) {
          if (
            worksheet[cellRef] &&
            typeof cellValue === "string" &&
            !isNaN(parseFloat(cellValue))
          ) {
            const numericValue = parseFloat(cellValue);
            worksheet[cellRef].t = "n"; // 셀 타입을 숫자로 설정
            worksheet[cellRef].v = numericValue; // 셀 값도 숫자로 설정
          }
        }
      }
    }
    const getMaxWidths = (data: (string | number | null)[][]) => {
      let maxWidths: number[] = [];
      data.forEach((row) => {
        row.forEach((cell, i) => {
          const cellLength = cell ? cell.toString().length : 0;
          maxWidths[i] = Math.max(maxWidths[i] || 10, cellLength + 10);
        });
      });
      return maxWidths;
    };
    const maxWidths = getMaxWidths(csvData);
    worksheet["!cols"] = maxWidths.map((w) => ({ wch: w }));

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // workbook을 file로 변화
    XLSX.writeFile(
      workbook,
      `${dayjs(date).format("YYYY-MM-DD")} 주문내역.xlsx`,
    );
  };

  return {
    csvData,
    downloadCsv,
    CSVLinkComponent: <CSVLink {...csvReport} />,
    convertCsvToXlsx,
  };
};

export default useCsv;
