import { ReactNode } from "react";

interface MainContentContainerProps {
  children: ReactNode;
}

const MainContentContainer = ({ children }: MainContentContainerProps) => {
  // 하얀 아이 (고객 아래)
  return (
    <div className="relative box-border flex h-[78.24vh] w-full flex-col rounded-[8px] bg-white px-[2.08vw] py-[3.7vh]">
      {children}
    </div>
  );
};

export default MainContentContainer;
