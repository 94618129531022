import { ChangeEvent, MouseEvent } from "react";
import { RegisterShopFormData } from "../../pages/auth/RegisterShopPage";

interface RegisterInputProps {
  title: string;
  name: keyof RegisterShopFormData;
  placeholder?: string;
  width?: number;
  type?: "normal" | "phoneNumber" | "address";
  formData: RegisterShopFormData;
  onPostCodeClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  checkDuplicatedMobile?: (e: MouseEvent<HTMLButtonElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RegisterInput = ({
  title,
  width,
  placeholder,
  type,
  onPostCodeClick,
  checkDuplicatedMobile,
  name,
  onChange,
  formData,
}: RegisterInputProps) => {
  const value = formData[name];

  return (
    <div className="w-full">
      <h1 className="mb-[1.25rem] text-[1rem] font-bold leading-5">{title}</h1>
      <div className="box-border flex w-full flex-col items-stretch justify-center gap-x-[53px] gap-y-[14px]">
        <div className="flex w-full items-center justify-between">
          <input
            placeholder={
              type !== "address" && placeholder ? placeholder : "주소"
            }
            className={`mr-4 h-[4vh] min-w-[20.38rem] rounded-lg border border-solid p-4`}
            disabled={type === "address"}
            name={type !== "address" ? name : "address"}
            value={type !== "address" ? value : formData.address}
            onChange={type !== "address" ? onChange : undefined}
          />
          {type === "phoneNumber" && (
            <div className="">
              <button
                className="box-border h-[36px] w-[76px] rounded-[1.25rem] bg-custom-gray px-[12px] py-2 text-[0.875rem] text-white"
                onClick={checkDuplicatedMobile}
              >
                중복확인
              </button>
            </div>
          )}
          {type === "address" && (
            <button
              title="주소검색"
              className="box-border h-[36px] w-[76px] rounded-[1.25rem] bg-custom-gray px-[12px] py-2 text-[0.875rem] text-white"
              onClick={onPostCodeClick}
            >
              주소검색
            </button>
          )}
        </div>
        {type === "address" && (
          <input
            placeholder={"건물이름 동 호수"}
            className={`h-[4vh] min-w-[326px] rounded-lg border border-solid p-4 ${
              width ? `w-[${width}px]` : "w-auto"
            }`}
            name={name}
            onChange={onChange}
            value={value}
          />
        )}
      </div>
    </div>
  );
};

export default RegisterInput;
