import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import shopsApi from "../../api/Shops";
import { ModifiedOrderByDriver, OrderByDriver } from "../../types/OrderTypes";

const useAllDeliveryListsQuery = (date: string, isAdditional?: boolean) => {
  // Query
  const { data, isLoading, isFetched, isFetching, refetch } = useQuery({
    queryKey: ["allDeliveryLists", date],
    queryFn: async () => shopsApi.allDeliveryLists(date, isAdditional),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  // State
  const [modifiedOrderByDriver, setModifiedOrderByDriver] = useState<
    ModifiedOrderByDriver[]
  >([]);
  const [totalGeneralContainer, setTotalGeneralContainer] = useState<number>(0);
  const [totalGlassContainer, setTotalGlassContainer] = useState<number>(0);

  // Effects
  useEffect(() => {
    if (!data) return;

    const driverGroupKey = (item: OrderByDriver) =>
      `${item.driverName}_${item.driverMobile}`;
    const orderGroupKey = (item: OrderByDriver) =>
      `${item.userName}_${item.orderType}_${item.containerType}`;

    const resultMap: { [key: string]: ModifiedOrderByDriver } = {};

    data.forEach((item) => {
      const dKey = driverGroupKey(item);
      const oKey = orderGroupKey(item);

      if (!resultMap[dKey]) {
        resultMap[dKey] = {
          orderIds: [],
          driverName: item.driverName,
          driverMobile: item.driverMobile,
          orders: [],
        };
      }

      const existingOrder = resultMap[dKey].orders.find(
        (order) => orderGroupKey(order as OrderByDriver) === oKey,
      );
      if (existingOrder) {
        if (existingOrder.quantity !== null && item.quantity !== null) {
          existingOrder.quantity += item.quantity;
        } else if (existingOrder.quantity === null && item.quantity !== null) {
          existingOrder.quantity = item.quantity;
        } // If both are null, it remains null
      } else {
        resultMap[dKey].orders.push({
          id: item.id,
          userName: item.userName,
          containerType: item.containerType,
          orderType: item.orderType,
          quantity: item.quantity,
        });
      }

      resultMap[dKey].orderIds.push(item.id);
    });

    const result: ModifiedOrderByDriver[] = Object.values(resultMap);
    console.log(result);
    setModifiedOrderByDriver(result);
  }, [data]);

  // 총 개수 구하기
  useEffect(() => {
    if (!modifiedOrderByDriver) return;

    let generalTotal = 0;
    let glassTotal = 0;

    modifiedOrderByDriver.forEach((driver) => {
      driver.orders.forEach((order) => {
        console.log(order.containerType);
        if (order.containerType === "glass") {
          glassTotal += order.quantity ?? 0;
        } else {
          generalTotal += order.quantity ?? 0;
        }
      });
    });

    setTotalGeneralContainer(generalTotal);
    setTotalGlassContainer(glassTotal);
  }, [modifiedOrderByDriver]);

  return {
    data,
    isFetched,
    isFetching,
    isLoading,
    refetch,
    modifiedOrderByDriver,
    totalGeneralContainer,
    totalGlassContainer,
  };
};

export default useAllDeliveryListsQuery;
