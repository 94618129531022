import { atom } from "recoil";

export type SelectedDay = "" | "mon" | "tue" | "wed" | "thu" | "fri";
//  초기, 초중기 ,중기, 중후기, 후기, 완료기, 유아식
export type ChildType =
  | ""
  | "superEarly"
  | "early"
  | "middle"
  | "lateMiddle"
  | "late"
  | "completion"
  | "infant"
  | "etc";

interface ShopMenuFormAtomProps {
  name: string;
  foodAmount: number;
  price: number;
  childType: ChildType;
  week: SelectedDay;
}

export const shopMemuFormAtom = atom<ShopMenuFormAtomProps>({
  key: "shopMenuFormAtom",
  default: {
    name: "",
    foodAmount: 0,
    price: 0,
    childType: "",
    week: "",
  },
});
