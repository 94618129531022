import { atom } from "recoil";

export interface ShopSettingStateProps {
  shopId: number;
  name: string;
  owner: string;
  address: string;
  addressDetail: string;
  mobile: string;
  logo: string | File;
  logoUrl: string | null;
  imgUrl: string | null;
  imgUrl2: string | null;
  imgUrl3: string | null;
  imgUrl4: string | null;
  imgUrl5: string | null;
  imgUrl6: string | null;
  imgUrl7: string | null;
  imgUrl8: string | null;
  imgUrl9: string | null;
  imgUrl10: string | null;
}

export const shopSettingAtom = atom<ShopSettingStateProps>({
  key: "shopSettingAtom",
  default: {
    shopId: 0,
    name: "",
    owner: "",
    address: "",
    addressDetail: "",
    mobile: "",
    logo: "",
    logoUrl: "",
    imgUrl: "",
    imgUrl2: "",
    imgUrl3: "",
    imgUrl4: "",
    imgUrl5: "",
    imgUrl6: "",
    imgUrl7: "",
    imgUrl8: "",
    imgUrl9: "",
    imgUrl10: "",
  },
});
