import { MouseEvent } from "react";
import { WhereTo } from "../../types/ComponentSizeTypes";

interface FilterButtonProps {
  title: string;
  whereTo: WhereTo;
  isSelected?: boolean;
  width?: number;
  height?: number;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const widthClassList = {
  customerAndDriver: "w-[5.5vw]",
  inAndOut: "w-fit",
  order: "w-[3.54vw]",
  hqContainer: "w-fit",
};

const FilterButton = ({
  title,
  isSelected,
  onClick,
  whereTo,
}: FilterButtonProps) => {
  const widthClass = widthClassList[whereTo]
    ? widthClassList[whereTo]
    : "w-[80px]";
  const backgroundClass = isSelected ? `bg-custom-lightYellow` : "bg-white";
  const borderClass = isSelected
    ? "border-custom-mainYellow"
    : "border-custom-gray";
  const spanClass = isSelected ? `text-custom-mainYellow` : `text-custom-gray`;

  return (
    <div
      className={`box-border flex h-[2.96vh] cursor-pointer items-center justify-center rounded-[1.25rem] border border-solid px-[12px] py-[8px] ${backgroundClass} ${widthClass}   ${borderClass}`}
      onClick={onClick}
    >
      <span
        className={`whitespace-nowrap text-[0.75rem] leading-[1rem] ${spanClass}`}
      >
        {title}
      </span>
    </div>
  );
};

export default FilterButton;
