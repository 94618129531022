import { atom } from "recoil";
import { ShopInfo } from "../../../types/UserTypes";
import { recoilPersist } from "recoil-persist";

// const { persistAtom } = recoilPersist();

export const shopInfoAtom = atom<ShopInfo[] | undefined>({
  key: "shopInfo",
  default: undefined,
  // effects_UNSTABLE: [persistAtom],
});
