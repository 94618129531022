import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import { ReactComponent as CancelIcon } from "../../assets/svgs/Cancel.svg";

const ItemSlider = ({
  displayFiles,
  setFiles,
  files,
  setDisplayFiles,
}: {
  displayFiles: (string | null)[];
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  setDisplayFiles: Dispatch<SetStateAction<(string | null)[]>>;
}) => {
  // States
  const [currentIndx, setCurrentIndex] = useState(0);

  // Refs
  const sliderRef = useRef<Slider>(null);

  // Functions
  const goToNext = (e: MouseEvent<HTMLSpanElement>) =>
    sliderRef?.current?.slickNext();

  const goToPrev = (e: MouseEvent<HTMLSpanElement>) =>
    sliderRef?.current?.slickPrev();

  const beforeChange = (oldIndex: number, newIndex: number) => {
    setCurrentIndex(newIndex);
    console.log(oldIndex, newIndex);
  };

  const onClickRemoveImage = (index: number) => {
    const willNewFiles = [...files];
    const willNewDisplayFiles = [...displayFiles];
    // 서버에 올라간 파일들들의 개수
    const awsFileLenth = willNewDisplayFiles.filter(
      (file) => file?.includes("https://dameum"),
    ).length;
    // willNewFiles 에서 삭제될 File의 인덱스
    const indexOfFile = index - awsFileLenth;

    willNewFiles.splice(indexOfFile, 1);
    setFiles(willNewFiles);
    willNewDisplayFiles.splice(index, 1, null);
    setDisplayFiles(willNewDisplayFiles);
  };

  // Carousel Slider 세팅
  const sliderSettings = {
    accessibility: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: beforeChange,
  };

  useEffect(() => {
    console.log("인덱스", currentIndx);
    if (currentIndx === -1) setCurrentIndex(0);
  }, [currentIndx]);

  return (
    <div className="flex flex-col">
      {displayFiles.length > 0 && (
        <>
          <Slider {...sliderSettings} ref={sliderRef}>
            {displayFiles.map((item, index) => {
              if (!item) return null;

              return (
                <div
                  key={Math.random() + index}
                  className="relative h-[22.4vh] w-full overflow-hidden rounded-[8px] object-cover"
                >
                  <img
                    className="h-full w-full object-cover"
                    src={item}
                    alt="메뉴 사진"
                  />
                  <CancelIcon
                    className="absolute right-[1.3vh] top-[1.3vh]"
                    onClick={() => onClickRemoveImage(index)}
                  />
                </div>
              );
            })}
          </Slider>
          {displayFiles.every((file) => file === null) ? null : (
            <div className="flex items-center justify-center gap-x-[10px] text-[1rem] leading-[1.25rem] ">
              <span className="cursor-pointer" onClick={goToPrev}>
                &lt;
              </span>
              <span className="w-1/3 text-center">{`${currentIndx + 1} / ${
                displayFiles.filter((file) => file !== null).length
              }`}</span>
              <span className="cursor-pointer" onClick={goToNext}>
                &gt;
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ItemSlider;
