import axios, { isAxiosError } from "axios";
import dayjs from "dayjs";
import { HQInAndOutFilterType } from "../pages/HQInAndOutStatusPage";
import {
  HqBoxContainerRes,
  HqToShopRequestData,
  HqTransferListRes,
} from "../types/HqInAndOutPageTypes";
import {
  GetHqShopsRes,
  HqBoxContainerStatus,
  HqContainer,
  HqContainerStatusRes,
  HqDriver,
  ManageContainerState,
} from "../types/HqTypes";

const hqApis = {
  // 기사 등록
  signUpHqDriver: async (
    name: string,
    mobile: string,
    vehicleNum: string,
    vehicleModel: string,
    vehicleType: string,
  ) => {
    try {
      const { status } = await axios.post("/shops/driver-sign-up", {
        name,
        mobile,
        vehicleNum,
        vehicleModel,
        vehicleType,
      });
      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 기사 목록 조회
  getHqDriverList: async (
    pageNum: number,
    sort: "desc" | "asc",
    searchText?: string,
    perPage?: number | string,
  ) => {
    try {
      const { data } = await axios.get<{
        message: string;
        data: HqDriver[];
        totalCount: number;
      }>("/shops/hq-driver-list", {
        params: {
          sort,
          pageNum,
          perPage: perPage ?? 10,
          searchText,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      if (isAxiosError(error)) {
        console.log(error);
        const message = error.response?.data;
        if (message === "NOT EXIST DATA") {
          return {
            message: "NOT EXIST DATA",
            data: [],
            totalCount: 0,
          };
        }
      }
      // alert(error);
      return {
        message: "NOT EXIST DATA",
        data: [],
        totalCount: 0,
      };
    }
  },

  // 기사 업데이트
  updateHqDriver: async (
    name: string,
    mobile: string,
    vehicleNum: string,
    vehicleModel: string,
    vehicleType: string,
    driverId: number,
  ) => {
    try {
      const { status } = await axios.patch("/shops/hq-driver-update", {
        name,
        mobile,
        vehicleNum,
        vehicleModel,
        vehicleType,
        driverId,
      });

      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 기사 삭제
  deleteHqDriver: async (driverId: number) => {
    try {
      const { status } = await axios.patch("/shops/hq-driver-delete", {
        driverId,
      });
      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  getHqShopList: async (
    pageNum: number,
    sort: "desc" | "asc",
    searchText?: string,
  ) => {
    try {
      const { data } = await axios.get<GetHqShopsRes>("/shops/hq-shop-list?", {
        params: {
          sort,
          pageNum,
          perPage: 10,
          searchText,
        },
      });

      let willNewData: GetHqShopsRes = {
        message: data.message,
        data: [...data.data],
        totalCount: data.totalCount,
      };

      const onlyActiveShops = willNewData.data.filter(
        (shop) => shop.is_active === "YES" && shop.shopId !== 1,
      );

      if (onlyActiveShops.length !== 0) {
        willNewData = {
          message: data.message,
          data: onlyActiveShops,
          totalCount: data.totalCount - onlyActiveShops.length,
        };
      } else {
        return {
          message: "NOT EXIST DATA",
          data: [],
          totalCount: 0,
        };
      }

      return willNewData;
    } catch (error) {
      console.log(error);
      return {
        message: "NOT EXIST DATA",
        data: [],
        totalCount: 0,
      };
    }
  },

  // 매장 삭제
  deleteShop: async (shopId: number) => {
    try {
      const { status } = await axios.patch("/shops/hq-shop-delete", { shopId });
      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 매장 업데이트
  updateShop: async (
    name: string,
    owner: string,
    mobile: string,
    address: string,
    addressDetail: string,
    shopId: number,
  ) => {
    try {
      const { status } = await axios.patch("/shops/hq-shop-update", {
        name,
        owner,
        mobile,
        address,
        addressDetail,
        shopId,
      });

      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  checkDuplicatedShop: async (mobile: string) => {
    try {
      const { status } = await axios.post("/shops/hq-shop-duplicate-check", {
        mobile,
      });
      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 용기 조회
  getContainers: async (searchText: string) => {
    try {
      const { data } = await axios<{ message: string; data: HqContainer[] }>(
        "/shops/hq-container-list",
        { params: { searchText } },
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  // 용기 생성
  createHqContainer: async (form: ManageContainerState) => {
    try {
      const { status } = await axios.post("/shops/hq-container-status", {
        ...form,
      });
      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 박스 용기 관리
  getHqContainerStatus: async () => {
    try {
      const { data } = await axios<HqContainerStatusRes>(
        "/shops/hq-container-status-total",
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  // 입출고 현황 운송 내역
  getHqTransferLIst: async (
    status: HQInAndOutFilterType,
    searchText: string,
    startDate: Date | null,
    endDate: Date | null,
    perPage: number,
    pageNum: number,
    shopId: number,
  ) => {
    try {
      const plusedEndData = dayjs(endDate).add(1, "day").format("YYYY-MM-DD");
      const { data } = await axios<HqTransferListRes>(
        "/shops/hq-transfer-list",
        {
          params: {
            status,
            searchText,
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
            endDate: plusedEndData,
            perPage,
            pageNum,
            shopId,
          },
        },
      );
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  // 입출고 현황 현황
  getBoxContainerStatus: async (shopId: number) => {
    try {
      const { data } = await axios<HqBoxContainerRes>("/shops/hq-in-out-list", {
        params: { shopId },
      });
      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  // 입출고 현황 요청 및 반납 처리 모달
  getHqToShopRequestTotalList: async (
    status?: HqBoxContainerStatus,
    toBeStatus?: string,
    driverId?: string,
  ) => {
    try {
      const { data } = await axios<{
        data: HqToShopRequestData[];
      }>("/shops/hq-to-shop-request-total-list", {
        params: {
          status: status ?? "transferDriverMatched",
          toBeStatus: toBeStatus ?? "transferring",
          driverId: driverId ?? "",
        },
      });
      return data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  // 요청 및 반납 기사 배정
  assignDriverRequestAndRecall: async (
    containerHistoryIds: number[],
    driverId: number,
  ) => {
    try {
      const requestArray = containerHistoryIds.map((eachId) =>
        axios.patch("/shops/hq-driver-transfer-assign", {
          containerHistoriesId: eachId,
          driverId,
          status: "transferDriverMatched",
        }),
      );
      await Promise.all(requestArray);
      return 200;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },

  // 요청 및 반납 이송 진행
  proceedHqRequestAndRecall: async () => {
    try {
      const { status } = await axios.patch("/shops/hq-driver-transfer-proceed");
      return status;
    } catch (error) {
      console.log(error);
      return 400;
    }
  },
};

export default hqApis;
