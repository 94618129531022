import {
  Collection,
  Menu,
  ShopOrder,
  TransformedOrder,
} from "../../types/OrderTypes";
import { ChildType } from "../recoils/atoms/ShopMenuPage/ShopMenuFormAtom";

function transformData(data: ShopOrder[]): TransformedOrder[] {
  const groupedByUserId: { [key: number]: ShopOrder[] } = {};
  const manualOrders: ShopOrder[] = [];
  const transformed: TransformedOrder[] = [];

  // 1. Group by userId
  data.forEach((item) => {
    if (item && item.userId) {
      if (item.orderType === "manualOrder") {
        manualOrders.push(item);
      } else {
        if (!groupedByUserId[item.userId]) {
          groupedByUserId[item.userId] = [];
        }
        groupedByUserId[item.userId].push(item);
      }
    }
  });

  // 2. Process each group
  // Process manual orders separately
  manualOrders.forEach((item) => {
    const transformedItem: TransformedOrder = {
      ...item,
      menus: [], // Adjust as needed
      collections: [], // Adjust as needed
      orderIds: [item.orderId],
    };
    transformed.push(transformedItem);
  });

  // Process the orders grouped by userId
  for (const userId in groupedByUserId) {
    const items = groupedByUserId[Number(userId)];
    console.log("유저로 묶인 데이터", items);

    const collectionsMap: { [key: string]: Collection } = {};
    const orderIds: number[] = []; // Array to store the orderIds
    let menusArr: Menu[] = [];

    items.forEach((item) => {
      if (item.quantity) {
        // Extract menus
        const menuQuantities = item.quantity
          .split("menuQuantity:")
          .filter((splited) => splited !== "")
          .map((filteredSplit) => filteredSplit[0]);
        console.log("메뉴 쿠안티티", menuQuantities);

        const menuNames = item.quantity
          .split(",menuName:")
          .filter((_, index) => index !== 0)
          .map((filtered) => filtered.split(",")[0]);
        console.log("메뉴 네임즈", menuNames);

        const childTypes = item.quantity
          .split(",childType:")
          .filter((_, index) => index !== 0)
          .map((filtered) => filtered.split(",")[0]);
        console.log("차일드 타입스", childTypes);

        const foodAmounts = item.quantity
          .split(",foodAmount:")
          .filter((_, index) => index !== 0)
          .map((filtered) => filtered.split(",")[0]);
        console.log("푸드 어마운츠", foodAmounts);
        // 현재 isRegular를 받아올 방법 없어서 추후 사용 가능하게 주석 처리
        // const isRegular = item.quantity
        //   .split(",isRegular:")
        //   .filter((_, index) => index !== 0)
        //   .map((filtered) => filtered.split(",")[0]);
        // console.log("정기배송 여부", isRegular);

        // const menuName = item.quantity.split("menuName:")[1].split(",")[0];
        // const childType = item.quantity.split("childType:")[1].split(",")[0];
        // const quantity = parseInt(
        //   item.quantity.split("menuQuantity:")[1].split(",")[0],
        //   10,
        // );
        // const amount = parseInt(item.quantity.split("foodAmount:")[1], 10);

        const menus: Menu[] = menuNames.map((menuName, index) => ({
          menuName: menuName,
          childType: childTypes[index],
          amount: Number(foodAmounts[index]),
          quantity: Number(menuQuantities[index]),
          // 현재 isRegular를 받아올 방법 없어서 추후 사용 가능하게 주석 처리
          // isRegular: isRegular[index],
        }));

        console.log(menus);

        menusArr.push(...menus);
        // 기존 코드
        // menuNames.forEach((menuName, index) => {
        //   if (!menusMap[menuName]) {
        //     menusMap[menuName] = {
        //       menuName,
        //       amount: Number(foodAmounts[index]),
        //       childType: childTypes[index],
        //       quantity: Number(menuQuantities[index]),
        //     };
        //   }
        // });
        // console.log("메뉴 맵", menusMap);

        // menuNames.forEach((menuName, index) => {
        //   const obj: Menu = {
        //     menuName: menuName,
        //     amount: Number(foodAmounts[index]),
        //     childType: childTypes[index],
        //     quantity: Number(menuQuantities[index]),
        //   };
        //   menusArr.push(obj);
        // });
        // console.log(item.quantity);
        // console.log("userId", userId, menusArr);
      }

      if (item.collectQuantity && typeof item.collectQuantity === "string") {
        // Extract collections
        const collections = item.collectQuantity.split(",");
        collections.forEach((collection) => {
          const [type, qty] = collection.split(":");
          const collectionKey = type;
          if (!collectionsMap[collectionKey]) {
            collectionsMap[collectionKey] = {
              type,
              quantity: parseInt(qty, 10),
            };
          } else {
            collectionsMap[collectionKey].quantity += parseInt(qty, 10);
          }
        });
      }

      // Add orderId to the orderIds array
      orderIds.push(item.orderId);
    });

    // ChildType 별로 정렬하기 위한 맵
    const childTypePriority: { [key in ChildType]: number } = {
      "": 1,
      superEarly: 2,
      early: 3,
      middle: 4,
      lateMiddle: 5,
      late: 6,
      completion: 7,
      infant: 8,
      etc: 9,
    };

    const transformedItem: TransformedOrder = {
      ...items[0], // Keeping all original data fields
      // 메뉴 이름, 메뉴 amount별로 정렬
      menus: menusArr.sort((a, b) => {
        if (
          childTypePriority[a.childType as ChildType] <
          childTypePriority[b.childType as ChildType]
        )
          return -1;
        if (
          childTypePriority[a.childType as ChildType] >
          childTypePriority[b.childType as ChildType]
        )
          return 1;
        if (a.menuName < b.menuName) return -1;
        if (a.menuName > b.menuName) return 1;

        return a.amount - b.amount;
      }),
      collections: Object.values(collectionsMap),
      orderIds, // Add the collected orderIds
    };

    transformed.push(transformedItem);
  }

  console.log("결과 데이터", transformed);
  return transformed;
}

export default transformData;
