import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import hqApis from "../../api/HqApis";
import { HANDLE_TYPE } from "../../lib/Consts";
import { useModal } from "../../lib/hooks/UseModal";
import badgeStyle from "../../lib/utils/GetHqContainerBadgeName";
import { HqContainerStatus } from "../../types/HqTypes";
import Button from "../button/Button";
import Badge, { ColorType } from "../common/Badge";
import ContentTitle from "../common/ContentTitle";
import ModalContainer from "../common/ModalContainer";
import Separator from "../common/Separator";
import Span from "../span/Span";

const HqContainerStatusModal = () => {
  // Hooks
  const { closeModal } = useModal();
  // Query
  const { data: hqContainerStatus, isLoading } = useQuery({
    queryKey: ["hqCotainerStatus"],
    queryFn: hqApis.getHqContainerStatus,
  });

  // States
  const [boxStatus, setBoxStatus] = useState<HqContainerStatus[]>();
  const [containerStatus, setContainerStatus] = useState<HqContainerStatus[]>();
  const [availableGlassQuantity, setAvailableGlassQuantity] = useState(0);
  const [availableBoxQuantity, setAvailableBoxQuantity] = useState(0);

  // Effects
  // 데이터 가공
  useEffect(() => {
    if (hqContainerStatus && hqContainerStatus.length > 0) {
      const boxItems = hqContainerStatus.filter((item) => item.name === "box");
      const glassItems = hqContainerStatus.filter(
        (item) => item.name === "glass",
      );

      // 유리 가용 수량 계산
      const glassNewItem =
        glassItems.find((item) => item.handle_type === "new")?.quantity ?? 0;
      const glassRepairItem =
        glassItems.find((item) => item.handle_type === "repair")?.quantity ?? 0;
      const glassDisposeItem =
        glassItems.find((item) => item.handle_type === "dispose")?.quantity ??
        0;
      const glassCompleteItem =
        glassItems.find((item) => item.handle_type === "complete")?.quantity ??
        0;

      const availableGlassQuantity =
        Number(glassNewItem) -
        Number(glassRepairItem) -
        Number(glassDisposeItem) +
        Number(glassCompleteItem);

      // 박스 가용 수량 계산
      const boxNewItem =
        boxItems.find((item) => item.handle_type === "new")?.quantity ?? 0;
      const boxRepairItem =
        boxItems.find((item) => item.handle_type === "repair")?.quantity ?? 0;
      const boxDisposeItem =
        boxItems.find((item) => item.handle_type === "dispose")?.quantity ?? 0;
      const boxCompleteItem =
        boxItems.find((item) => item.handle_type === "complete")?.quantity ?? 0;

      const availableBoxQuantity =
        Number(boxNewItem) -
        Number(boxRepairItem) -
        Number(boxDisposeItem) +
        Number(boxCompleteItem);

      setBoxStatus(boxItems);
      setContainerStatus(glassItems);
      setAvailableGlassQuantity(availableGlassQuantity);
      setAvailableBoxQuantity(availableBoxQuantity);
    }
  }, [hqContainerStatus]);

  return (
    <ModalContainer widthVw={42.34}>
      <div className="flex w-full flex-col">
        {isLoading ? <div>로딩중...</div> : null}

        <header className="flex w-full justify-center pb-8">
          <ContentTitle isCenter>박스 현황</ContentTitle>
        </header>
        <section className="flex w-full">
          <ul className="flex grow-[3] list-none justify-between">
            {HANDLE_TYPE.map((item, index) => {
              const { text, badgeColor, textColor } = badgeStyle(item);
              const dataByHandleType = boxStatus?.find(
                (boxData) => boxData.handle_type === item,
              );
              return (
                <li className="flex w-1/4 flex-col items-center gap-y-16">
                  <Badge
                    key={index + Math.random()}
                    badgeColor={badgeColor as ColorType}
                    textColor={textColor as ColorType}
                  >
                    {text}
                  </Badge>
                  <Span type="20pxBoldPureBlack">
                    {dataByHandleType?.quantity ?? "알 수 없음"}
                  </Span>
                </li>
              );
            })}
          </ul>
          <div className="flex grow-[2] flex-col items-center justify-center gap-y-16">
            <Span type="20pxBoldPureBlack">가용수량</Span>
            <Span type="20pxBoldPureBlack">{availableBoxQuantity}</Span>
          </div>
        </section>

        <div className="py-10">
          <Separator />
        </div>

        <header className="flex w-full justify-center pb-8">
          <ContentTitle isCenter>유리 현황</ContentTitle>
        </header>
        <section className="flex w-full ">
          <ul className="flex grow-[3] list-none justify-between">
            {HANDLE_TYPE.map((item, index) => {
              const { text, badgeColor, textColor } = badgeStyle(item);
              const dataByHandleType = containerStatus?.find(
                (containerData) => containerData.handle_type === item,
              );
              return (
                <li className="flex w-1/4 flex-col items-center gap-16">
                  <Badge
                    key={index + Math.random()}
                    badgeColor={badgeColor as ColorType}
                    textColor={textColor as ColorType}
                  >
                    {text}
                  </Badge>
                  <Span type="20pxBoldPureBlack">
                    {dataByHandleType?.quantity ?? "알 수 없음"}
                  </Span>
                </li>
              );
            })}
          </ul>
          <div className="flex grow-[2] flex-col items-center justify-center gap-y-16">
            <Span type="20pxBoldPureBlack">가용수량</Span>
            <Span type="20pxBoldPureBlack">{availableGlassQuantity}</Span>
          </div>
        </section>

        <div className="py-10">
          <Separator />
        </div>

        <div className="flex w-full justify-center">
          <Button title="확인" heightVh={5} width={324} onClick={closeModal} />
        </div>
      </div>
    </ModalContainer>
  );
};

export default HqContainerStatusModal;
