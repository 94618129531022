import React, { Dispatch, MouseEvent, SetStateAction } from "react";
import { useRecoilState } from "recoil";
import { shopMemuFormAtom } from "../../lib/recoils/atoms/ShopMenuPage/ShopMenuFormAtom";
import { CurrentStageIndex } from "../../pages/MenuPage";

const SelectedDaySection = ({
  currentStageIndex,
  setCurrentStgeIndex,
}: {
  currentStageIndex: CurrentStageIndex;
  setCurrentStgeIndex: Dispatch<SetStateAction<CurrentStageIndex>>;
}) => {
  // State
  const [menuDetailFormData, setMenuDetailFormData] =
    useRecoilState(shopMemuFormAtom);

  // Functions
  const onClickSelectedDayUl = (e: MouseEvent<HTMLUListElement>) => {
    const target = e.target as HTMLElement;
    const day = target.innerHTML;
    switch (day) {
      case "월요일":
        setMenuDetailFormData((prev) => ({
          week: "mon",
          childType: "",
          foodAmount: 0,
          name: "",
          price: 0,
        }));
        break;
      case "화요일":
        setMenuDetailFormData((prev) => ({
          week: "tue",
          childType: "",
          foodAmount: 0,
          name: "",
          price: 0,
        }));
        break;
      case "수요일":
        setMenuDetailFormData((prev) => ({
          week: "wed",
          childType: "",
          foodAmount: 0,
          name: "",
          price: 0,
        }));
        break;
      case "목요일":
        setMenuDetailFormData((prev) => ({
          week: "thu",
          childType: "",
          foodAmount: 0,
          name: "",
          price: 0,
        }));
        break;
      case "금요일":
        setMenuDetailFormData((prev) => ({
          week: "fri",
          childType: "",
          foodAmount: 0,
          name: "",
          price: 0,
        }));
        break;
      default:
        break;
    }
  };

  const handleStage = () => {
    if (currentStageIndex > 1) {
      return;
    } else {
      setCurrentStgeIndex(1);
    }
  };

  // Classes
  const liClass = `w-full h-[10vh] cursor-pointer flex justify-center items-center list-none text-[1.5rem] font-bold leading-[2rem]`;

  return (
    <section
      className="box-border flex h-full w-[10.5vw] flex-col gap-y-[7px]"
      onClick={handleStage}
    >
      <span
        className={`whitespace-pre-wrap text-[0.875rem] font-normal leading-[1.25rem] text-custom-black`}
      >
        요일
      </span>
      <div className="flex h-[64vh] w-full flex-col justify-between  rounded-[8px] border border-solid border-custom-gray  ">
        <div className="px-[2vw] pt-[2.4vh]">
          <span
            className={`whitespace-pre-wrap text-[0.875rem] font-normal leading-[1.25rem] text-custom-black`}
          >
            요일을 선택해 주세요.
          </span>
        </div>
        <ul
          className="flex w-full flex-col items-center"
          onClickCapture={onClickSelectedDayUl}
        >
          <li
            className={`${liClass.trim()} ${
              menuDetailFormData.week === "mon" && "bg-custom-lightBlue"
            }`}
          >
            월요일
          </li>
          <li
            className={`${liClass.trim()} ${
              menuDetailFormData.week === "tue" && "bg-custom-lightBlue"
            }`}
          >
            화요일
          </li>
          <li
            className={`${liClass.trim()} ${
              menuDetailFormData.week === "wed" && "bg-custom-lightBlue"
            }`}
          >
            수요일
          </li>
          <li
            className={`${liClass.trim()} ${
              menuDetailFormData.week === "thu" && "bg-custom-lightBlue"
            }`}
          >
            목요일
          </li>
          <li
            className={`${liClass.trim()} ${
              menuDetailFormData.week === "fri" && "bg-custom-lightBlue"
            }`}
          >
            금요일
          </li>
        </ul>
      </div>
    </section>
  );
};

export default SelectedDaySection;
