import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import Button from "../components/button/Button";
import FilterButton from "../components/button/FilterButton";
import ContentTitle from "../components/common/ContentTitle";
import MainContentContainer from "../components/common/MainContentContainer";
import SearchInput from "../components/input/SearchInput";
import { useModal } from "../lib/hooks/UseModal";
import RegisterShopModal from "../components/HQShopsPage/RegisterShopModal";
import HQShopsPageComponent from "../components/HQShopsPage/HQShopsPageComponent";
import { useQuery } from "@tanstack/react-query";
import hqApis from "../api/HqApis";
import { debounce } from "lodash";
import { useSetRecoilState } from "recoil";
import { refetchFunctionsAtom } from "../lib/recoils/RefetchFunctionsAtom";

const HQShopsPage = () => {
  // Hooks
  const { openModal } = useModal();

  // States
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // Recoil
  const setRefetchFunctions = useSetRecoilState(refetchFunctionsAtom);

  // Query
  const { data: hqShopData, refetch } = useQuery({
    queryKey: ["hqShopList", sortOrder, searchTerm, currentPage],
    queryFn: async () =>
      hqApis.getHqShopList(currentPage, sortOrder, searchTerm),
  });

  // Functions
  const onClickSortOrder = () => {
    sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");
  };

  const onClickAddButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openModal({
      content: <RegisterShopModal type="register" />,
    });
  };

  const debouncedOnchange = debounce((value: string) => {
    setSearchTerm(value);
  }, 800);

  const onChangeSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    debouncedOnchange(value);
  };

  useEffect(() => {
    if (refetch) {
      setRefetchFunctions((prev) => ({ ...prev, refetchHqShops: refetch }));
    }
  }, [refetch, setRefetchFunctions]);

  return (
    <div className="relative flex w-full flex-col">
      <ContentTitle hasMb>매장</ContentTitle>

      <MainContentContainer>
        <div className="bg-akber-300 absolute -top-[1.6vh] right-0 flex -translate-y-full flex-col items-end gap-y-[6px] ">
          <Button
            title="매장추가⨁"
            buttoncolor="custom-mainYellow"
            style={{ width: "7.5rem", height: "3.75rem" }}
            onClick={onClickAddButton}
          />
          <span className="text-[0.75rem] font-normal leading-[rem] text-custom-gray">
            신규로 매장을 등록할 때 눌러 주세요.
          </span>
        </div>
        <div className="flex w-full items-center justify-between">
          <h3 className="text-[0.875rem] leading-[1.25rem] text-custom-black">
            기사를 등록 및 관리할 수 있습니다. 클릭 시 변경할 수 있습니다.
          </h3>
          <div className="flex items-center gap-x-[52px]">
            <div className="flex items-center gap-x-[42px]">
              <FilterButton
                whereTo="customerAndDriver"
                title="가입일 오름차순"
                isSelected={sortOrder === "asc"}
                onClick={onClickSortOrder}
              />
              <FilterButton
                whereTo="customerAndDriver"
                title="가입일 내림차순"
                isSelected={sortOrder === "desc"}
                onClick={onClickSortOrder}
              />
            </div>
            <div>
              <SearchInput
                height={"4vh"}
                placeholder={"기사명, 전화번호"}
                onChange={onChangeSearchTerm}
              />
            </div>
          </div>
        </div>

        <HQShopsPageComponent
          title="배송 주소 확인"
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          sort={sortOrder}
          data={hqShopData}
        />
      </MainContentContainer>
    </div>
  );
};

export default HQShopsPage;
