import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import ContentTitle from "../common/ContentTitle";
import ModalContainer from "../common/ModalContainer";
import InputBase from "../input/InputBase";
import SelectBase from "../../components/select/SelectBase";
import Separator from "../common/Separator";
import Button from "../button/Button";
import {
  HandleType,
  ManageContainerState,
  Material,
} from "../../types/HqTypes";
import { useModal } from "../../lib/hooks/UseModal";
import { useRecoilValue } from "recoil";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import hqApis from "../../api/HqApis";
import AlertModal from "../modal/AlertModal";

const PROCESSING_OPTIONS = [
  {
    string: "등록",
    value: "new",
  },
  {
    string: "수리",
    value: "repair",
  },
  {
    string: "완료",
    value: "complete",
  },
  {
    string: "폐기",
    value: "dispose",
  },
];

const TYPE_OPTIONS = [
  {
    string: "유리",
    value: "glass",
  },

  {
    string: "박스",
    value: "box",
  },
];
const SUB_TYPE_OPTIONS = [
  {
    string: "플라스틱",
    value: "plastic",
  },
  {
    string: "유리",
    value: "glass",
  },
];

const DETAILS_OPTIONS = [
  { string: "TYPE A", value: "TYPE A" },
  // 추후 필요시 적용
  // { string: "세부 항목2", value: "세부 항목 2" },
];

const HQManageContainerModal = () => {
  // Hooks
  const { closeModal, changeModal } = useModal();
  // States
  const [manageContainerState, setManageContainerstate] =
    useState<ManageContainerState>({
      name: "", // 세부 항목?
      material: "", //유형
      detail: "", // 세부 유형
      quantity: "", // 수량
      handleType: "", // 처리
      remarks: "", // 비고
    });
  const [isValid, setIsValid] = useState(false);

  // Recoil
  const refechFunctions = useRecoilValue(refetchFunctionsAtom);

  // Functions
  const onChangeSelectAndInput = (
    e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>,
  ) => {
    const { value, name } = e.target;
    let material = "";
    let detail = "";

    if (name === "quantity") {
      if (isNaN(Number(value))) return;
    }

    if (name === "name") {
      if (value === "glass") {
        material = "glass";
        detail = "TYPE A";
      } else {
        material = "plastic";
        detail = "TYPE A";
      }
      setManageContainerstate((prev) => ({
        ...prev,
        name: value as Material,
        material,
        detail,
      }));
    } else {
      setManageContainerstate((prev) => ({ ...prev, [name]: value }));
    }
  };

  const createContainer = async (handleType: HandleType) => {
    const createContainerStatus = await hqApis.createHqContainer(
      manageContainerState,
    );
    if (createContainerStatus !== 400) {
      const message =
        handleType === "new"
          ? "등록이 완료 되었습니다."
          : handleType === "repair"
          ? "수리로 저장 되었습니다."
          : handleType === "complete"
          ? "수리가 완료 되었습니다."
          : "폐기 되었습니다";

      refechFunctions &&
        refechFunctions.refetchHqContainers &&
        refechFunctions.refetchHqContainers();

      changeModal({
        content: <AlertModal alertType={"check"} message={message} />,
      });
      return;
    } else {
      changeModal({
        content: <AlertModal alertType="denied" message="생성 실패" />,
      });
      return;
    }
  };

  const onClickCreateContainer = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isValid) {
      alert("입력을 확인해주세요.");
      return;
    }

    const { handleType } = manageContainerState;

    if (handleType === "dispose") {
      changeModal({
        content: (
          <AlertModal
            alertType="denied"
            message="정말로 폐기하시겠습니까?"
            hasCancelOnDenied
            onClickCheck={async () => await createContainer(handleType)}
          />
        ),
      });
      return;
    }

    await createContainer(handleType);
  };

  // Effects
  // Validate
  useEffect(() => {
    const { remarks, ...restContainerState } = manageContainerState;
    const valuesOfState = Object.values({ ...restContainerState });
    const hasEmptyValue = valuesOfState.some((value) => value === "");
    setIsValid(!hasEmptyValue);
  }, [manageContainerState]);

  useEffect(() => {
    console.log(manageContainerState);
  }, [manageContainerState]);

  return (
    <ModalContainer widthVw={22.4}>
      <ContentTitle hasMb isCenter>
        박스 용기 관리
      </ContentTitle>
      <form className="flex w-full flex-col">
        <div className="flex flex-col gap-y-[2.22vh]">
          <SelectBase
            name="handleType"
            id="process_select"
            defaultPlaceholder="등록 / 수리 / 완료 / 폐기 중 선택해 주세요."
            title="처리"
            options={PROCESSING_OPTIONS}
            onChange={onChangeSelectAndInput}
          />
          <SelectBase
            name="name"
            id="type_select"
            defaultPlaceholder="유형을 선택해 주세요."
            title="유형"
            options={TYPE_OPTIONS}
            onChange={onChangeSelectAndInput}
          />
          <SelectBase
            name="material"
            id="subtype_select"
            defaultPlaceholder="세부 유형을 선택해 주세요."
            title="세부 유형"
            disabled
            options={SUB_TYPE_OPTIONS}
            value={manageContainerState.material}
            // onChange={onChangeSelectAndInput}
          />
          <SelectBase
            name="detail"
            id="detail_select"
            defaultPlaceholder="세부 항목을 선택해 주세요."
            disabled
            title="세부 항목"
            options={DETAILS_OPTIONS}
            value={manageContainerState.detail}
            // onChange={onChangeSelectAndInput}
          />
          <InputBase
            name="quantity"
            id="quantity_input"
            title="수량"
            heightVh={4}
            value={manageContainerState.quantity}
            fullWidth
            onChange={onChangeSelectAndInput}
          />
          <InputBase
            name="remarks"
            id="note_input"
            title="비고"
            heightVh={4}
            fullWidth
            onChange={onChangeSelectAndInput}
          />
        </div>
        <div className="mb-[4.629vh] mt-[2.78vh]">
          <Separator />
        </div>
        <div className="flex w-full justify-center">
          <Button
            title="취소"
            buttoncolor="custom-gray"
            style={{ height: "4vh", width: "5.36vw", marginRight: "0.75rem" }}
            onClick={closeModal}
          />
          <Button
            disabled={!isValid}
            onClick={onClickCreateContainer}
            title="저장하기"
            style={{ height: "4vh", width: "11.5vw" }}
          />
        </div>
      </form>
    </ModalContainer>
  );
};

export default HQManageContainerModal;
