import { ChangeEvent, useState } from "react";
import { useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import { useModal } from "../../lib/hooks/UseModal";
import { refetchFunctionsAtom } from "../../lib/recoils/RefetchFunctionsAtom";
import { pxToVw } from "../../lib/utils/TransformPxToViewPort";
import { HqBoxContainerStatus } from "../../types/HqTypes";
import Button from "../button/Button";
import ContentTitle from "../common/ContentTitle";
import ModalContainer from "../common/ModalContainer";
import InputBase from "../input/InputBase";
import AlertModal from "../modal/AlertModal";
import Span from "../span/Span";

interface RequestsAndRecallsModalProps {
  isRequest: boolean;
  type: "requestBox" | "requestContainer" | "recallBox" | "recallContainer";
  detail: string;
  containerId: number;
  currentRequestedQuantity?: number;
}

const RequestsAndRecallsModal = ({
  isRequest,
  type,
  detail,
  containerId,
  currentRequestedQuantity,
}: RequestsAndRecallsModalProps) => {
  // Const
  const title =
    type === "requestBox"
      ? "박스 요청"
      : type === "requestContainer"
      ? "용기 요청"
      : type === "recallBox"
      ? "박스 반납"
      : type === "recallContainer"
      ? "용기 반납"
      : "";

  // Hooks
  const { closeModal, changeModal } = useModal();

  // States
  const [quantity, setQuantity] = useState("");

  // Recoil
  const refetchFunctions = useRecoilValue(refetchFunctionsAtom);

  // Functions
  const onClickSaveButton = async () => {
    // 요청이면 transferDriverMatched 반납이면 resendDriverMatched
    const status = isRequest ? "transferReady" : "resendReady";
    const requestStatus = await shopsApi.requestContainerBoxToHq(
      status as HqBoxContainerStatus,
      containerId,
      Number(quantity),
    );
    if (requestStatus === 200) {
      refetchFunctions &&
        refetchFunctions.refetchShopRequestAndRecall &&
        refetchFunctions.refetchShopRequestAndRecall();

      changeModal({ content: <AlertModal message="성공" alertType="check" /> });
      return;
    } else {
      alert("오류 발생");
      closeModal();
      return;
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isNaN(Number(value))) return;
    setQuantity(value);
  };

  return (
    <ModalContainer widthVw={pxToVw(550, "number")}>
      <ContentTitle>{title}</ContentTitle>
      <div className="mt-[3.52vh] flex w-full flex-col gap-y-[1.85vh] rounded-[8px] border border-solid border-custom-mediumGray px-[1.56vw] py-[2.78vh]">
        <section className="flex items-center gap-x-[0.73vw]">
          <ContentTitle>{detail ?? "알 수 없음"}</ContentTitle>
          <Span type="14pxBoldGray">세부유형</Span>
        </section>
        <section>
          <Span type="14pxNormalBlack">
            배송 신청하고자 하는 수량을 입력하여 주세요.
          </Span>
        </section>
        <section className="flex w-full justify-between">
          <div className="flex w-[30%] flex-col items-center">
            <Span type="14pxNormalBlack">현재 신청된 수량</Span>
            <Span type="14pxNormalBlack">
              {currentRequestedQuantity ?? "0"}
            </Span>
          </div>
          <div>
            <InputBase onChange={onChangeInput} />
          </div>
        </section>
        <section className="flex w-full justify-end">
          <Span type="14pxNormalBlack">
            음수를 입력하시면 현재 신청된 수량에서 차감 됩니다.
          </Span>
        </section>
      </div>

      <div className="mt-[3.52vh] flex w-full items-center justify-between">
        <Button
          title="삭제"
          width={103}
          heightVh={4.81}
          buttoncolor="custom-mainRed"
        />
        <Button
          title="취소"
          width={103}
          heightVh={4.81}
          buttoncolor="custom-gray"
          onClick={closeModal}
        />
        <Button
          title="저장"
          width={219}
          heightVh={4.81}
          buttoncolor="custom-mainYellow"
          onClick={onClickSaveButton}
        />
      </div>
    </ModalContainer>
  );
};

export default RequestsAndRecallsModal;
