import { ChangeEvent } from "react";
import { useRecoilState } from "recoil";
import { shopInfoAtom } from "../recoils/atoms/shopInfoAtom";
import { shopSettingAtom } from "../recoils/atoms/ShopSettingPage/ShopSettingAtom";

const useShopSettingControl = () => {
  // Recoils
  const [shopSettingState, setShopSettingState] =
    useRecoilState(shopSettingAtom);
  const [shopInfo, setShopInfo] = useRecoilState(shopInfoAtom);

  // Functions
  const cleanShopSettingState = () => {
    setShopSettingState({
      shopId: 0,
      address: "",
      addressDetail: "",
      mobile: "",
      name: "",
      owner: "",
      logo: "",
      logoUrl: "",
      imgUrl: "",
      imgUrl2: "",
      imgUrl3: "",
      imgUrl4: "",
      imgUrl5: "",
      imgUrl6: "",
      imgUrl7: "",
      imgUrl8: "",
      imgUrl9: "",
      imgUrl10: "",
    });
  };

  const onChangeShopSetting = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!name) return;
    setShopSettingState((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeLogoUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files) return;
    const file = files[0];

    if (!file) return;
    if (file.type !== "image/png") {
      alert("png 파일을 선택해주세요");
      return;
    }

    if (!file) return;
    if (file.type !== "image/png") {
      alert("png 파일을 선택해주세요");
      e.target.value = ""; // Reset the input value
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      console.log(width, height);

      setShopSettingState((prev) => ({
        ...prev,
        logo: file,
      }));
      /**
       * 128 X 128 크기 제한 두던 시절 사용
       */
      // if (width === 128 && height === 128) {
      //   console.log(typeof file);
      //   setShopSettingState((prev) => ({
      //     ...prev,
      //     logo: file,
      //   }));
      // } else {
      //   alert("128 X 128 크기의 png 이미지를 준비해주세요.");
      //   e.target.value = ""; // Reset the input value
      // }
    };

    img.onerror = () => {
      alert("Invalid image. Please select a PNG file.");
      e.target.value = ""; // Reset the input value
    };
  };

  const handlePostCode = (address: string) => {
    setShopSettingState((prev) => ({ ...prev, address }));
  };

  return {
    shopSettingState,
    setShopSettingState,
    cleanShopSettingState,
    onChangeShopSetting,
    handlePostCode,
    shopInfo,
    onChangeLogoUpload,
    setShopInfo,
  };
};

export default useShopSettingControl;
