import React, { useEffect, useRef } from "react";
import { useModal } from "../../lib/hooks/UseModal";

const BaseModal = () => {
  const {
    modalState: { isOpen, content },
    closeModal,
  } = useModal();

  // Refs
  const backgroundRef = useRef<HTMLDivElement>(null);

  // Effects
  useEffect(() => {
    // Add a click event listener to the document
    const handleClickOutside = (e: any) => {
      // If the user clicked inside the div, do nothing
      if (backgroundRef.current && backgroundRef.current.contains(e.target)) {
        closeModal();
      }

      // If the user clicked outside the div, close the modal
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal]);

  if (!isOpen) return null;

  return (
    <div className="fixed left-0 top-0 z-10 flex h-full w-full items-center justify-center">
      <div
        className="absolute left-0 top-0 h-full w-full bg-black opacity-50"
        ref={backgroundRef}
      />
      {/**
       * content 에 해당하는 JSX Element 의 최상위에서 z-20을 설정
       */}
      {content}
    </div>
  );
};

export default BaseModal;
