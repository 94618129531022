import React, { ButtonHTMLAttributes } from "react";

interface ButtonComponentProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttoncolor?:
    | "custom-mainYellow"
    | "custom-mainBlue"
    | "custom-mainRed"
    | "custom-gray"
    | "custom-black"
    | "custom-mediumGray";
  width?: number;
  height?: number;
  heightVh?: number | string;
  fullwidth?: boolean;
}

const Button: React.FC<ButtonComponentProps> = ({
  buttoncolor,
  fullwidth,
  width,
  height,
  heightVh,
  disabled,
  ...props
}) => {
  const colorClasses = {
    "custom-mainYellow": "bg-custom-mainYellow",
    "custom-mainBlue": "bg-custom-mainBlue",
    "custom-mainRed": "bg-custom-mainRed",
    "custom-gray": "bg-custom-gray",
    "custom-black": "bg-custom-black",
    "custom-mediumGray": "bg-custom-mediumGray",
  };

  const buttonClasses = `flex justify-center items-center text-white text-center text-[1rem] leading-[1.25rem] font-bold leading-none rounded-lg cursor-pointer ${
    disabled
      ? "bg-custom-gray"
      : colorClasses[buttoncolor ? buttoncolor : "custom-mainYellow"]
  }`;

  return (
    <button
      className={buttonClasses.trim()}
      style={{
        width: fullwidth ? "100%" : `${width}rem` ? `${width}px` : "auto",
        height: heightVh ? `${heightVh}vh` : height ? `${height}px` : "auto",
      }}
      {...props}
    >
      {props.title ? props.title : props.children ? props.children : null}
    </button>
  );
};

export default Button;
