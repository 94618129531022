import React, { ReactNode } from "react";

interface PageComponentProps {
  title?: string;
  children?: ReactNode;
}

const PageComponent = ({ title, children }: PageComponentProps) => {
  return (
    <div className="box-border flex h-full w-full flex-col">
      <h3 className="mb-[0.4vh] text-[0.875rem] leading-[1.25rem] text-custom-black">
        {title}
      </h3>
      <section className="custom-border-container mb-[2.7vh] box-border h-[57vh] w-full overflow-hidden rounded-[8px] px-[1.5vw] py-[3.1vh]">
        <ul className="relative flex h-full w-full flex-col gap-y-[3.33vh] overflow-auto">
          {children}
        </ul>
      </section>
    </div>
  );
};

export default PageComponent;
