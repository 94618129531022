import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import shopsApi from "../../api/Shops";
import { KOREAN_CHILDTYPE } from "../../lib/Consts";
import { useModal } from "../../lib/hooks/UseModal";
import { ChildType } from "../../lib/recoils/atoms/ShopMenuPage/ShopMenuFormAtom";
import { shopOrderAtom } from "../../lib/recoils/atoms/ShopOrderPage/ShopOrderAtom";
import Button from "../button/Button";
import ContentTitle from "../common/ContentTitle";
import ModalContainer from "../common/ModalContainer";
import Span from "../span/Span";

const TotalOrderModal = () => {
  // Hooks
  const { closeModal } = useModal();
  // Recoil
  const { selectedDate } = useRecoilValue(shopOrderAtom);
  // Query
  const { data: totalOrders, isLoading } = useQuery({
    queryKey: ["totalOrderList", dayjs(selectedDate).format("YYYY-MM-DD")],
    queryFn: async () =>
      shopsApi.getTotalOrderList(dayjs(selectedDate).format("YYYY-MM-DD")),
  });

  // States
  const [totalContainerQuantity, setTotalContainerQuantity] = useState(0);

  useEffect(() => {
    if (!totalOrders) return;
    const orderObj = totalOrders.find((obj) => obj.name !== "collectObject");
    const sum = orderObj?.containers.reduce((acc: any, cur: any) => {
      return (acc += cur.quantity);
    }, 0);
    console.log(orderObj);
    setTotalContainerQuantity(sum);
  }, [totalOrders]);

  return (
    <ModalContainer width={1646}>
      <ContentTitle hasMb isCenter>
        전체 주문
      </ContentTitle>

      <div className="custom-border-container relative h-[59.1vh] w-full">
        <div className="absolute -top-[2.41vh] right-0 z-50 flex -translate-y-full items-start">
          <div className="mr-[5vw]">
            <Span type="14pxNormalBlack">
              {dayjs(selectedDate).format("YYYY-MM-DD") ?? ""}
            </Span>
          </div>
          <div className="flex flex-col">
            <Span type="14pxNormalBlack">
              유리용기 : {totalContainerQuantity}
            </Span>
          </div>
        </div>
        {/* // 컨텐츠 영역 */}
        <div className="h-full w-full overflow-y-scroll px-[3.25vw] py-6">
          <div className="w-full overflow-auto">
            {totalOrders ? (
              isLoading && totalOrders ? (
                <Span type="20pxBoldBlack">로딩중...</Span>
              ) : (
                <div className="grid w-full grid-cols-2 gap-x-28 gap-y-10">
                  {totalOrders?.map((order, index) => {
                    if (order.name === "collectObject") return null;

                    return (
                      <div
                        key={index + Math.random() + order.name}
                        className="flex w-full justify-between"
                      >
                        <div className="flex w-[45%]">
                          <Span type="24pxBoldBlack">{`${
                            KOREAN_CHILDTYPE[order.childType as ChildType]
                          } ${order.name} [${order.food_amount}g]`}</Span>
                        </div>
                        <div className="flex w-[20%] justify-center ">
                          <Span type="24pxBoldBlack">
                            {order.containers
                              ? order.containers.reduce(
                                  (
                                    accTotalCount: number,
                                    currentOrder: any,
                                  ) => {
                                    return (accTotalCount +=
                                      currentOrder.quantity);
                                  },
                                  0,
                                )
                              : null}
                            개
                          </Span>
                        </div>
                        <div className="w-[30%] flex-col">
                          {order.containers
                            ? order.containers.map(
                                (containerInfo: any, index: number) => {
                                  return (
                                    <div
                                      key={
                                        index +
                                        Math.random() +
                                        containerInfo.quantity
                                      }
                                      className="flex"
                                    >
                                      <div className="">
                                        <Span type="24pxBoldBlack">
                                          {containerInfo.container === "glass"
                                            ? "유리용기"
                                            : "일회용기"}
                                        </Span>
                                      </div>
                                      <div className="flex grow justify-end">
                                        <Span type="24pxBoldBlack">
                                          {containerInfo.quantity}개
                                        </Span>
                                      </div>
                                    </div>
                                  );
                                },
                              )
                            : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )
            ) : (
              <div className="flex grow items-center justify-center">
                <Span type="20pxBoldBlack">주문이 없습니다.</Span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-[3.7vh] flex items-center justify-center">
        <Button
          title="확인"
          style={{ width: 120, height: "4vh" }}
          onClick={closeModal}
        />
      </div>
    </ModalContainer>
  );
};

export default TotalOrderModal;
